import { ProcessListItem } from "@/components/Process/ProcessListItem";

export const ProcessesList = ({ processes }) => {
  return (
    <div className="grid w-full grid-cols-2 justify-center justify-items-center gap-5">
      {processes?.map((process) => (
        <ProcessListItem key={process.id} process={process} />
      ))}
    </div>
  );
};
