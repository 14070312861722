import { UserTooltip } from "@/components/Users/UserTooltip";
import { CardRow } from "@/components/ui/CardRow";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { isAddressNotEmpty } from "@/helpers/address/isAddressNotEmpty";
import { capitalize } from "@/helpers/capitalize";
import { formatMoney } from "@/helpers/formatMoney";
import { formatPhoneNumber } from "@/helpers/formatPhoneNumber";
import i18n from "@/i18n";
import { useClientPolicy } from "@/policies/lead/useClientPolicy";
import { useLeadSourcePolicy } from "@/policies/lead/useLeadSourcePolicy";
import { useCalculationPolicy } from "@/policies/useCalculationPolicy";
import { format, parse } from "date-fns";

export const ClientInformationCard = ({ client }) => {
  const clientPolicy = useClientPolicy();
  const leadSourcePolicy = useLeadSourcePolicy();
  const calculationPolicy = useCalculationPolicy();

  return (
    <Card className="flex-1 h-fit shadow-md">
      <CardHeader>
        <CardTitle>{i18n.t("Informacje")}</CardTitle>
        <CardDescription>{i18n.t("Informacje o kliencie")}</CardDescription>
      </CardHeader>
      <CardContent className="divide-y divide-y-border">
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Imię i nazwisko")}</p>
          <p>{client.name}</p>
        </CardRow>
        {leadSourcePolicy.viewAny() && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Źródło")}</p>
            <p>{client?.source?.name ?? i18n.t("Brak")}</p>
          </CardRow>
        )}
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Łączna wartość umów")}</p>
          <p className="flex flex-row h-8 w-32 text-sm justify-center items-center bg-primary rounded-lg font-semibold">
            {client.contracts.length
              ? formatMoney(client.contracts.reduce((total, contract) => total + contract.gross_price, 0))
              : formatMoney(0)}
          </p>
        </CardRow>
        {calculationPolicy.viewMargin() && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Łączna wartość marży z umów")}</p>
            <p className="flex flex-row h-8 w-32 text-sm justify-center items-center bg-green-500 rounded-lg font-semibold">
              {client.contracts.length
                ? formatMoney(client.contracts.reduce((total, contract) => total + contract?.calculation?.margin, 0))
                : formatMoney(0)}
            </p>
          </CardRow>
        )}
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Data utworzenia")}</p>
          <p title={client.created_at}>
            {format(parse(client.created_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMM yyyy")}
          </p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Adres")}</p>
          {isAddressNotEmpty(client) ? (
            <div className="flex flex-col justify-end sm:items-end">
              <span className="flex gap-1 items-center">
                <p className="italic text-xs font-bold">{i18n.t("Adres: ")}</p>
                {client?.address?.street_number ?? i18n.t("Brak")}
              </span>
              <span className="flex gap-1 items-center">
                <p className="italic text-xs font-bold">{i18n.t("Kod pocztowy: ")}</p>
                {client?.address?.postal_code ?? i18n.t("Brak")}
              </span>
              <span className="flex gap-1 items-center">
                <p className="italic text-xs font-bold">{i18n.t("Miasto: ")}</p>
                {client?.address?.city ?? i18n.t("Brak")}
              </span>
              <span className="flex gap-1 items-center">
                <p className="italic text-xs font-bold">{i18n.t("Woj. ") + ":"}</p>
                {client?.address?.voivodeship ? capitalize(client.address.voivodeship) : i18n.t("Brak")}
              </span>
            </div>
          ) : (
            <p>{i18n.t("Brak")}</p>
          )}
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Numer telefonu")}</p>
          {client.phone_number ? (
            <a className="font-semibold" href={`tel:${client.phone_number}`}>
              {formatPhoneNumber(client.phone_number)}
            </a>
          ) : (
            <p>{i18n.t("Brak")}</p>
          )}
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Adres e-mail")}</p>
          {client.email ? (
            <a className="font-semibold" href={`mailto:${client.email}`}>
              {client.email}
            </a>
          ) : (
            <p>{i18n.t("Brak")}</p>
          )}
        </CardRow>
        {clientPolicy.viewUsers() && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Opiekun klienta")}</p>
            {client.user ? (
              <UserTooltip user={client.user} linkClassName="whitespace-break-spaces text-right" />
            ) : (
              i18n.t("Brak")
            )}
          </CardRow>
        )}
        {client.description && (
          <CardRow className="flex lg:flex-col lg:items-start gap-1">
            <p className="text-xs sm:text-base font-medium">{i18n.t("Opis")}</p>
            <p className="text-muted-foreground">{client.description ?? i18n.t("Brak")}</p>
          </CardRow>
        )}
      </CardContent>
    </Card>
  );
};
