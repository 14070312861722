import apiClient from "@/api/apiClient";
import { CalculatorAdditionalCost } from "@/components/Calculators/Configuration/AdditionalCosts/CalculatorAdditionalCost";
import { CalculatorAdditionalCostCreateDialog } from "@/components/Calculators/Configuration/AdditionalCosts/CalculatorAdditionalCostCreateDialog";
import { CalculatorSection } from "@/components/Calculators/Configuration/Sections/CalculatorSection";
import { CalculatorSectionCreateDialog } from "@/components/Calculators/Configuration/Sections/CalculatorSectionCreateDialog";
import { EmptyState } from "@/components/EmptyState";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { toast } from "@/components/ui/use-toast";
import { DndContext } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export const CalculatorConfiguration = ({ calculator }) => {
  const [sections, setSections] = useState(calculator.sections);
  const [additionalCosts, setAdditionalCosts] = useState(calculator.additionalCosts);

  const updateCalculatorSectionOrderMutation = useMutation({
    mutationFn: apiClient.updateCalculatorSectionOrder,
    onSuccess: (res) => {
      if (!res.ok) {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleSectionDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = sections.findIndex((section) => section.id === active.id);
      const newIndex = sections.findIndex((section) => section.id === over.id);
      const newSections = arrayMove(sections, oldIndex, newIndex);
      setSections(newSections);
      updateCalculatorSectionOrderMutation.mutate({
        calculatorId: calculator.id,
        data: {
          section_ids: newSections.map((section) => section.id),
        },
      });
    }
  };

  useEffect(() => {
    if (calculator) {
      setSections(calculator.sections);
      setAdditionalCosts(calculator.additionalCosts);
    }
  }, [calculator]);

  return (
    <div className="flex flex-col lg:flex-row gap-3">
      <DndContext onDragEnd={handleSectionDragEnd}>
        <SortableContext items={sections.map((section) => section.id)} strategy={verticalListSortingStrategy}>
          <div className="flex flex-col gap-4 items-center justify-center flex-1">
            {sections.length === 0 && <EmptyState title="Brak sekcji" />}
            {sections?.map((section) => (
              <CalculatorSection section={section} key={section.id} />
            ))}
            <CalculatorSectionCreateDialog />
          </div>
        </SortableContext>
      </DndContext>
      <div className="flex flex-col gap-4 items-center justify-start flex-1">
        {additionalCosts.length === 0 && <EmptyState title="Brak kosztów dodatkowych" />}
        {additionalCosts?.map((cost) => (
          <CalculatorAdditionalCost cost={cost} key={cost.id} />
        ))}
        <CalculatorAdditionalCostCreateDialog />
      </div>
    </div>
  );
};
