import apiClient from "@/api/apiClient";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { CalculatorCreateDialog } from "@/components/Calculators/CalculatorCreateDialog";
import { EmptyState } from "@/components/EmptyState";
import { Layout } from "@/components/layout/Layout";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { CalculatorSetting } from "@/pages/Calculators/CalculatorSetting";
import { useCalculatorPolicy } from "@/policies/useCalculatorPolicy";
import { useDepartmentPolicy } from "@/policies/useDepartmentPolicy";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export const CalculatorsSettingsPage = () => {
  useDocumentTitle("Kalkulatory");
  const calculatorPolicy = useCalculatorPolicy();
  const departmentPolicy = useDepartmentPolicy();

  const [departmentOptions, setDepartmentOptions] = useState([]);

  const { data: calculators, isLoading: isLoadingCalculators } = useQuery({
    queryKey: ["calculators"],
    queryFn: apiClient.getCalculators,
    staleTime: 1000 * 60 * 5,
    enabled: calculatorPolicy.viewAll(),
  });

  const {
    isLoading,
    data: departments,
    error,
  } = useQuery({
    queryKey: ["departments"],
    queryFn: apiClient.getDepartments,
    staleTime: 1000 * 60 * 5,
    enabled: departmentPolicy.viewAny(),
  });

  useEffect(() => {
    if (!isLoading && !error && departments) {
      setDepartmentOptions(departments.data.map((department) => ({ name: department.name, value: department.id })));
    }
  }, [isLoading, error, departments]);

  return (
    <Layout isLoading={isLoadingCalculators || isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          <CalculatorCreateDialog />
        </div>
      </div>
      {calculators?.data?.length === 0 && <EmptyState title="Brak kalkulatorów" />}
      {calculators?.data?.map((calculator) => {
        return <CalculatorSetting key={calculator.id} calculator={calculator} departmentOptions={departmentOptions} />;
      })}
    </Layout>
  );
};
