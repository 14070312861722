import { routes } from "@/routes";
import { Route } from "react-router-dom";
import { ForgotPasswordPage } from "@/pages/ForgotPasswordPage";
import { LoginPage } from "@/pages/LoginPage";
import { ResetPasswordPage } from "@/pages/ResetPasswordPage";

export const AuthRoutes = () => (
  <>
    <Route exact path={routes.login} element={<LoginPage />} />
    <Route exact path={routes.forgotPassword} element={<ForgotPasswordPage />} />
    <Route exact path={routes.resetPassword} element={<ResetPasswordPage />} />
  </>
);
