import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCheckboxGroup } from "@/components/forms/FormCheckboxGroup";
import { FormFileUpload } from "@/components/forms/FormFileUpload";
import { Loader } from "@/components/Loader";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { BillingStatus } from "@/enums/BillingStatusEnum";
import { formatMoney } from "@/helpers/formatMoney";
import { useAuth } from "@/hooks/useAuth";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

export const BillingGroupCreateForm = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { user } = useAuth();

  const [billingOptions, setBillingOptions] = useState([]);

  const filters = {
    status: [BillingStatus.ACCEPTED.value],
    user: [user.id],
  };

  const {
    isLoading,
    data: billings,
    error,
  } = useQuery({
    queryKey: ["billings", filters],
    queryFn: () => apiClient.getBillings(filters),
  });

  const validationSchema = Yup.object({
    billing_ids: Yup.array()
      .of(Yup.string())
      .required(i18n.t("Pole jest wymagane"))
      .min(1, i18n.t("Pole jest wymagane")),
  });

  const defaultValues = {
    billing_ids: [],
  };

  const onSubmit = (values) => {
    const data = new FormData();
    for (let i = 0; i < values?.files?.length; i++) {
      data.append(`files[${i}]`, values.files[i]);
    }
    for (let i = 0; i < values?.billing_ids?.length; i++) {
      data.append(`billing_ids[${i}]`, values.billing_ids[i]);
    }

    createBillingGroupMutation.mutate({ data });
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const createBillingGroupMutation = useMutation({
    mutationFn: apiClient.createBillingGroup,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["billings"] });
        queryClient.invalidateQueries({ queryKey: ["billingGroups"] });
        return navigate(routes.billings, { state: { activeTab: "billingGroups" } });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const calculateTotalAmount = () => {
    const selectedBillingIds = form.watch("billing_ids");
    if (!billings?.data) return 0;

    return billings.data
      .filter((billing) => selectedBillingIds.includes(billing.id))
      .reduce((total, billing) => total + billing.amount, 0);
  };

  useEffect(() => {
    if (!isLoading && !error && billings) {
      setBillingOptions(
        billings.data.map((billing) => ({
          name: `${billing.amount} zł (${billing.contract.identifier}, ${billing.contract.name})`,
          value: billing.id,
        })),
      );
    }
  }, [isLoading, error, billings]);

  return (
    <Card className="shadow-md mt-5 w-full xl:w-1/2">
      <Form onSubmit={onSubmit} form={form}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <CardContent className="pt-5">
              {billingOptions.length === 0 && !isLoading ? (
                <span className="font-medium">{i18n.t("Aktualnie nie posiadasz prowizji do wypłacenia.")}</span>
              ) : (
                <div className="flex flex-col gap-4">
                  <FormCheckboxGroup
                    label="Prowizje"
                    description="Wybierz prowizje do rozliczenia"
                    name="billing_ids"
                    options={billingOptions}
                  />
                  {form.watch("billing_ids")?.length > 0 && `${i18n.t("Suma")}: ${formatMoney(calculateTotalAmount())}`}
                  <FormFileUpload name="files" label="Faktury, potwierdzenia płatności itp." />
                </div>
              )}
            </CardContent>
            <CardFooter className="gap-3 items-center border-t px-6 py-4">
              {billingOptions.length > 0 && (
                <Button
                  type="submit"
                  title="Dodaj"
                  leftIcon={<Plus size={20} />}
                  isLoading={createBillingGroupMutation.isPending}
                />
              )}
              <Button
                title="Anuluj"
                type="button"
                leftIcon={<X size={20} />}
                variant="destructive"
                onClick={() => navigate(routes.billings, { state: { tab: "billing_groups" } })}
              />
            </CardFooter>
          </>
        )}
      </Form>
    </Card>
  );
};
