import { ContractCalculationDetailsPage } from "@/pages/Contracts/ContractCalculationDetailsPage";
import { ContractCreatePage } from "@/pages/Contracts/ContractCreatePage";
import { ContractDetailsPage } from "@/pages/Contracts/ContractDetailsPage";
import { ContractEditPage } from "@/pages/Contracts/ContractEditPage";
import { ContractPaymentCreatePage } from "@/pages/Contracts/ContractPaymentCreatePage";
import { ContractsPage } from "@/pages/Contracts/ContractsPage";
import { routes } from "@/routes";
import { Route } from "react-router-dom";

export const ContractRoutes = () => (
  <>
    <Route exact path={routes.contracts} element={<ContractsPage />} />
    <Route exact path={routes.contractCreate} element={<ContractCreatePage />} />
    <Route exact path={routes.contract} element={<ContractDetailsPage />} />
    <Route exact path={routes.contractEdit} element={<ContractEditPage />} />
    <Route exact path={routes.contractPaymentCreate} element={<ContractPaymentCreatePage />} />
    <Route exact path={routes.contractCalculation} element={<ContractCalculationDetailsPage />} />
  </>
);
