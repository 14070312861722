import { ChartContainerWrapper } from "@/components/Charts/ChartContainerWrapper";
import { StatCardDescription } from "@/components/StatCardDescription";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";
import i18n from "@/i18n";
import { format, parse } from "date-fns";
import { TrendingUp } from "lucide-react";
import { Area, AreaChart, CartesianGrid, XAxis } from "recharts";

export const ContractIncomeStat = ({ dateRange, data, isLoading }) => {
  const chartData =
    data &&
    Object.keys(data)
      .map((month) => {
        const { income, margin } = data[month];
        return {
          month: format(parse(month, "yyyy-MM", new Date()), "MMM yyyy"),
          income,
          margin,
        };
      })
      .sort(
        (a, b) => parse(a.month, "MMM yyyy", new Date()).getTime() - parse(b.month, "MMM yyyy", new Date()).getTime(),
      );

  const chartConfig = {
    income: {
      label: "Przychód",
      color: "hsl(var(--chart-1))",
    },
    margin: {
      label: "Marża",
      color: "hsl(var(--chart-2))",
    },
  };

  return (
    <Card>
      <CardHeader>
        {data && Object.values(data)[0]?.margin !== null ? (
          <CardTitle>{i18n.t("Przychód i marża")}</CardTitle>
        ) : (
          <CardTitle>{i18n.t("Przychód")}</CardTitle>
        )}
        <StatCardDescription dateRange={dateRange} />
      </CardHeader>
      <CardContent>
        <ChartContainerWrapper
          isLoading={isLoading}
          data={chartData}
          config={chartConfig}
          className="w-full max-h-[300px]"
        >
          <AreaChart
            accessibilityLayer
            data={chartData}
            margin={{
              left: 12,
              right: 12,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="month"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={(value) => value.slice(0, 3)}
            />
            <ChartTooltip cursor={false} content={<ChartTooltipContent indicator="dot" />} />
            <Area
              dataKey="margin"
              type="natural"
              fill="var(--color-margin)"
              fillOpacity={0.4}
              stroke="var(--color-margin)"
              stackId="a"
            />
            <Area
              dataKey="income"
              type="natural"
              fill="var(--color-income)"
              fillOpacity={0.4}
              stroke="var(--color-income)"
              stackId="a"
            />
          </AreaChart>
        </ChartContainerWrapper>
      </CardContent>
      <CardFooter>
        <div className="flex w-full items-start gap-2 text-sm">
          <div className="grid gap-2">
            <div className="flex items-center gap-2 font-medium leading-none">
              {i18n.t("W tym miesiącu odnotowano wzrost o ")} 5.2% <TrendingUp className="h-4 w-4" />
            </div>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
};
