import apiClient from "@/api/apiClient";
import { PermissionContext } from "@/context/PermissionContext";
import { useContext } from "react";

export const usePermissions = () => {
  const { setPermissions } = useContext(PermissionContext);

  const fetchPermissions = async () => {
    const response = await apiClient.getPermissions();
    if (response.ok) {
      setPermissions(response.data.map((perm) => perm.name));
    } else {
      setPermissions([]);
    }
  };

  return { fetchPermissions };
};
