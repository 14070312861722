import apiClient from "@/api/apiClient";
import { LeadConversionStat } from "@/components/Leads/Stats/LeadConversionStat";
import { LeadCountStat } from "@/components/Leads/Stats/LeadCountStat";
import { LeadProfitabilityStat } from "@/components/Leads/Stats/LeadProfitabilityStat";
import { LeadSourceStat } from "@/components/Leads/Stats/LeadSourceStat";
import { useLeadPolicy } from "@/policies/lead/useLeadPolicy";
import { useQuery } from "@tanstack/react-query";

export const LeadsStats = ({ dateRange }) => {
  const leadPolicy = useLeadPolicy();

  const { isLoading, data: response } = useQuery({
    queryKey: ["leadsStats", dateRange],
    queryFn: () => apiClient.getLeadsStats(dateRange),
    staleTime: 1000 * 60 * 5,
    enabled: leadPolicy.viewStats(),
  });

  return (
    <div className="w-full flex flex-col gap-5">
      <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-5">
        <LeadSourceStat dateRange={dateRange} data={response?.data?.source_stat} isLoading={isLoading} />
        <LeadCountStat dateRange={dateRange} data={response?.data?.count_by_month_stat} isLoading={isLoading} />
      </div>
      <div className="w-full grid grid-cols-4 gap-5">
        <LeadConversionStat dateRange={dateRange} data={response?.data?.conversion_stat} isLoading={isLoading} />
        <LeadProfitabilityStat dateRange={dateRange} data={response?.data?.profitability_stat} isLoading={isLoading} />
      </div>
    </div>
  );
};
