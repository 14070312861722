import { AdminDashboardOverviewIncomeChart } from "@/components/Dashboard/DashboardOverview/Admin/AdminDashboardOverviewIncomeChart";
import { AdminDashboardOverviewLastContracts } from "@/components/Dashboard/DashboardOverview/Admin/AdminDashboardOverviewLastContracts";
import { AdminDashboardOverviewTiles } from "@/components/Dashboard/DashboardOverview/Admin/AdminDashboardOverviewTiles";
import { OfficeDashboardOverviewContractsCountChart } from "@/components/Dashboard/DashboardOverview/Office/OfficeDashboardOverviewContractsCountChart";
import { OfficeDashboardOverviewLastContracts } from "@/components/Dashboard/DashboardOverview/Office/OfficeDashboardOverviewLastContracts";
import { OfficeDashboardOverviewTiles } from "@/components/Dashboard/DashboardOverview/Office/OfficeDashboardOverviewTiles";
import { SalesManagerDashboardOverviewContractsCountChart } from "@/components/Dashboard/DashboardOverview/SalesManager/SalesManagerDashboardOverviewContractsCountChart";
import { SalesManagerDashboardOverviewLastContracts } from "@/components/Dashboard/DashboardOverview/SalesManager/SalesManagerDashboardOverviewLastContracts";
import { SalesManagerDashboardOverviewTiles } from "@/components/Dashboard/DashboardOverview/SalesManager/SalesManagerDashboardOverviewTiles";
import { UserRole } from "@/enums/UserRoleEnum";
import { useAuth } from "@/hooks/useAuth";

export const DashboardRoleBasedOverview = ({ data }) => {
  const { user } = useAuth();

  switch (user.roles[0].name) {
    case UserRole.SUPERADMIN.value:
    case UserRole.ADMIN.value:
      return (
        <div className="flex flex-1 flex-col gap-5">
          <AdminDashboardOverviewTiles data={data} />
          <div className="grid grid-cols-3 gap-4 md:gap-5 lg:grid-cols-5">
            <AdminDashboardOverviewIncomeChart data={data} />
            <AdminDashboardOverviewLastContracts data={data} />
          </div>
        </div>
      );
    case UserRole.OFFICE.value:
      return (
        <div className="flex flex-1 flex-col gap-5">
          <OfficeDashboardOverviewTiles data={data} />
          <div className="grid grid-cols-3 gap-4 md:gap-5 lg:grid-cols-5">
            <OfficeDashboardOverviewContractsCountChart data={data} />
            <OfficeDashboardOverviewLastContracts data={data} />
          </div>
        </div>
      );
    case UserRole.SALES.value:
    case UserRole.MANAGER.value:
      return (
        <div className="flex flex-1 flex-col gap-5">
          <SalesManagerDashboardOverviewTiles data={data} />
          <div className="grid grid-cols-3 gap-4 md:gap-5 lg:grid-cols-5">
            <SalesManagerDashboardOverviewContractsCountChart data={data} />
            <SalesManagerDashboardOverviewLastContracts data={data} />
          </div>
        </div>
      );
  }
};
