import { Button } from "@/components/Button";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/lib/utils";
import { format, getHours, getMinutes, setHours, setMinutes } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { forwardRef, useEffect, useState } from "react";

const DateTimePicker = forwardRef(
  ({ value, onChange, placeholder = "Wybierz datę i godzinę", format: dateFormat = "PPPP, HH:mm", className }, ref) => {
    const [date, setDate] = useState(value);
    const [time, setTime] = useState(
      value
        ? `${getHours(value).toString().padStart(2, "0")}:${getMinutes(value).toString().padStart(2, "0")}`
        : "12:00",
    );

    useEffect(() => {
      if (value) {
        setDate(value);
        setTime(`${getHours(value).toString().padStart(2, "0")}:${getMinutes(value).toString().padStart(2, "0")}`);
      }
    }, [value]);

    const updateDateTime = (newDate, newTime) => {
      const [hours, minutes] = newTime.split(":").map(Number);
      setTime(newTime);
      const updatedDate = newDate ? setMinutes(setHours(newDate, hours), minutes) : undefined;
      setDate(updatedDate);
      onChange(updatedDate);
    };

    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            variant="outline"
            className={cn("justify-start text-left font-normal w-full", !date && "text-muted-foreground", className)}
            leftIcon={<CalendarIcon className="h-4 w-4" />}
            title={date ? format(date, dateFormat) : placeholder}
          />
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0 flex flex-row items-start gap-2" align="start">
          <Calendar mode="single" selected={date} onSelect={(newDate) => updateDateTime(newDate, time)} initialFocus />
          <div className="w-[100px] my-2 mr-2">
            <ScrollArea className="h-[230px]">
              {Array.from({ length: 96 }).map((_, i) => {
                const hour = Math.floor(i / 4)
                  .toString()
                  .padStart(2, "0");
                const minute = ((i % 4) * 15).toString().padStart(2, "0");
                const timeValue = `${hour}:${minute}`;

                return (
                  <span
                    key={i}
                    onClick={() => updateDateTime(date, timeValue)}
                    className={cn(
                      "text-center text-sm block cursor-pointer rounded-md p-2",
                      time === timeValue ? "bg-primary text-background" : "hover:bg-border",
                    )}
                  >
                    {timeValue}
                  </span>
                );
              })}
            </ScrollArea>
          </div>
        </PopoverContent>
      </Popover>
    );
  },
);

DateTimePicker.displayName = "DateTimePicker";

export { DateTimePicker };
