import { CardDescription } from "@/components/ui/card";
import i18n from "@/i18n";
import { format } from "date-fns";

export const StatCardDescription = ({ dateRange }) => {
  return (
    <CardDescription>
      {!dateRange?.from && !dateRange?.to && null}
      {dateRange?.from && i18n.t("Od ") + format(dateRange?.from, "dd MMM yyyy")}
      {dateRange?.to && i18n.t(" - ") + i18n.t("do ") + format(dateRange?.to, "dd MMM yyyy")}
    </CardDescription>
  );
};
