import { useAuth } from "@/hooks/useAuth";

export const useBillingPolicy = () => {
  const { user, hasPermission, hasAnyPermission } = useAuth();

  const viewAny = () => {
    return hasAnyPermission(["access_all_billings", "access_billings"]);
  };

  const viewAll = () => {
    return hasPermission("access_all_billings");
  };

  const create = () => {
    return hasPermission("create_billing");
  };

  const update = (billing) => {
    if (hasPermission("update_any_billing")) {
      return true;
    }
    if (hasPermission("update_billing")) {
      return billing?.user?.id === user?.id;
    }
    return false;
  };

  const destroy = (billing) => {
    if (hasPermission("delete_any_billing")) {
      return true;
    }
    if (hasPermission("delete_billing")) {
      return billing?.user?.id === user?.id;
    }
    return false;
  };

  return {
    viewAny,
    viewAll,
    create,
    update,
    destroy,
  };
};
