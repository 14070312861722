export const FileCategory = Object.freeze({
  CATALOG: { value: "CATALOG", name: "Karty katalogowe" },
  PRESENTATIONS: { value: "PRESENTATIONS", name: "Prezentacje" },
  CONTRACTS: { value: "CONTRACTS", name: "Umowy" },
  IMPLEMENTATION_PHOTOS: { value: "IMPLEMENTATION_PHOTOS", name: "Zdjęcia realizacji" },
  CRM_INSTRUCTIONS: { value: "CRM_INSTRUCTIONS", name: "Instrukcje Systemu" },
  CATALOG_INVERTERS: { value: "CATALOG_INVERTERS", name: "Inwertery" },
  CATALOG_INVERTER_CERTIFICATES: { value: "CATALOG_INVERTER_CERTIFICATES", name: "Certyfikaty inwerterów" },
  CATALOG_INVERTER_WARRANTY: { value: "CATALOG_INVERTER_WARRANTY", name: "Gwarancje inwerterów" },
  CATALOG_INVERTER_INSTRUCTION: { value: "CATALOG_INVERTER_INSTRUCTION", name: "Instrukcje inwerterów" },
  CATALOG_SOLAR_PANELS: { value: "CATALOG_SOLAR_PANELS", name: "Panele fotowoltaiczne" },
  CATALOG_ENERGY_STORAGE: { value: "CATALOG_ENERGY_STORAGE", name: "Magazyny energii" },
  CATALOG_MOUNTING_STRUCTURES: { value: "CATALOG_MOUNTING_STRUCTURES", name: "Konstrukcje montażowe" },
  CATALOG_HEAT_PUMPS: { value: "CATALOG_HEAT_PUMPS", name: "Pompy ciepła" },
  CATALOG_PELLET_BOILERS: { value: "CATALOG_PELLET_BOILERS", name: "Kotły na pellet" },
  CONTRACT_SOLAR_PANELS: { value: "CONTRACT_SOLAR_PANELS", name: "Umowa fotowoltaika" },
  CONTRACT_SOLAR_WITH_ENERGY_STORAGE: {
    value: "CONTRACT_SOLAR_WITH_ENERGY_STORAGE",
    name: "Umowa fotowoltaika z magazynem energii",
  },
  CONTRACT_HEAT_PUMPS: { value: "CONTRACT_HEAT_PUMPS", name: "Umowa pompa ciepła" },
  CONTRACT_THERMAL_MODERNIZATION: { value: "CONTRACT_THERMAL_MODERNIZATION", name: "Umowa termomodernizacja" },
  FUND_CONTRACT_SCAN: { value: "FUND_CONTRACT_SCAN", name: "Umowa funduszu" },
  LIQENE_CONTRACT_SCAN: { value: "LIQENE_CONTRACT_SCAN", name: "Umowa firmy" },
  PROXY_SCAN: { value: "PROXY_SCAN", name: "Pełnomocnictwo" },
  SPAUSE_CONSENT: { value: "SPAUSE_CONSENT", name: "Oświadczenia Współmałżonka/Współwłaściciela" },
  MARKETING_CONSENT: { value: "MARKETING_CONSENT", name: "Zgoda na korespondencję elektroniczną" },
  INSTALLATION_PLACES_PHOTOS: { value: "INSTALLATION_PLACES_PHOTOS", name: "Zdjęcia miejsc montażu" },
  FIRST_TRANCHE_DISPOSITION: { value: "FIRST_TRANCHE_DISPOSITION", name: "Dyspozycja wypłaty 1 transzy" },
  CONTRACT_SCAN: { value: "CONTRACT_SCAN", name: "Skan umowy" },
  AUDIT_SCAN: { value: "AUDIT_SCAN", name: "Skan audytu" },
  INSTALLATION_PLACE_PHOTOS: { value: "INSTALLATION_PLACE_PHOTOS", name: "Zdjęcia miejsca montażu" },
  PROXIES_SCAN: { value: "PROXIES_SCAN", name: "Pełnomocnictwa (Dotacje, Energetyka)" },
  ADDITIONAL_DOCUMENTS: { value: "ADDITIONAL_DOCUMENTS", name: "Inne dokumenty" },
  PAYMENT_CONFIRMATION: { value: "PAYMENT_CONFIRMATION", name: "Potwierdzenie płatności" },

  getValues() {
    return Object.values(this).filter((value) => typeof value === "object" && value !== null);
  },

  getNames() {
    return this.getValues().map((category) => category.name);
  },

  getMain() {
    return [this.CATALOG, this.PRESENTATIONS, this.CONTRACTS, this.IMPLEMENTATION_PHOTOS, this.CRM_INSTRUCTIONS];
  },

  getSubcategories(category) {
    switch (category) {
      case this.CATALOG.value:
        return [
          this.CATALOG_INVERTERS,
          this.CATALOG_INVERTER_CERTIFICATES,
          this.CATALOG_INVERTER_WARRANTY,
          this.CATALOG_INVERTER_INSTRUCTION,
          this.CATALOG_SOLAR_PANELS,
          this.CATALOG_ENERGY_STORAGE,
          this.CATALOG_MOUNTING_STRUCTURES,
          this.CATALOG_HEAT_PUMPS,
          this.CATALOG_PELLET_BOILERS,
        ];
      case this.CONTRACTS.value:
        return [
          this.CONTRACT_SOLAR_PANELS,
          this.CONTRACT_SOLAR_WITH_ENERGY_STORAGE,
          this.CONTRACT_HEAT_PUMPS,
          this.CONTRACT_THERMAL_MODERNIZATION,
        ];
      default:
        return null;
    }
  },

  getContractCategories() {
    return [this.CONTRACT_SCAN, this.AUDIT_SCAN, this.INSTALLATION_PLACE_PHOTOS, this.PROXIES_SCAN];
  },

  getProductCategories() {
    return [this.CATALOG];
  },
});
