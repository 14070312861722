import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { UserLevelEditDialog } from "@/components/Users/Levels/UserLevelEditDialog";
import i18n from "@/i18n";
import { useUserLevelPolicy } from "@/policies/user/useUserLevelPolicy";
import { MoreHorizontal, Pencil, Trash } from "lucide-react";

export const useUserLevelsTableColumns = (handleDelete) => {
  const userLevelPolicy = useUserLevelPolicy();

  const columns = [
    {
      id: "name",
      name: "Nazwa statusu",
      size: 10,
      accessorKey: "name",
      header: i18n.t("Nazwa statusu"),
      cell: ({ row }) => row.getValue("name"),
    },
    {
      id: "required_points",
      name: "Wymagana ilość punktów",
      accessorKey: "required_points",
      header: i18n.t("Wymagana ilość punktów"),
      cell: ({ row }) => row.getValue("required_points") ?? "-",
    },
    {
      id: "comission_percentage",
      name: "Procentowy udział w prowizji",
      accessorKey: "comission_percentage",
      header: i18n.t("Procentowy udział w prowizji"),
      cell: ({ row }) => row.getValue("comission_percentage") + "%",
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const userLevel = row.original;
        if (!userLevelPolicy.destroy(userLevel) && !userLevelPolicy.update()) return null;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {userLevelPolicy.update() && (
                <UserLevelEditDialog
                  userLevel={userLevel}
                  trigger={
                    <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                      <Pencil size={16} className="mr-1 text-primary" />
                      <span className="font-semibold">{i18n.t("Edytuj")}</span>
                    </DropdownMenuItem>
                  }
                />
              )}
              {userLevelPolicy.destroy(userLevel) && (
                <>
                  <DropdownMenuSeparator />
                  <DeleteAlertDialog
                    onConfirm={() => handleDelete(userLevel.id)}
                    message="Czy na pewno chcesz usunąć ten status? Tej operacji nie można cofnąć"
                    trigger={
                      <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                        <Trash size={16} className="mr-1 text-primary" />
                        <span className="font-semibold">{i18n.t("Usuń")}</span>
                      </DropdownMenuItem>
                    }
                  />
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
