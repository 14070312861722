import apiClient from "@/api/apiClient";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Layout } from "@/components/layout/Layout";
import { NotificationsList } from "@/components/Notifications/NotificationsList";
import { Tabs, TabsContent } from "@/components/ui/tabs";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useQuery } from "@tanstack/react-query";

export const NotificationsPage = () => {
  useDocumentTitle("Powiadomienia");
  const { isLoading, data: response } = useQuery({ queryKey: ["notifications"], queryFn: apiClient.getNotifications });

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5"></div>
      </div>
      <Tabs defaultValue="notifications">
        <TabsContent value="notifications">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <NotificationsList notifications={response?.data} />
          </div>
        </TabsContent>
      </Tabs>
    </Layout>
  );
};
