import { useAuth } from "@/hooks/useAuth";

export const useContractProductPolicy = () => {
  const { hasPermission } = useAuth();

  const create = () => {
    return hasPermission("create_contract_product");
  };

  const update = () => {
    return hasPermission("update_contract_product");
  };

  const updateName = () => {
    return hasPermission("update_contract_product");
  };

  const destroy = () => {
    return hasPermission("delete_contract_product");
  };

  return {
    create,
    update,
    updateName,
    destroy,
  };
};
