import { Button } from "@/components/Button";
import { UserAvatar } from "@/components/Users/UserAvatar";
import { CardRow } from "@/components/ui/CardRow";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { UserRole } from "@/enums/UserRoleEnum";
import { useAuth } from "@/hooks/useAuth";
import i18n from "@/i18n";
import { useBillingSettingPolicy } from "@/policies/billing/useBillingSettingPolicy";
import { useDepartmentPolicy } from "@/policies/useDepartmentPolicy";
import { useUserPolicy } from "@/policies/user/useUserPolicy";
import { routes } from "@/routes";
import { Link } from "react-router-dom";

export const UserInformationCard = ({ user }) => {
  const { userHasRole } = useAuth();
  const departmentPolicy = useDepartmentPolicy();
  const billingSettingPolicy = useBillingSettingPolicy();
  const userPolicy = useUserPolicy();

  if (!userPolicy.view(user)) return null;

  return (
    <Card className="w-1/3">
      <CardHeader>
        <CardTitle>{i18n.t("Informacje")}</CardTitle>
        <CardDescription>{i18n.t("Informacje na temat użytkownika")}</CardDescription>
      </CardHeader>
      <CardHeader className="flex justify-center items-center">
        <UserAvatar user={user} className="w-1/3 h-1/3" />
      </CardHeader>
      <CardContent className="divide-y divide-y-border">
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Imię i nazwisko")}</p>
          <p>{user.name}</p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Rola")}</p>
          <p>{UserRole[user.roles[0].name].name}</p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Poziom")}</p>
          {user.level ? <Badge className="py-1">{user.level.name}</Badge> : i18n.t("Brak")}
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Data utworzenia")}</p>
          <p>{user.created_at.split(" ")[0]}</p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Email")}</p>
          <a className="font-semibold" href={`mailto:${user.email}`}>
            {user.email}
          </a>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Numer telefonu")}</p>
          {user.phone_number ? (
            <a className="font-semibold" href={`tel:${user.phone_number}`}>
              {user.phone_number}
            </a>
          ) : (
            <p className="text-xs sm:text-base font-medium">{i18n.t("Brak")}</p>
          )}
        </CardRow>
        {user.department && departmentPolicy.viewAny() && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Oddział")}</p>
            <Link className="font-semibold" to={routes.departments + `/${user?.department?.id}`}>
              <Button variant="link" size="xs">
                {user?.department?.name}
              </Button>
            </Link>
          </CardRow>
        )}
        {billingSettingPolicy.viewAny() && (
          <>
            <CardRow>
              <p className="text-xs sm:text-base font-medium">{i18n.t("Model rozliczeń")}</p>
              {user.billingSetting ? (
                <Link className="font-semibold" to={routes.billingSettings + `/${user?.billingSetting?.id}`}>
                  <Button variant="link" size="xs">
                    {user?.billingSetting?.name}
                  </Button>
                </Link>
              ) : (
                i18n.t("Brak")
              )}
            </CardRow>
            {userHasRole(user, UserRole.MANAGER.value) && (
              <CardRow>
                <p className="text-xs sm:text-base font-medium">
                  {i18n.t("Udział w prowizji użytkowników w oddziale")}
                </p>
                <p>{user?.comission_percentage + " %"}</p>
              </CardRow>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};
