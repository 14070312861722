import i18n from "@/i18n";

export const Text = ({ translate, children, className, ...otherProps }) => {
  if (!children && !translate) return null;
  return (
    <p className={className} {...otherProps}>
      {children ? children : i18n.t(translate)}
    </p>
  );
};
