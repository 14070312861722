import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { formatMoney } from "@/helpers/formatMoney";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { DollarSign, Handshake, TrendingDown, TrendingUp, UserCheck, Users } from "lucide-react";

export const AdminDashboardOverviewTiles = ({ data }) => {
  const { revenue, leads_count, contracts_count, active_leads_count } = data;

  return (
    <div className="grid gap-4 md:grid-cols-2 md:gap-5 lg:grid-cols-4">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">{i18n.t("Przychody")}</CardTitle>
          <DollarSign className="h-6 w-6 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className={cn("text-2xl font-bold", revenue.growth < 0 ? "text-red-500" : "text-green-500")}>
            {formatMoney(revenue.current_month)}
          </div>
          {revenue.growth > 0 ? (
            <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
              <TrendingUp size={16} />
              {i18n.t("Wzrost o ")} {revenue.growth + "%"} {i18n.t(" względem poprzedniego miesiąca")}
            </p>
          ) : (
            revenue.growth < 0 && (
              <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
                <TrendingDown size={16} />
                {i18n.t("Spadek o")} {revenue.growth + "%"} {i18n.t(" względem poprzedniego miesiąca")}
              </p>
            )
          )}
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">{i18n.t("Nowych kontaktów")}</CardTitle>
          <Users className="h-6 w-6 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{leads_count.current_month}</div>
          {leads_count.growth > 0 ? (
            <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
              <TrendingUp size={16} />
              {i18n.t("Wzrost o ")} {leads_count.growth + "%"} {i18n.t(" względem poprzedniego miesiąca")}
            </p>
          ) : (
            leads_count.growth < 0 && (
              <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
                <TrendingDown size={16} />
                {i18n.t("Spadek o")} {leads_count.growth + "%"} {i18n.t(" względem poprzedniego miesiąca")}
              </p>
            )
          )}
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">{i18n.t("Nowych umów")}</CardTitle>
          <Handshake className="h-6 w-6 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{contracts_count.current_month}</div>
          {contracts_count.growth > 0 ? (
            <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
              <TrendingUp size={16} />
              {i18n.t("Wzrost o ")} {contracts_count.growth + "%"} {i18n.t(" względem poprzedniego miesiąca")}
            </p>
          ) : (
            contracts_count.growth < 0 && (
              <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
                <TrendingDown size={16} />
                {i18n.t("Spadek o")} {contracts_count.growth + "%"} {i18n.t(" względem poprzedniego miesiąca")}
              </p>
            )
          )}
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">{i18n.t("Aktywne szanse sprzedaży")}</CardTitle>
          <UserCheck className="h-6 w-6 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{active_leads_count.current_month}</div>
          {active_leads_count.growth > 0 ? (
            <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
              <TrendingUp size={16} />
              {i18n.t("Wzrost o ")} {active_leads_count.growth + "%"} {i18n.t(" względem poprzedniego miesiąca")}
            </p>
          ) : (
            active_leads_count.growth < 0 && (
              <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
                <TrendingDown size={16} />
                {i18n.t("Spadek o")} {active_leads_count.growth + "%"} {i18n.t(" względem poprzedniego miesiąca")}
              </p>
            )
          )}
        </CardContent>
      </Card>
    </div>
  );
};
