import { useAuth } from "@/hooks/useAuth";
import { useContractPolicy } from "@/policies/contracts/useContractPolicy";

export const useContractUserPolicy = () => {
  const { hasPermission } = useAuth();
  const contractPolicy = useContractPolicy();

  const viewAny = () => {
    return hasPermission("access_contract_users");
  };

  const create = () => {
    return hasPermission("create_contract_user");
  };

  const destroy = () => {
    return (contractPolicy.view() && hasPermission("delete_contract_user")) || contractPolicy.destroyAny();
  };

  return {
    viewAny,
    create,
    destroy,
  };
};
