import apiClient from "@/api/apiClient";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { CalculationDetails } from "@/components/Calculations/CalculationDetails/CalculationDetails";
import { Layout } from "@/components/layout/Layout";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useQuery } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router-dom";

export const ContractCalculationDetailsPage = () => {
  const { id: contractId } = useParams();
  const { state } = useLocation();
  const contract = state.contract;

  const { isLoading, data: calculation } = useQuery({
    queryKey: ["contract", contractId, "calculation"],
    queryFn: () => apiClient.getContractCalculation(contractId),
  });
  useDocumentTitle(`Kalkulacja umowy ${contract.identifier}`);

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-8">
        <Breadcrumbs idAlias={contract.identifier} />
      </div>
      <CalculationDetails calculation={calculation?.data} />
    </Layout>
  );
};
