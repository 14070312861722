import { ColorPicker } from "@/components/forms/ColorPicker";
import { ErrorMessage } from "@/components/forms/ErrorMessage";
import { Label } from "@/components/ui/label";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { Controller, useFormContext } from "react-hook-form";

export const FormColorPicker = ({ name, label, ...rest }) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className="w-full">
          <Label className={cn("", errors[name] && "text-destructive")}>{i18n.t(label)}</Label>
          <ColorPicker {...field} {...rest} />
          <ErrorMessage error={errors[name]?.message} />
        </div>
      )}
    />
  );
};
