import { useAuth } from "@/hooks/useAuth";

export const useWarehouseOperationPolicy = () => {
  const { hasPermission } = useAuth();

  const viewAny = () => {
    return hasPermission("access_warehouse_operations");
  };

  return {
    viewAny,
  };
};
