import { ChartContainerWrapper } from "@/components/Charts/ChartContainerWrapper";
import { StatCardDescription } from "@/components/StatCardDescription";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";
import { slugify } from "@/helpers/stringHelpers";
import i18n from "@/i18n";
import { Bar, BarChart, CartesianGrid, Label, LabelList, Rectangle, ReferenceLine, XAxis } from "recharts";

export const DepartmentUserContractCountStat = ({ data, dateRange, isLoading }) => {
  let max = { value: 0, index: 0 };
  let avg = 0;
  const chartData =
    data &&
    Object.keys(data).map((key, index) => {
      const value = data[key];
      avg += value;
      if (data[key] > max.value) {
        max.index = index;
        max.value = value;
      }
      return {
        user: slugify(key),
        value,
        fill: `var(--color-${slugify(key)})`,
      };
    });

  const chartConfig =
    data &&
    Object.keys(data).reduce(
      (config, key, index) => {
        config[slugify(key)] = {
          label: key,
          color: `hsl(var(--chart-${index + 1}))`,
        };
        return config;
      },
      { value: { label: i18n.t("Ilość umów") } },
    );

  return (
    <Card className="col-span-2 h-fit">
      <CardHeader>
        <CardTitle>{i18n.t("Skuteczność handlowców")}</CardTitle>
        <StatCardDescription dateRange={dateRange} />
      </CardHeader>
      <CardContent>
        <ChartContainerWrapper
          isLoading={isLoading}
          data={chartData}
          config={chartConfig}
          className="w-full max-h-[300px]"
        >
          <BarChart accessibilityLayer data={chartData}>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="user"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) => chartConfig[value]?.label}
            />
            <ChartTooltip content={<ChartTooltipContent />} />
            <Bar
              dataKey="value"
              strokeWidth={2}
              radius={8}
              activeIndex={max.index}
              activeBar={({ ...props }) => {
                return (
                  <Rectangle
                    {...props}
                    fillOpacity={0.8}
                    stroke={props.payload.fill}
                    strokeDasharray={4}
                    strokeDashoffset={4}
                  />
                );
              }}
            >
              <LabelList dataKey="value" offset={8} className="fill-foreground text-sm font-semibold" fontSize={12} />
            </Bar>
            {chartData?.length && (
              <ReferenceLine
                y={avg / chartData.length}
                stroke="hsl(var(--muted-foreground))"
                strokeDasharray="3 3"
                strokeWidth={1}
              >
                <Label position="insideBottomLeft" value="Średnia" offset={10} fill="hsl(var(--foreground))" />
                <Label
                  position="insideTopLeft"
                  value={(avg / chartData.length).toFixed(0)}
                  className="text-lg"
                  fill="hsl(var(--foreground))"
                  offset={10}
                  startOffset={100}
                />
              </ReferenceLine>
            )}
          </BarChart>
        </ChartContainerWrapper>
      </CardContent>
    </Card>
  );
};
