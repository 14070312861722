import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { UserAvatar } from "@/components/Users/UserAvatar";
import { UserRole } from "@/enums/UserRoleEnum";
import { formatPhoneNumber } from "@/helpers/formatPhoneNumber";
import { useAuth } from "@/hooks/useAuth";
import i18n from "@/i18n";
import { useDepartmentPolicy } from "@/policies/useDepartmentPolicy";
import { useUserPolicy } from "@/policies/user/useUserPolicy";
import { routes } from "@/routes";
import { ArrowDown, ArrowUp, ArrowUpDown, MoreHorizontal, Pencil, Trash } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";

export const useUsersTableColumns = (handleDelete) => {
  const { user: authUser } = useAuth();
  const userPolicy = useUserPolicy();
  const departmentPolicy = useDepartmentPolicy();
  const navigate = useNavigate();

  const columns = [
    {
      id: "name",
      name: "Imię i nazwisko",
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Imię i nazwisko")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        if (row.original.id !== authUser.id) {
          if (userPolicy.view(row.original)) {
            return (
              <Link
                to={`${routes.users}/${row.original.id}`}
                className="flex flex-row items-center gap-3 font-medium hover:underline"
              >
                <UserAvatar user={row.original} />
                {row.getValue("name")}
              </Link>
            );
          } else {
            return (
              <div className="flex flex-row items-center gap-3 font-medium">
                <UserAvatar user={row.original} />
                {row.getValue("name")}
              </div>
            );
          }
        } else {
          return (
            <div className="flex flex-row items-center gap-3 font-medium text-primary">
              <UserAvatar user={row.original} />
              {row.getValue("name")}
            </div>
          );
        }
      },
    },
    {
      id: "email",
      name: "Email",
      accessorKey: "email",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Email")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => (
        <a className="font-semibold lowercase hover:underline" href={`mailto:${row.getValue("email")}`}>
          {row.getValue("email")}
        </a>
      ),
    },
    {
      id: "phone_number",
      name: "Numer telefonu",
      accessorKey: "phone_number",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Numer telefonu")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        if (row.getValue("phone_number")) {
          return (
            <a className="font-semibold lowercase hover:underline" href={`tel:${row.getValue("phone_number")}`}>
              {formatPhoneNumber(row.getValue("phone_number"))}
            </a>
          );
        } else {
          return <p>{i18n.t("Brak")}</p>;
        }
      },
    },
    {
      id: "roles",
      accessorKey: "roles",
      name: "Rola",
      enableSorting: false,
      header: () => <p className="text-left px-4">{i18n.t("Rola")}</p>,
      cell: ({ row }) => {
        const role = row.original.roles[0]?.name;
        return UserRole[role]?.name;
      },
    },
    departmentPolicy.viewAny()
      ? {
          id: "department",
          accessorKey: "department",
          name: "Oddział",
          header: () => <div className="text-right">Oddział</div>,
          cell: ({ row }) => {
            const department = row.getValue("department");
            if (!department) return <div className="text-right">{i18n.t("Brak")}</div>;
            else {
              if (departmentPolicy.view(department)) {
                return (
                  <div className="text-right">
                    <Link to={`${routes.departments}/${department.id}`} className="font-medium hover:underline">
                      {department.name}
                    </Link>
                  </div>
                );
              } else {
                return <div className="text-right">{department.name}</div>;
              }
            }
          },
        }
      : { id: "department" },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const user = row.original;
        if (!userPolicy.destroy(user) && !userPolicy.update(user)) return null;
        const handleEdit = () => {
          return navigate(`${routes.users}/${user.id}/edytuj`);
        };

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {userPolicy.update(user) && (
                <DropdownMenuItem onClick={handleEdit} className="flex flex-row items-center">
                  <Pencil size={16} className="mr-1 text-primary" />
                  <span className="font-semibold">{i18n.t("Edytuj")}</span>
                </DropdownMenuItem>
              )}
              {userPolicy.destroy(user) && userPolicy.update(user) && <DropdownMenuSeparator />}
              {userPolicy.destroy(user) && (
                <DeleteAlertDialog
                  onConfirm={() => handleDelete(user.id)}
                  message="Czy na pewno chcesz usunąć tego użytkownika? Tej operacji nie można cofnąć"
                  trigger={
                    <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                      <Trash size={16} className="mr-1 text-primary" />
                      <span className="font-semibold">{i18n.t("Usuń")}</span>
                    </DropdownMenuItem>
                  }
                />
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
