import { ClearFiltersButton } from "@/components/Filters/ClearFiltersButton";
import { ColumnFilter } from "@/components/Filters/ColumnFilter";
import { MultipleSelectFilter } from "@/components/Filters/MultipleSelectFilter";
import { SearchFilter } from "@/components/Filters/SearchFilter";
import { getVoivodeshipOptions } from "@/helpers/address/getVoivodeshipOptions";
import { useFilters } from "@/hooks/table/useFilters";

export const InstallationCrewFilters = ({ table }) => {
  const { filter, setFilter } = useFilters(table);
  const { voivodeshipOptions } = getVoivodeshipOptions();

  return (
    <div className="flex flex-row flex-wrap gap-3 mb-3">
      <div className="flex flex-row gap-3 w-full lg:max-w-sm">
        <ClearFiltersButton table={table} />
        <SearchFilter value={filter("search")} onChange={(value) => setFilter("search", value)} />
      </div>
      <MultipleSelectFilter
        options={voivodeshipOptions}
        setValue={(value) => setFilter("voivodeships", value)}
        title="Województwo"
        value={filter("voivodeships")}
      />
      <ColumnFilter table={table} />
    </div>
  );
};
