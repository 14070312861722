import { ErrorMessage } from "@/components/forms/ErrorMessage";
import { MultipleSelect } from "@/components/forms/MultipleSelect";
import { Label } from "@/components/ui/label";
import i18n from "@/i18n";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

export const FormMultipleSelect = ({
  name,
  options,
  parent,
  label,
  className,
  setCurrentValue = () => {},
  ...rest
}) => {
  const {
    formState: { errors },
    control,
    watch,
    setValue,
    getFieldState,
  } = useFormContext();

  const parentValue = watch(parent);
  let disabled = parent && !parentValue;

  const handleChange = (value) => {
    setValue(name, value);
    setCurrentValue(value);
  };

  useEffect(() => {
    if (parent && getFieldState(parent)?.isDirty) {
      setValue(name, null);
    }
  }, [parentValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div>
          <Label className={errors[name] && "text-destructive"} htmlFor={name}>
            {i18n.t(label)}
          </Label>
          <MultipleSelect
            id={name}
            label={label}
            name={name}
            setValue={handleChange}
            options={options}
            disabled={disabled}
            className={className}
            {...field}
            {...rest}
          />
          <ErrorMessage error={errors[name]?.message} />
        </div>
      )}
    />
  );
};
