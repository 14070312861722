export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return;
  let chunkedArray = [];
  for (let i = phoneNumber.length; i > 0; i -= 3) {
    let chunk = phoneNumber.slice(Math.max(0, i - 3), i);
    chunkedArray.unshift(chunk);
  }

  // Join the chunks with a space
  return chunkedArray.join(" ");
};
