import { Breadcrumbs } from "@/components/Breadcrumbs";
import { DepartmentCreateDialog } from "@/components/Departments/DepartmentCreateDialog";
import { DepartmentsTable } from "@/components/Departments/DepartmentsTable";
import { Layout } from "@/components/layout/Layout";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useDepartmentPolicy } from "@/policies/useDepartmentPolicy";

export const DepartmentsPage = () => {
  useDocumentTitle("Oddziały");
  const departmentPolicy = useDepartmentPolicy();

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          <DepartmentCreateDialog />
        </div>
      </div>
      {departmentPolicy.viewAny() && <DepartmentsTable />}
    </Layout>
  );
};
