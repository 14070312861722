import { FileCheck, FileCheck2, FileClock, FilePlus2, FileX } from "lucide-react";

export const BillingStatus = Object.freeze({
  NEW: {
    value: "NEW",
    name: "Nowa",
    icon: () => <FilePlus2 className="mr-2 h-6 w-6 text-primary" />,
  },
  ACCEPTED: {
    value: "ACCEPTED",
    name: "Zaakceptowana",
    icon: () => <FileCheck className="mr-2 h-6 w-6 text-green-500" />,
  },
  NEEDS_CORRECTION: {
    value: "NEEDS_CORRECTION",
    name: "Do poprawy",
    icon: () => <FileX className="mr-2 h-6 w-6 text-destructive" />,
  },
  CORRECTED: {
    value: "CORRECTED",
    name: "Poprawiona",
    icon: () => <FileCheck className="mr-2 h-6 w-6 text-yellow-500" />,
  },
  PENDING: {
    value: "PENDING",
    name: "Oczekująca",
    icon: () => <FileClock className="mr-2 h-6 w-6 text-muted-foreground" />,
  },
  SETTLED: {
    value: "SETTLED",
    name: "Rozliczona",
    icon: () => <FileCheck2 className="mr-2 h-6 w-6 text-muted-foreground" />,
  },

  getValues() {
    return Object.values(this).filter((value) => typeof value === "object" && value !== null);
  },

  getNames() {
    return this.getValues().map((status) => status.name);
  },

  getForEdit(hasPermission) {
    if (hasPermission("update_any_billing")) {
      return [this.NEW, this.ACCEPTED, this.NEEDS_CORRECTION, this.CORRECTED];
    }
    return [];
  },
});
