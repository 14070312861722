import { format } from "date-fns";

export const formatDate = (date) => {
  return format(date, "dd-MM-yyyy");
};

export const formatDateTime = (date) => {
  return format(date, "dd-MM-yyyy HH:mm:ss");
};

export const formatFromToDates = (dateObj) => {
  return {
    from: dateObj?.from ? formatDate(dateObj.from) : undefined,
    to: dateObj?.to ? formatDate(dateObj.to) : undefined,
  };
};
