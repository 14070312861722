import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormSelect } from "@/components/forms/FormSelect";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { ApplicationStatusContext } from "@/context/ApplicationStatusContext";
import { UsersContext } from "@/context/UsersContext";
import i18n from "@/i18n";
import { useApplicationPolicy } from "@/policies/application/useApplicationPolicy";
import { useUserPolicy } from "@/policies/user/useUserPolicy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Check, X } from "lucide-react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

export const ApplicationEditDialog = ({ trigger, application }) => {
  const queryClient = useQueryClient();
  const applicationPolicy = useApplicationPolicy();
  const userPolicy = useUserPolicy();
  const { statusOptions } = useContext(ApplicationStatusContext);
  const { userOptions, isLoading } = useContext(UsersContext);

  const [isOpen, setIsOpen] = useState(false);

  const validationSchema = Yup.object({
    user_id: Yup.string().optional().nullable(),
    application_status_id: Yup.string().required(i18n.t("Pole jest wymagane")),
    description: Yup.string().nullable(),
  });

  const onSubmit = (data) => {
    updateApplicationMutation.mutate({ applicationId: application.id, data });
  };

  const defaultValues = {
    user_id: application?.user?.id ?? undefined,
    application_status_id: application?.status?.id ?? undefined,
    description: application.description,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const updateApplicationMutation = useMutation({
    mutationFn: apiClient.updateApplication,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["applications"] });
      queryClient.invalidateQueries({ queryKey: ["application", application.id] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie zapisano." /> });
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  if (!applicationPolicy.update()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Edycja wniosku")}</DialogTitle>
          <DialogDescription>{application.lead.name}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            {userPolicy.viewAny() && (
              <FormCombobox
                label="Użytkownik odpowiedzialny"
                name="user_id"
                options={userOptions}
                isLoading={isLoading}
              />
            )}
            <FormSelect label="Status" name="application_status_id" options={statusOptions} />
            <FormTextarea name="description" label="Opis" />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Zapisz"
              leftIcon={<Check size={20} />}
              isLoading={updateApplicationMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
