import { ApplicationsTable } from "@/components/Applications/ApplicationsTable";
import { ApplicationSettings } from "@/components/Applications/Settings/ApplicationSettings";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Layout } from "@/components/layout/Layout";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import i18n from "@/i18n";
import { useApplicationStatusPolicy } from "@/policies/application/useApplicationStatusPolicy";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const ApplicationsPage = () => {
  useDocumentTitle("Wnioski");
  const { state } = useLocation();
  const applicationStatusPolicy = useApplicationStatusPolicy();

  const [activeTab, setActiveTab] = useState("table");

  useEffect(() => {
    if (state?.tab) {
      setActiveTab(state.tab);
    }
  }, [state]);

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
      </div>
      <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value)}>
        {applicationStatusPolicy.create() && (
          <TabsList className="mb-6">
            <TabsTrigger value="table">{i18n.t("Tabela")}</TabsTrigger>
            {applicationStatusPolicy.create() && <TabsTrigger value="settings">{i18n.t("Ustawienia")}</TabsTrigger>}
          </TabsList>
        )}
        <TabsContent value="table">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <ApplicationsTable />
          </div>
        </TabsContent>
        {applicationStatusPolicy.create() && (
          <TabsContent value="settings">
            <ApplicationSettings />
          </TabsContent>
        )}
      </Tabs>
    </Layout>
  );
};
