import { ChartContainerWrapper } from "@/components/Charts/ChartContainerWrapper";
import { StatCardDescription } from "@/components/StatCardDescription";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import i18n from "@/i18n";
import { format, parse } from "date-fns";
import { Bar, BarChart, CartesianGrid, Label, LabelList, Rectangle, ReferenceLine, XAxis } from "recharts";

export const LeadCountStat = ({ data, dateRange, isLoading }) => {
  const max = { value: 0, index: 0 };
  let avg = 0;
  const chartData =
    data &&
    Object.keys(data)
      .map((month, index) => {
        const value = data[month];
        avg += value;
        if (data[month] > max.value) {
          max.index = index;
          max.value = value;
        }

        return {
          month: format(parse(month, "yyyy-MM", new Date()), "MMM yyyy"),
          value,
        };
      })
      .sort(
        (a, b) => parse(a.month, "MMM yyyy", new Date()).getTime() - parse(b.month, "MMM yyyy", new Date()).getTime(),
      );

  const chartConfig = {
    value: {
      label: "Ilość",
      color: "hsl(var(--chart-1))",
    },
  };

  return (
    <Card className="col-span-2">
      <CardHeader>
        <CardTitle>{i18n.t("Ilość kontaktów z w czasie")}</CardTitle>
        <StatCardDescription dateRange={dateRange} />
      </CardHeader>
      <CardContent>
        <ChartContainerWrapper
          isLoading={isLoading}
          data={chartData}
          config={chartConfig}
          className="w-full max-h-[300px]"
        >
          <BarChart
            accessibilityLayer
            data={chartData}
            margin={{
              top: 20,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis dataKey="month" tickLine={false} tickMargin={10} axisLine={false} />
            <Bar
              dataKey="value"
              fill="var(--color-value)"
              radius={8}
              activeIndex={max.index}
              activeBar={({ ...props }) => {
                return (
                  <Rectangle
                    {...props}
                    fillOpacity={0.8}
                    stroke="hsl(var(--chart-1))"
                    strokeDasharray={4}
                    strokeDashoffset={4}
                  />
                );
              }}
            >
              <LabelList offset={12} className="fill-foreground" fontSize={12} />
            </Bar>
            {chartData?.length && (
              <ReferenceLine
                y={avg / chartData.length}
                stroke="hsl(var(--muted-foreground))"
                strokeDasharray="3 3"
                strokeWidth={1}
              >
                <Label position="insideBottomLeft" value="Średnia" offset={10} fill="hsl(var(--foreground))" />
                <Label
                  position="insideTopLeft"
                  value={(avg / chartData.length).toFixed(0)}
                  className="text-lg"
                  fill="hsl(var(--foreground))"
                  offset={10}
                  startOffset={100}
                />
              </ReferenceLine>
            )}
          </BarChart>
        </ChartContainerWrapper>
      </CardContent>
    </Card>
  );
};
