import { Button as ShadButton } from "@/components/ui/button";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { Loader2 } from "lucide-react";
import { forwardRef } from "react";

const Button = forwardRef(
  (
    { children, disabled = false, title = null, isLoading = false, leftIcon, rightIcon, className, ...otherProps },
    ref,
  ) => {
    return isLoading ? (
      <ShadButton ref={ref} className={cn("flex flex-row items-center gap-2", className)} disabled {...otherProps}>
        <Loader2 className="h-4 w-4 animate-spin" />
        {title || children ? i18n.t("Ładowanie...") : null}
      </ShadButton>
    ) : (
      <ShadButton
        ref={ref}
        className={cn("flex flex-row items-center gap-2", className, disabled && "disabled:cursor-not-allowed")}
        disabled={disabled}
        {...otherProps}
      >
        {leftIcon && <span>{leftIcon}</span>}
        {title ? i18n.t(title) : children}
        {rightIcon && <span>{rightIcon}</span>}
      </ShadButton>
    );
  },
);

Button.displayName = "Button";
export { Button };
