import { useAuth } from "@/hooks/useAuth";

export const useContractNotePolicy = () => {
  const { user, hasPermission } = useAuth();

  const viewAny = () => {
    return hasPermission("access_contract_notes");
  };

  const create = () => {
    return hasPermission("create_contract_note");
  };

  const update = (contractNote) => {
    return contractNote?.user?.id === user?.id && hasPermission("update_contract_note");
  };

  const destroy = (contractNote) => {
    return (
      hasPermission("delete_any_contract_note") ||
      (contractNote?.user?.id === user?.id && hasPermission("delete_contract_note"))
    );
  };

  return {
    viewAny,
    create,
    update,
    destroy,
  };
};
