import { Button } from "@/components/Button";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import i18n from "@/i18n";
import { useInstallationPolicy } from "@/policies/installation/useInstallationPolicy";
import { routes } from "@/routes";
import { format, parse } from "date-fns";
import { Link } from "react-router-dom";

export const InstallationCrewInstallationsCard = ({ installations }) => {
  const installationPolicy = useInstallationPolicy();
  return (
    <Card className="flex-1 h-fit shadow-md pr-2">
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5">
          <CardTitle>{i18n.t("Montaże")}</CardTitle>
          <CardDescription>{i18n.t("Montaże realizowane przez tą ekipę")}</CardDescription>
        </div>
      </CardHeader>
      <ScrollArea className="w-full flex max-h-96 flex-col">
        <CardContent>
          <div className="flex flex-col gap-3 justify-center items-center">
            {installations.map((installation) => {
              return (
                <Card key={installation.id} className="p-4 shadow-md border-l-4 border-l-primary w-full">
                  <div className="flex flex-row justify-between items-start w-full gap-3">
                    <div className="flex flex-col gap-2 items-center sm:items-start w-full whitespace-nowrap">
                      {installationPolicy.view() ? (
                        <Link to={`${routes.installations}/${installation.id}`}>
                          <Button
                            variant="link"
                            title={installation.name}
                            className="m-0 p-0 h-fit text-lg font-semibold"
                          />
                        </Link>
                      ) : (
                        <span className="font-semibold text-lg">{installation.name}</span>
                      )}
                      <div className="text-muted-foreground flex flex-row gap-1 items-center">
                        <p>{i18n.t("Termin") + ":"}</p>
                        {installation.date
                          ? format(parse(installation.date, "yyyy-MM-dd", new Date()), "dd MMM yyyy")
                          : i18n.t("Nie ustalono")}
                      </div>
                      <div className="text-muted-foreground">
                        <Badge className="h-8 rounded-md" style={{ backgroundColor: installation.status.color }}>
                          {installation.status.name}
                        </Badge>
                      </div>
                    </div>
                  </div>
                </Card>
              );
            })}
          </div>
        </CardContent>
      </ScrollArea>
    </Card>
  );
};
