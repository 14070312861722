import { DatePicker } from "@/components/forms/DatePicker";
import { ErrorMessage } from "@/components/forms/ErrorMessage";
import { Label } from "@/components/ui/label";
import i18n from "@/i18n";
import { Controller, useFormContext } from "react-hook-form";

export const FormDatePicker = ({ name, label, placeholder = "Wybierz datę", description, ...rest }) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div>
          <Label className={errors[name] && "text-destructive"} htmlFor={name}>
            {i18n.t(label)}
          </Label>
          <DatePicker
            date={field.value}
            setDate={field.onChange}
            placeholder={placeholder}
            description={description}
            {...field}
            {...rest}
            // disabled={(date) => date > new Date() || date < new Date("1900-01-01")}
          />
          <ErrorMessage error={errors[name]?.message} />
        </div>
      )}
    />
  );
};
