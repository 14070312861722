import { AuditStatusCreateDialog } from "@/components/Audits/Settings/Statuses/AuditStatusCreateDialog";
import { AuditStatuses } from "@/components/Audits/Settings/Statuses/AuditStatuses";
import { ProcessCreateDialog } from "@/components/Process/ProcessCreateDialog";
import { ProcessSettings } from "@/components/Process/ProcessSettings";
import i18n from "@/i18n";
import { useAuditStatusPolicy } from "@/policies/audit/useAuditStatusPolicy";
import { useProcessPolicy } from "@/policies/useProcessPolicy";

export const AuditSettings = () => {
  const auditStatusPolicy = useAuditStatusPolicy();
  const processPolicy = useProcessPolicy();

  return (
    <div className="flex flex-col gap-5 w-full">
      {auditStatusPolicy.update() && (
        <div>
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Statusy")}</h2>
            <AuditStatusCreateDialog />
          </div>
          <AuditStatuses />
        </div>
      )}
      {processPolicy.create() && (
        <div>
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Procesy")}</h2>
            <ProcessCreateDialog appliesTo="Audit" />
          </div>
          <ProcessSettings model="Audit" />
        </div>
      )}
    </div>
  );
};
