import { Button } from "@/components/Button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { useCalculatorCustomFields } from "@/hooks/useCalculatorCustomFields";
import i18n from "@/i18n";
import { Info } from "lucide-react";

export const CalculatorCustomFieldsPopover = () => {
  const customFields = useCalculatorCustomFields();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" title="Dostępne pola" leftIcon={<Info size={20} />} />
      </PopoverTrigger>
      <PopoverContent className="max-w-3xl z-500">
        <div className="grid gap-4">
          <div className="space-y-2">
            <h4 className="font-medium leading-none">{i18n.t("Dostępne pola")}</h4>
            <p className="text-sm text-muted-foreground">
              {i18n.t(
                "Pola dostępne do wykorzystania w kalkulatorach, pamiętaj zachować szczególną ostrożność tworząc reguły na ich podstawie gdyż nie zawsze będą one możliwe do wyliczenia.",
              )}
            </p>
          </div>
          <div className="grid gap-2">
            <div className="grid grid-cols-2 items-center gap-4 font-semibold text-sm">
              <span>{i18n.t("Klucz do wykorzystania")}</span>
              <span>{i18n.t("Nazwa i opis")}</span>
            </div>
          </div>
          <div className="grid gap-2">
            <div className="grid grid-cols-2 items-start gap-4">
              <span className="border rounded-md p-1 text-primary font-medium w-fit">identyfikator_pola</span>
              <span className="flex flex-col gap-1">
                <span>{i18n.t("Identyfikator pola")}</span>
                <span className="text-muted-foreground text-xs font-medium">
                  {i18n.t(
                    "Identyfikator pola pozwala na otrzymanie wartości tego pola którą wpisał użytkownik, działa tylko w przypadku pól typu Lista rozwijana i Pole liczbowe. W przypadku Listy rozwijanej jest to wartość opcji którą wybrał użytkownik",
                  )}
                </span>
              </span>
            </div>
            <div className="grid grid-cols-2 items-start gap-4">
              <span className="border rounded-md p-1 text-primary font-medium w-fit">identyfikator_pola_cena</span>
              <span className="flex flex-col gap-1">
                <span>{i18n.t("Cena jednostkowa pola")}</span>
                <span className="text-muted-foreground text-xs font-medium">
                  {i18n.t(
                    "Cena jednostkowa pola pozwala na otrzymanie wartości ceny jednostkowej pola która nie jest mnożona przez inne zmienne, działa tylko w przypadku pola Pole liczbowe",
                  )}
                </span>
              </span>
            </div>
            <div className="grid grid-cols-2 items-start gap-4">
              <span className="border rounded-md p-1 text-primary font-medium w-fit">identyfikator_pola_ilosc</span>
              <span className="flex flex-col gap-1">
                <span>Ilosc pola</span>
                <span className="text-muted-foreground text-xs font-medium">
                  {i18n.t("Pozwala na otrzymanie ilości tego pola którą wpisał użytkownik")}
                </span>
              </span>
            </div>
            {Object.keys(customFields).map((key, i) => (
              <div key={key + i} className="grid grid-cols-2 items-start gap-4">
                <span className="border rounded-md p-1 text-primary font-medium w-fit">{key}</span>
                <span className="flex flex-col gap-1">
                  <span>{customFields[key].name}</span>
                  <span className="text-muted-foreground text-xs font-medium">{customFields[key].description}</span>
                </span>
              </div>
            ))}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
