import i18n from "@/i18n";
import { XCircle } from "lucide-react";

export const ErrorToast = ({ title }) => {
  return (
    <div className="flex flex-row gap-3">
      <XCircle size={30} className="text-destructive" />
      {i18n.t(title)}
    </div>
  );
};
