import { BillingGroupCreateForm } from "@/components/Billings/Groups/BillingGroupCreateForm";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Layout } from "@/components/layout/Layout";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";

export const BillingGroupCreatePage = () => {
  useDocumentTitle("Dodawanie faktury rozliczeniowej");
  return (
    <Layout>
      <div className="flex flex-col justify-between items-center sm:flex-row">
        <Breadcrumbs />
      </div>
      <BillingGroupCreateForm />
    </Layout>
  );
};
