import { Button } from "@/components/Button";
import { EmptyState } from "@/components/EmptyState";
import { Notification } from "@/components/Notifications/Notification";
import { useState } from "react";

export const NotificationsList = ({ notifications }) => {
  const NOTIFICATIONS_COUNT = 10;
  const [showAll, setShowAll] = useState(NOTIFICATIONS_COUNT);

  return (
    <div className="flex flex-col items-center justify-center gap-3 w-full lg:w-1/2">
      {notifications.length === 0 && <EmptyState title="Brak powiadomień" />}
      {notifications.slice(0, showAll).map((notification) => (
        <Notification key={notification.id} notification={notification} />
      ))}
      {notifications.length > showAll && (
        <Button onClick={() => setShowAll(notifications.length)} title="Pokaż więcej" />
      )}
    </div>
  );
};
