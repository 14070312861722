import apiClient from "@/api/apiClient";
import { CalendarView } from "@/components/CalendarView";
import { FullPageLoader } from "@/components/FullPageLoader";
import { getCalendarEvents } from "@/helpers/calendar/getCalendarEvents";
import { useUserPolicy } from "@/policies/user/useUserPolicy";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const UserCalendar = () => {
  const userPolicy = useUserPolicy();
  const { id: userId } = useParams();
  const [events, setEvents] = useState([]);

  const {
    isLoading,
    data: calendar,
    error,
  } = useQuery({
    queryKey: ["user", userId, "calendar"],
    queryFn: () => apiClient.getCalendar({ user: userId }),
    staleTime: 1000 * 60 * 30,
    enabled: userPolicy.viewCalendar(),
  });

  useEffect(() => {
    setEvents(() => []);
    if (!isLoading && calendar && !error) {
      setEvents(getCalendarEvents(calendar.data));
    }
  }, [isLoading, calendar, error]);

  return isLoading ? <FullPageLoader /> : <CalendarView events={events} />;
};
