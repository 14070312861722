import { FullPageLoader } from "@/components/FullPageLoader";
import { Container } from "@/components/layout/Container";
import { Sidebar } from "@/components/layout/Sidebar";

export const Layout = ({ children, isLoading = false }) => {
  return (
    <>
      <Sidebar />
      <Container>{isLoading ? <FullPageLoader /> : children}</Container>
    </>
  );
};
