import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { InstallationEditDialog } from "@/components/Installations/InstallationEditDialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { UserAvatarLink } from "@/components/Users/UserAvatarLink";
import i18n from "@/i18n";
import { useContractPolicy } from "@/policies/contracts/useContractPolicy";
import { useInstallationPolicy } from "@/policies/installation/useInstallationPolicy";
import { useUserPolicy } from "@/policies/user/useUserPolicy";
import { routes } from "@/routes";
import { format, parse } from "date-fns";
import { ArrowDown, ArrowUp, ArrowUpDown, MoreHorizontal, Pencil, Trash } from "lucide-react";
import { Link } from "react-router-dom";

export const useInstallationsTableColumns = (handleDelete) => {
  const installationPolicy = useInstallationPolicy();
  const userPolicy = useUserPolicy();
  const contractPolicy = useContractPolicy();

  const columns = [
    {
      id: "name",
      name: "Nazwa",
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Nazwa")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const installation = row.original;
        if (installationPolicy.view()) {
          return (
            <Link
              to={`${routes.installations}/${row.original.id}`}
              className="flex flex-row items-center gap-3 font-medium hover:underline"
            >
              {installation.name}
            </Link>
          );
        } else {
          return <span>{installation.name}</span>;
        }
      },
    },
    {
      id: "contract",
      name: "Umowa",
      accessorKey: "contract",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Umowa")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const installation = row.original;
        if (contractPolicy.view()) {
          return (
            <Link
              to={`${routes.contracts}/${row.original.contract.id}`}
              className="flex flex-row items-center gap-3 font-medium hover:underline"
            >
              {installation.contract.identifier}
            </Link>
          );
        } else {
          return <span>{installation.contract.identifier}</span>;
        }
      },
    },
    {
      id: "status",
      name: "Status",
      accessorKey: "status",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Status")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const installation = row.original;
        return (
          <span
            className="rounded-md px-2 py-1 font-semibold whitespace-nowrap text-primary-foreground dark:text-foreground dark:bg-opacity-60"
            style={{ backgroundColor: installation.status.color }}
          >
            {installation.status.name}
          </span>
        );
      },
    },
    userPolicy.viewAny()
      ? {
          id: "user_id",
          name: "Osoba odpowiedzialna",
          accessorKey: "user_id",
          header: i18n.t("Osoba odpowiedzialna"),
          cell: ({ row }) => {
            const user = row.original.user;
            return <UserAvatarLink user={user} />;
          },
        }
      : { id: "user_id" },
    {
      id: "date",
      name: "Termin montażu",
      accessorKey: "date",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Termin montażu")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const installation = row.original;
        return (
          <span>
            {installation.date
              ? format(parse(installation.date, "yyyy-MM-dd", new Date()), "dd MMM yyyy")
              : i18n.t("Nie ustalono")}
          </span>
        );
      },
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const installation = row.original;
        if (!installationPolicy.destroy(installation) && !installationPolicy.update()) return null;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <InstallationEditDialog
                installation={installation}
                trigger={
                  <DropdownMenuItem className="flex flex-row items-center" onSelect={(e) => e.preventDefault()}>
                    <Pencil size={16} className="mr-1 text-primary" />
                    <span className="font-semibold">{i18n.t("Edytuj")}</span>
                  </DropdownMenuItem>
                }
              />
              {installationPolicy.destroy(installation) && (
                <>
                  <DropdownMenuSeparator />
                  <DeleteAlertDialog
                    onConfirm={() => handleDelete(installation.id)}
                    message="Czy na pewno chcesz usunąć ten montaż? Tej operacji nie można cofnąć"
                    trigger={
                      <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                        <Trash size={16} className="mr-1 text-primary" />
                        <span className="font-semibold">{i18n.t("Usuń")}</span>
                      </DropdownMenuItem>
                    }
                  />
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
