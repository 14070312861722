import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { UserTooltip } from "@/components/Users/UserTooltip";
import { Form } from "@/components/forms/Form";
import { FormDateTimePicker } from "@/components/forms/FormDateTimePicker";
import { FormField } from "@/components/forms/FormField";
import { FormSelect } from "@/components/forms/FormSelect";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { toast } from "@/components/ui/use-toast";
import { ActionType } from "@/enums/ActionTypeEnum";
import { isArchive } from "@/helpers/isArchive";
import { useAuth } from "@/hooks/useAuth";
import i18n from "@/i18n";
import { useLeadActionPolicy } from "@/policies/lead/useLeadActionPolicy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format, isSameDay, parse, subHours } from "date-fns";
import { ChevronDown, ChevronUp, MoreHorizontal, Pencil, Plus, Trash, X } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FcGoogle } from "react-icons/fc";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string().required(i18n.t("Pole jest wymagane")),
  type: Yup.string().required(i18n.t("Pole jest wymagane")),
  action_at: Yup.date(i18n.t("Pole musi być poprawną datą.")).required(i18n.t("Pole jest wymagane")),
  description: Yup.string().nullable(),
});

export const ClientAction = ({ action }) => {
  const leadActionPolicy = useLeadActionPolicy();
  const { id: leadId } = useParams();
  const { user } = useAuth();
  const [showDescription, setShowDescription] = useState(false);
  const queryClient = useQueryClient();
  const [editMode, setEditMode] = useState(false);
  const actionDate = parse(action.action_at, "yyyy-MM-dd HH:mm:ss", new Date());

  const defaultValues = {
    action_at: parse(action.action_at, "yyyy-MM-dd HH:mm:ss", new Date()),
    description: action.description,
    name: action.name,
    type: action.type,
  };

  const onSubmit = (data) => {
    updateLeadActionMutation.mutate({ leadId, actionId: action.id, action: data });
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const deleteLeadActionMutation = useMutation({
    mutationFn: apiClient.deleteLeadAction,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["lead", leadId, "actions"] });
        queryClient.invalidateQueries({ queryKey: ["user", user.id, "calendar"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const updateLeadActionMutation = useMutation({
    mutationFn: apiClient.updateLeadAction,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["lead", leadId, "actions"] });
      queryClient.invalidateQueries({ queryKey: ["user", user.id, "calendar"] });
      if (res.ok) {
        setEditMode(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const handleDelete = (leadId, actionId) => {
    deleteLeadActionMutation.mutate({ leadId, actionId });
  };

  const handleAddToCalendar = () => {
    const actionDateTime = parse(action.action_at, "yyyy-MM-dd HH:mm:ss", new Date());
    const eventTitle = action.name;
    const startTime = format(subHours(actionDateTime, 1), "yyyyMMdd'T'HHmmss'Z'");
    const endTime = format(actionDateTime, "yyyyMMdd'T'HHmmss'Z'");
    const details = "";
    const googleCalendarLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
      eventTitle,
    )}&dates=${startTime}/${endTime}&details=${encodeURIComponent(details)}&ctz=Europe/Warsaw`;
    window.open(googleCalendarLink, "_blank");
  };

  return editMode ? (
    <Card key={action.id} className="shadow-md border-l-4 border-l-primary">
      <Form onSubmit={onSubmit} form={form}>
        <CardContent className="pt-5">
          <div className="flex flex-col gap-4">
            <FormField name="name" label="Nazwa działania" />
            <FormSelect name="type" options={ActionType.getForLead()} label="Typ działania" />
            <FormDateTimePicker name="action_at" placeholder="Data działania" label="Data działania" />
            <FormTextarea
              name="description"
              label="Opis działania"
              placeholder="Opis działania (opcjonalne)"
              className={action.description && "min-h-40"}
            />
          </div>
        </CardContent>
        <CardFooter className="gap-3 items-center border-t px-6 py-4">
          <Button
            type="submit"
            title="Dodaj"
            leftIcon={<Plus size={20} />}
            isLoading={updateLeadActionMutation.isPending}
          />
          <Button
            title="Anuluj"
            type="button"
            leftIcon={<X size={20} />}
            variant="destructive"
            onClick={() => setEditMode(false)}
          />
        </CardFooter>
      </Form>
    </Card>
  ) : (
    <Card key={action.id} className="p-4 shadow-md border-l-4 border-l-primary">
      <div className="flex flex-row justify-between items-start w-full gap-3">
        <div
          className={`bg-accent py-3 px-1 rounded-lg flex flex-col min-w-20 ${
            isArchive(action.action_at, "yyyy-MM-dd HH:mm:ss") ? "text-gray-400 dark:text-gray-500 line-through" : ""
          }`}
        >
          <div className="font-semibold text-center">
            {isSameDay(actionDate, new Date()) ? i18n.t("Dziś") : format(actionDate, "dd LLL")}
          </div>
          <div className="font-bold text-lg text-center">{format(actionDate, "HH:mm")}</div>
        </div>
        <div className="flex flex-col items-center sm:items-start w-full whitespace-nowrap">
          <span className="font-semibold">
            {action.name}
            {action.type && <span className="font-semibold">{` (${ActionType[action.type]?.name})`}</span>}
          </span>

          <UserTooltip user={action?.user} />
          <div>
            {action.description && (
              <Button
                variant="link"
                className="flex flex-row gap-1 items-center px-0 py-0 h-8 text-foreground hover:text-accent-foreground"
                onClick={() => setShowDescription(!showDescription)}
              >
                <p>{i18n.t("Opis")}</p>
                {showDescription ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
              </Button>
            )}
          </div>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <Button variant="ghost" className="h-8 w-8 p-0 text-foreground">
              <span className="sr-only">{i18n.t("Otwórz menu")}</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem className="flex flex-row gap-2 font-semibold text-sm" onClick={handleAddToCalendar}>
              <FcGoogle size={20} />
              {i18n.t("Dodaj")}
            </DropdownMenuItem>
            {leadActionPolicy.update(action) && (
              <>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  className="flex flex-row gap-2 font-semibold text-sm"
                  onClick={() => setEditMode(!editMode)}
                >
                  <Pencil size={20} className="text-primary" />
                  {i18n.t("Edytuj")}
                </DropdownMenuItem>
              </>
            )}
            {leadActionPolicy.destroy(action) && (
              <>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  className="flex flex-row gap-2 font-semibold text-sm"
                  onClick={(e) => e.preventDefault()}
                >
                  <DeleteAlertDialog
                    message="Czy na pewno chcesz usunąć to działanie? Tej operacji nie można cofnąć"
                    onConfirm={() => handleDelete(action?.actionable?.id, action.id)}
                    trigger={
                      <Button
                        variant="ghost"
                        size="xs"
                        leftIcon={<Trash size={20} className="text-primary" />}
                        title="Usuń"
                      />
                    }
                  />
                </DropdownMenuItem>
              </>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      {showDescription && <div className="mt-2 whitespace-break-spaces">{action.description}</div>}
    </Card>
  );
};
