import { useAuditPolicy } from "@/policies/audit/useAuditPolicy";
import { routes } from "@/routes";
import { Link } from "react-router-dom";

export const AuditPopup = ({ audit }) => {
  const auditPolicy = useAuditPolicy();

  if (!audit) return null;

  const { address } = audit.lead;

  return (
    <p className="flex flex-col items-start justify-center text-xs gap-2">
      <span className="font-semibold text-sm text-muted-foreground flex items-center">
        {auditPolicy.view() ? <Link to={`${routes.audits}/${audit.id}`}>{audit.name}</Link> : audit.name}
      </span>
      <span className="flex flex-col">
        <span>{`${audit.street_number}, ${address.postal_code} ${address.city}`}</span>
        <span className="capitalize">{address.voivodeship}</span>
      </span>
    </p>
  );
};
