import { cn } from "@/lib/utils";

export const CardRow = ({ children, className }) => {
  return (
    <div
      className={cn(
        "flex flex-col items-start justify-start sm:justify-between sm:items-center sm:flex-row w-full hover:bg-accent py-3 px-3",
        className,
      )}
    >
      {children}
    </div>
  );
};
