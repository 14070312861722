import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { Layout } from "@/components/layout/Layout";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { UserLevelCreateDialog } from "@/components/Users/Levels/UserLevelCreateDialog";
import { UserLevelsTable } from "@/components/Users/Levels/UserLevelsTable";
import { UsersTable } from "@/components/Users/UsersTable";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import i18n from "@/i18n";
import { useUserLevelPolicy } from "@/policies/user/useUserLevelPolicy";
import { useUserPolicy } from "@/policies/user/useUserPolicy";
import { routes } from "@/routes";
import { Plus } from "lucide-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const UsersPage = () => {
  useDocumentTitle("Użytkownicy");
  const navigate = useNavigate();
  const userPolicy = useUserPolicy();
  const userLevelPolicy = useUserLevelPolicy();
  const { state } = useLocation();
  const [activeTab, setActiveTab] = useState("table");

  const handleCreate = () => {
    return navigate(routes.userCreate);
  };

  useEffect(() => {
    if (state?.tab) {
      setActiveTab(state.tab);
    }
  }, [state]);

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {activeTab === "levels" && <UserLevelCreateDialog />}
          {userPolicy.create() && activeTab === "table" && (
            <Button variant="outline" title="Dodaj użytkownika" leftIcon={<Plus size={20} />} onClick={handleCreate} />
          )}
        </div>
      </div>
      <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value)}>
        {userLevelPolicy.create() && (
          <TabsList className="mb-6">
            <TabsTrigger value="table">{i18n.t("Tabela")}</TabsTrigger>
            {userLevelPolicy.create() && <TabsTrigger value="levels">{i18n.t("Poziomy")}</TabsTrigger>}
          </TabsList>
        )}
        <TabsContent value="table">
          <UsersTable />
        </TabsContent>
        {userLevelPolicy.create() && (
          <TabsContent value="levels">
            <UserLevelsTable />
          </TabsContent>
        )}
      </Tabs>
    </Layout>
  );
};
