import apiClient from "@/api/apiClient";
import { Table } from "@/components/Table/Table";
import { DelegatedTasksFilters } from "@/components/Tasks/DelegatedTasksFilters";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { toast } from "@/components/ui/use-toast";
import { isEmpty } from "@/helpers/isEmpty";
import { useDelegatedTasksTableColumns } from "@/hooks/table/useDelegatedTasksTableColumns";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";

export const DelegatedTasksTable = ({ defaultColumns = {}, defaultFilters = [] }) => {
  const queryClient = useQueryClient();
  const [columnFilters, setColumnFilters] = useState(defaultFilters);
  const [sorting, setSorting] = useState([
    {
      id: "created_at",
      desc: true,
    },
  ]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const handleDelete = (taskId) => {
    deleteTaskMutation.mutate(taskId);
  };

  const deleteTaskMutation = useMutation({
    mutationFn: apiClient.deleteTask,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["tasks", "delegated"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery({
    queryKey: ["tasks", "delegated", pagination, sorting, columnFilters],
    queryFn: () => apiClient.getDelegatedTasks({ pagination, sorting, columnFilters }),
  });

  const { columns } = isEmpty(defaultColumns) ? useDelegatedTasksTableColumns(handleDelete) : defaultColumns;
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : response.data,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    rowCount: response?.meta?.total ?? response?.data?.length,
    onPaginationChange: setPagination,
    manualPagination: true,
    enableColumnResizing: true,
    onSortingChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: 10 });
      setSorting((old) => (updater instanceof Function ? updater(old) : updater));
    },
    onColumnFiltersChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: 10 });
      setColumnFilters((old) => (updater instanceof Function ? updater(old) : updater));
    },
    state: {
      pagination,
      sorting,
      columnFilters,
    },
  });

  return (
    <Table
      table={table}
      Filters={DelegatedTasksFilters}
      meta={response?.meta}
      isFetching={isFetching}
      isLoading={isLoading}
    />
  );
};
