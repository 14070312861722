import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Loader } from "@/components/Loader";
import { OrderItem } from "@/components/Order/OrderDetails/Items/OrderItem";
import { OrderItemCreateDialog } from "@/components/Order/OrderDetails/Items/OrderItemCreateDialog";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { ProductsContext } from "@/context/ProductsContext";
import { CalculatorFieldType } from "@/enums/CalculatorFieldTypeEnum";
import { OrderStatus } from "@/enums/OrderStatusEnum";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { routes } from "@/routes";
import { useQuery } from "@tanstack/react-query";
import { Check } from "lucide-react";
import { useContext } from "react";
import { Link } from "react-router-dom";

export const OrderItemsCard = ({ order }) => {
  const { products, isLoading: isLoadingProducts } = useContext(ProductsContext);
  const calculation = order.contract.calculation;
  const calculationProducts = calculation.calculator.sections
    .flatMap((section) => section.fields)
    .filter((field) => field.type === CalculatorFieldType.PRODUCT.value);

  const { isLoading: isLoadingItems, data: items } = useQuery({
    queryKey: ["order", order.id, "items"],
    queryFn: () => apiClient.getOrderItems(order.id),
  });

  const isProductCompleted = (product) => {
    if (items?.data) {
      const itemsWithWarehouse = items.data.filter((item) => !!item.warehouse);
      const itemsMatched = itemsWithWarehouse.filter((item) => item.product.id === product.value);
      const itemsTotalQuantity = itemsMatched.reduce((acc, curr) => {
        return acc + curr.quantity;
      }, 0);
      return itemsTotalQuantity === product.quantity;
    }
  };

  return (
    <div className="flex flex-col lg:flex-row gap-5 w-full">
      <Card className="shadow-md lg:w-1/3">
        <CardHeader>
          <CardTitle>{i18n.t("Produkty")}</CardTitle>
          <CardDescription>{i18n.t("Lista produktów potrzebnych do zrealizowania zamówienia")}</CardDescription>
        </CardHeader>
        <CardContent className="flex flex-col divide-y divide-border">
          {isLoadingProducts && <Loader />}
          {calculationProducts.map((item) => {
            const product = products.find((product) => product.id === item.value);
            return (
              product && (
                <div key={product.id} className="flex flex-row justify-between w-full py-3 first:pt-0">
                  <Link to={`${routes.products}/${product.id}`}>
                    <Button title={product.name} variant="link" className="m-0 p-0 h-fit" />
                  </Link>
                  <div
                    className={cn(
                      "flex flex-row gap-3",
                      isProductCompleted(item) && "text-muted-foreground line-through",
                    )}
                  >
                    <span>{`${item.quantity} ${item.unit ?? "szt."}`}</span>
                    {isProductCompleted(item) && (
                      <span>
                        <Check className="text-green-500" size={20} />
                      </span>
                    )}
                  </div>
                </div>
              )
            );
          })}
        </CardContent>
      </Card>
      <Card className="shadow-md flex-1">
        <CardHeader className="flex flex-row justify-between items-center">
          <div className="flex flex-col gap-1.5">
            <CardTitle>{i18n.t("Realizacja zamówienia")}</CardTitle>
            <CardDescription>{i18n.t("Lista magazynów z których zostaną pobrane produkty")}</CardDescription>
          </div>
          <OrderItemCreateDialog order={order} />
        </CardHeader>
        <CardContent className="flex flex-col gap-5">
          {isLoadingItems ? (
            <Loader />
          ) : (
            <>
              <div className="flex flex-row gap-3 items-center font-semibold text-sm text-muted-foreground">
                {order.status === OrderStatus.NEW.value && <span className="w-10"></span>}
                <div className="grid grid-cols-3 items-end gap-3 w-full">
                  <span>{i18n.t("Nazwa produktu")}</span>
                  <span>{i18n.t("Ilość")}</span>
                  <span>{i18n.t("Magazyn")}</span>
                </div>
                {order.status === OrderStatus.NEW.value && <span className="w-10"></span>}
              </div>
              {items?.data?.map((item) => (
                <OrderItem key={item.id} item={item} order={order} />
              ))}
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};
