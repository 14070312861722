import { Button } from "@/components/Button";
import { XCircle } from "lucide-react";

export const ClearFiltersButton = ({ table }) => {
  const tableState = table.getState();
  const hasTruthyValue = (arr) => {
    return arr.some((item) => {
      const value = item.value;
      return value !== null && value !== undefined && value !== "" && !(Array.isArray(value) && value.length === 0);
    });
  };

  const handleClear = () => {
    table.resetColumnFilters();
    table.setPageSize(10);
  };

  return (
    hasTruthyValue(tableState.columnFilters) > 0 && (
      <Button variant="outline" size="icon" onClick={handleClear}>
        <XCircle size={20} />
      </Button>
    )
  );
};
