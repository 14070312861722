import { DateRangePicker } from "@/components/forms/DateRangePicker";

export const DateRangeFilter = ({ value, setValue, ...rest }) => {
  return (
    <DateRangePicker
      onSelect={setValue}
      selected={value}
      className="flex-1 lg:flex-initial text-foreground font-medium"
      {...rest}
    />
  );
};
