import { Button } from "@/components/Button";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import i18n from "@/i18n";
import { ChevronLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";

export const NotFoundPage = () => {
  useDocumentTitle("404");
  const navigate = useNavigate();

  const handleReturn = () => {
    return navigate(-1);
  };

  return (
    <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-primary">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight sm:text-5xl">{i18n.t("Strona nie istnieje")}</h1>
        <p className="mt-6 text-base leading-7">
          {i18n.t("Zasób pod który chcesz się dostać nie istnieje albo nie masz do niego dostępu.")}
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Button onClick={handleReturn} leftIcon={<ChevronLeft size={16} />}>
            {i18n.t("Powrót")}
          </Button>
        </div>
      </div>
    </main>
  );
};
