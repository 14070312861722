import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { WarningAlertDialog } from "@/components/WarningAlertDialog";
import { useAuditPolicy } from "@/policies/audit/useAuditPolicy";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Plus } from "lucide-react";

export const AuditCreateDialog = ({ lead, buttonVariant = "outline" }) => {
  const queryClient = useQueryClient();
  const auditPolicy = useAuditPolicy();

  const handleCreate = () => {
    const data = {
      lead_id: lead.id,
    };
    createAuditMutation.mutate({ data });
  };

  const createAuditMutation = useMutation({
    mutationFn: apiClient.createAudit,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["audits"] });
      queryClient.invalidateQueries({ queryKey: ["lead", lead.id], exact: true });
      if (res.ok) {
        toast({ title: <SuccessToast title="Audyt został zlecony." /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  if (!auditPolicy.create()) return null;

  return (
    <WarningAlertDialog
      message="Czy na pewno chcesz zlecić audyt dla tego kontaktu"
      onConfirm={handleCreate}
      trigger={<Button variant={buttonVariant} title="Zleć audyt" leftIcon={<Plus size={20} />} />}
    />
  );
};
