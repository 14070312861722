import { Button } from "@/components/Button";
import { UserTooltip } from "@/components/Users/UserTooltip";
import { CardRow } from "@/components/ui/CardRow";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { OrderStatus } from "@/enums/OrderStatusEnum";
import { formatPhoneNumber } from "@/helpers/formatPhoneNumber";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { useContractPolicy } from "@/policies/contracts/useContractPolicy";
import { useOrderPolicy } from "@/policies/order/useOrderPolicy";
import { routes } from "@/routes";
import { Link } from "react-router-dom";

export const OrderInformationCard = ({ order }) => {
  const orderPolicy = useOrderPolicy();
  const contractPolicy = useContractPolicy();
  const orderContract = order.contract;
  const installationCrew = order.contract?.installation?.installationCrew;

  if (!orderPolicy.view()) return null;

  return (
    <div className="flex-1 flex gap-5 flex-col">
      <Card className="shadow-md">
        <CardHeader>
          <CardTitle>{i18n.t("Informacje")}</CardTitle>
          <CardDescription>{i18n.t("Informacje na temat zamówienia")}</CardDescription>
        </CardHeader>
        <CardContent className="divide-y divide-y-border">
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Umowa")}</p>
            {contractPolicy.view() ? (
              <Link to={`${routes.contracts}/${order.contract.id}`}>
                <Button variant="link" size="xs">
                  {order.contract.identifier}
                </Button>
              </Link>
            ) : (
              <p className="font-medium">{order.contract.identifier}</p>
            )}
          </CardRow>
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Status zamówienia")}</p>
            <Badge className={cn("h-8 rounded-md", OrderStatus[order.status].color)}>
              {OrderStatus[order.status].name}
            </Badge>
          </CardRow>
          {orderContract.calculation && (
            <>
              <CardRow>
                <p className="text-xs sm:text-base font-medium">{i18n.t("Kalkulacja")}</p>
                <Link to={`${routes.contracts}/${orderContract.id}/kalkulacja`} state={{ contract: orderContract }}>
                  <Button variant="link" size="xs">
                    {orderContract.calculation.name}
                  </Button>
                </Link>
              </CardRow>
            </>
          )}
          {orderPolicy.viewAll() && (
            <CardRow>
              <p className="text-xs sm:text-base font-medium">{i18n.t("Użytkownik odpowiedzialny")}</p>
              {order.user ? (
                <UserTooltip user={order.user} linkClassName="whitespace-break-spaces text-right" />
              ) : (
                i18n.t("Brak")
              )}
            </CardRow>
          )}
          {order.description && (
            <CardRow className="flex lg:flex-col lg:items-start gap-1">
              <p className="text-xs sm:text-base font-medium">{i18n.t("Opis")}</p>
              <p className="text-muted-foreground">{order.description}</p>
            </CardRow>
          )}
        </CardContent>
      </Card>
      {installationCrew && (
        <Card className="shadow-md">
          <CardHeader>
            <CardTitle>{i18n.t("Ekipa montażowa")}</CardTitle>
            <CardDescription>{i18n.t("Informacje na temat ekipy montażowej")}</CardDescription>
          </CardHeader>
          <CardContent className="divide-y divide-y-border">
            <CardRow>
              <p className="text-xs sm:text-base font-medium">{i18n.t("Nazwa ekipy")}</p>
              <p>{installationCrew.name}</p>
            </CardRow>
            <CardRow>
              <p className="text-xs sm:text-base font-medium">{i18n.t("Numer telefonu")}</p>
              {installationCrew.phone_number ? (
                <a className="font-semibold" href={`tel:${installationCrew.phone_number}`}>
                  {formatPhoneNumber(installationCrew.phone_number)}
                </a>
              ) : (
                <p>{i18n.t("Brak")}</p>
              )}
            </CardRow>
            <CardRow>
              <p className="text-xs sm:text-base font-medium">{i18n.t("Adres e-mail")}</p>
              {installationCrew.email ? (
                <a className="font-semibold" href={`mailto:${installationCrew.email}`}>
                  {installationCrew.email}
                </a>
              ) : (
                <p>{i18n.t("Brak")}</p>
              )}
            </CardRow>
            {installationCrew.description && (
              <CardRow className="flex lg:flex-col lg:items-start gap-1">
                <p className="text-xs sm:text-base font-medium">{i18n.t("Opis")}</p>
                <p className="text-muted-foreground">{installationCrew.description}</p>
              </CardRow>
            )}
          </CardContent>
        </Card>
      )}
    </div>
  );
};
