import apiClient from "@/api/apiClient";
import { ProcessesList } from "@/components/Process/ProcessesList";
import { groupBy } from "@/helpers/arrayHelpers";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export const ProcessSettings = ({ model }) => {
  const [processGroups, setProcessGroups] = useState(null);
  const { isLoading, data: response } = useQuery({ queryKey: ["processes"], queryFn: apiClient.getProcesses });

  useEffect(() => {
    if (!isLoading && response) {
      setProcessGroups(groupBy(response.data, ({ applies_to }) => applies_to));
    }
  }, [isLoading, response]);
  return processGroups && <ProcessesList processes={processGroups[model]} />;
};
