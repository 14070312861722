import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormSelect } from "@/components/forms/FormSelect";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { AuditStatusContext } from "@/context/AuditStatusContext";
import { UsersContext } from "@/context/UsersContext";
import i18n from "@/i18n";
import { useAuditPolicy } from "@/policies/audit/useAuditPolicy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Check, X } from "lucide-react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

export const AuditEditDialog = ({ trigger, audit }) => {
  const queryClient = useQueryClient();
  const auditPolicy = useAuditPolicy();
  const { statusOptions } = useContext(AuditStatusContext);
  const { userOptions, isLoading: isLoadingUsers } = useContext(UsersContext);

  const [isOpen, setIsOpen] = useState(false);

  const validationSchema = Yup.object({
    user_id: Yup.string()
      .nullable()
      .when([], {
        is: () => auditPolicy.viewAll(),
        then: () => Yup.string().required(i18n.t("Pole jest wymagane")),
      }),
    audit_status_id: Yup.string().required(i18n.t("Pole jest wymagane")),
    description: Yup.string().nullable(),
  });

  const onSubmit = (data) => {
    updateAuditMutation.mutate({ auditId: audit.id, data });
  };

  const defaultValues = {
    user_id: audit?.user?.id ?? undefined,
    audit_status_id: audit?.status?.id ?? undefined,
    description: audit.description,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const updateAuditMutation = useMutation({
    mutationFn: apiClient.updateAudit,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["audits"] });
      queryClient.invalidateQueries({ queryKey: ["audit", audit.id] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie zapisano." /> });
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  if (!auditPolicy.update()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Edycja audytu")}</DialogTitle>
          <DialogDescription>{audit.name}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            {auditPolicy.viewAll() && (
              <FormCombobox
                label="Użytkownik odpowiedzialny"
                name="user_id"
                options={userOptions}
                isLoading={isLoadingUsers}
              />
            )}
            <FormSelect label="Status" name="audit_status_id" options={statusOptions} />
            <FormTextarea name="description" label="Opis" />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Zapisz"
              leftIcon={<Check size={20} />}
              isLoading={updateAuditMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
