import { Card, CardHeader } from "@/components/ui/card";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";

export const EmptyState = ({ title, className }) => {
  if (!title) return null;

  return (
    <Card className={cn("flex w-full", className)}>
      <CardHeader className="text-center py-3 w-full">{typeof title === "string" ? i18n.t(title) : title}</CardHeader>
    </Card>
  );
};
