import { ApplicationEditDialog } from "@/components/Applications/ApplicationEditDialog";
import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { UserAvatarLink } from "@/components/Users/UserAvatarLink";
import i18n from "@/i18n";
import { useApplicationPolicy } from "@/policies/application/useApplicationPolicy";
import { useLeadPolicy } from "@/policies/lead/useLeadPolicy";
import { useUserPolicy } from "@/policies/user/useUserPolicy";
import { routes } from "@/routes";
import { ArrowDown, ArrowUp, ArrowUpDown, MoreHorizontal, Pencil, Trash } from "lucide-react";
import { Link } from "react-router-dom";

export const useApplicationsTableColumns = (handleDelete) => {
  const applicationPolicy = useApplicationPolicy();
  const userPolicy = useUserPolicy();
  const leadPolicy = useLeadPolicy();

  const columns = [
    {
      id: "lead",
      name: "Klient",
      accessorKey: "lead",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Klient")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const application = row.original;
        if (leadPolicy.view()) {
          return (
            <Link
              to={`${routes.applications}/${row.original.id}`}
              className="flex flex-row items-center gap-3 font-medium hover:underline"
            >
              {application.lead.name}
            </Link>
          );
        } else {
          return <span>{application.lead.name}</span>;
        }
      },
    },
    {
      id: "type",
      name: "Typ wniosku",
      accessorKey: "type",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Typ wniosku")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const application = row.original;
        return (
          <span
            className="rounded-md px-2 py-1 font-semibold whitespace-nowrap text-primary-foreground dark:text-foreground dark:bg-opacity-60"
            style={{ backgroundColor: application.type.color }}
          >
            {application.type.name}
          </span>
        );
      },
    },
    {
      id: "status",
      name: "Status",
      accessorKey: "status",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Status")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const application = row.original;
        return (
          <span
            className="rounded-md px-2 py-1 font-semibold whitespace-nowrap text-primary-foreground dark:text-foreground dark:bg-opacity-60"
            style={{ backgroundColor: application.status.color }}
          >
            {application.status.name}
          </span>
        );
      },
    },
    userPolicy.viewAny()
      ? {
          id: "user_id",
          name: "Osoba odpowiedzialna",
          accessorKey: "user_id",
          header: i18n.t("Osoba odpowiedzialna"),
          cell: ({ row }) => {
            const user = row.original.user;
            return <UserAvatarLink user={user} />;
          },
        }
      : { id: "user_id" },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const application = row.original;
        if (!applicationPolicy.destroy(application) && !applicationPolicy.update()) return null;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <ApplicationEditDialog
                application={application}
                trigger={
                  <DropdownMenuItem className="flex flex-row items-center" onSelect={(e) => e.preventDefault()}>
                    <Pencil size={16} className="mr-1 text-primary" />
                    <span className="font-semibold">{i18n.t("Edytuj")}</span>
                  </DropdownMenuItem>
                }
              />
              {applicationPolicy.destroy(application) && (
                <>
                  <DropdownMenuSeparator />
                  <DeleteAlertDialog
                    onConfirm={() => handleDelete(application.id)}
                    message="Czy na pewno chcesz usunąć ten wniosek? Tej operacji nie można cofnąć"
                    trigger={
                      <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                        <Trash size={16} className="mr-1 text-primary" />
                        <span className="font-semibold">{i18n.t("Usuń")}</span>
                      </DropdownMenuItem>
                    }
                  />
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
