import { AuditsCalendar } from "@/components/Audits/AuditsCalendar";
import { AuditsTable } from "@/components/Audits/AuditsTable";
import { AuditSettings } from "@/components/Audits/Settings/AuditSettings";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { Layout } from "@/components/layout/Layout";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import i18n from "@/i18n";
import { useAuditStatusPolicy } from "@/policies/audit/useAuditStatusPolicy";
import { Map } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

export const AuditsPage = () => {
  useDocumentTitle("Audyty");
  const { state } = useLocation();
  const auditStatusPolicy = useAuditStatusPolicy();
  const bottomRef = useRef(null);

  const [activeTab, setActiveTab] = useState("table");
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    if (state?.tab) {
      setActiveTab(state.tab);
    }
  }, [state]);

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {activeTab === "table" && (
            <Button
              title="Pokaż mapę"
              variant="outline"
              leftIcon={<Map size={16} />}
              onClick={() => {
                setShowMap(!showMap);
                if (!showMap)
                  setTimeout(() => {
                    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
                  }, 200);
              }}
            />
          )}
        </div>
      </div>
      <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value)}>
        {auditStatusPolicy.create() && (
          <TabsList className="mb-6">
            <TabsTrigger value="table">{i18n.t("Tabela")}</TabsTrigger>
            <TabsTrigger value="calendar">{i18n.t("Kalendarz")}</TabsTrigger>
            {auditStatusPolicy.create() && <TabsTrigger value="settings">{i18n.t("Ustawienia")}</TabsTrigger>}
          </TabsList>
        )}
        <TabsContent value="table">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <AuditsTable showMap={showMap} />
          </div>
        </TabsContent>
        <TabsContent value="calendar">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <AuditsCalendar />
          </div>
        </TabsContent>
        {auditStatusPolicy.create() && (
          <TabsContent value="settings">
            <AuditSettings />
          </TabsContent>
        )}
      </Tabs>
      <div ref={bottomRef}></div>
    </Layout>
  );
};
