import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormField } from "@/components/forms/FormField";
import { FormSelect } from "@/components/forms/FormSelect";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { LeadSourceInput } from "@/components/Leads/Sources/LeadSourceInput";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { LeadStatusContext } from "@/context/LeadStatusContext";
import { UsersContext } from "@/context/UsersContext";
import { getVoivodeshipOptions } from "@/helpers/address/getVoivodeshipOptions";
import i18n from "@/i18n";
import { useLeadPolicy } from "@/policies/lead/useLeadPolicy";
import { useLeadSourcePolicy } from "@/policies/lead/useLeadSourcePolicy";
import { routes } from "@/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Check, X } from "lucide-react";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

export const LeadEditForm = ({ lead }) => {
  const leadPolicy = useLeadPolicy();
  const leadSourcePolicy = useLeadSourcePolicy();
  const { id: leadId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { voivodeshipOptions } = getVoivodeshipOptions();
  const { statusOptions } = useContext(LeadStatusContext);
  const { userOptions, isLoading: isLoadingUsers } = useContext(UsersContext);

  const validationSchema = Yup.object({
    first_name: Yup.string().required(i18n.t("Pole jest wymagane")),
    last_name: Yup.string().nullable(),
    phone_number: Yup.string()
      .required(i18n.t("Pole jest wymagane"))
      .min(9, "Numer telefonu musi mieć co najmniej 9 cyfr")
      .max(15, "Numer telefonu może mieć maksymalnie 15 cyfr"),
    lead_source_id: Yup.string()
      .nullable()
      .when([], {
        is: () => leadSourcePolicy.viewAny(),
        then: () => Yup.string().required(i18n.t("Pole jest wymagane")),
      }),
    email: Yup.string().email(i18n.t("To pole musi być poprawnym adresem email.")),
    street_number: Yup.string().nullable(),
    postal_code: Yup.string().nullable(),
    city: Yup.string().nullable(),
    voivodeship: Yup.string().nullable(),
    lead_status_id: Yup.string().required(i18n.t("Pole jest wymagane")),
    description: Yup.string().nullable(),
    user_id: Yup.string().nullable(),
    department_id: Yup.string().nullable(),
  });

  const defaultValues = {
    first_name: lead.first_name,
    last_name: lead.last_name,
    phone_number: lead.phone_number,
    email: lead.email,
    street_number: lead.address?.street_number,
    postal_code: lead.address?.postal_code,
    city: lead.address?.city,
    voivodeship: lead.address?.voivodeship,
    description: lead.description || undefined,
    lead_status_id: lead.status.id,
  };

  if (leadSourcePolicy.viewAny()) {
    defaultValues.lead_source_id = lead?.source?.id;
  }
  if (leadPolicy.assignUserLeads() && lead.user) {
    defaultValues.user_id = lead.user?.id;
  }

  const onSubmit = (data) => {
    updateLeadMutation.mutate({ id: leadId, data });
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const updateLeadMutation = useMutation({
    mutationFn: apiClient.updateLead,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["leads"] });
      queryClient.invalidateQueries({ queryKey: ["lead", leadId], exact: true });
      queryClient.invalidateQueries({ queryKey: ["sidebar"] });
      if (res.ok) {
        return navigate(`${routes.leads}/${leadId}`);
      } else {
        if (res.data.message.search("The phone number has already been taken.") >= 0) {
          toast({ title: <ErrorToast title="Taki numer telefonu istnieje już w systemie" /> });
        } else if (res.data.message.search("The email has already been taken.") >= 0) {
          toast({ title: <ErrorToast title="Taki adres email istnieje już w systemie" /> });
        } else {
          toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
        }
      }
    },
  });

  return (
    <Card className="mt-5 w-full xl:w-1/2">
      <Form form={form} onSubmit={onSubmit}>
        <CardContent className="pt-5">
          <div className="flex flex-col gap-4">
            <FormField name="first_name" label="Imię" autoComplete="first_name" />
            <FormField name="last_name" label="Nazwisko" autoComplete="last_name" />
            <FormField name="email" type="email" autoComplete="email" label="Email" />
            <FormField name="phone_number" autoComplete="phone_number" label="Numer telefonu" inputMode="numeric" />
            <LeadSourceInput />
            <FormSelect label="Status" options={statusOptions} name="lead_status_id" />
            <FormField name="street_number" label="Ulica i numer" />
            <div className="flex flex-row gap-3 items-center">
              <FormField name="postal_code" label="Kod pocztowy" />
              <FormField name="city" label="Miasto" />
            </div>
            <FormCombobox
              options={voivodeshipOptions}
              name="voivodeship"
              label="Województwo"
              required={false}
              isLoading={isLoadingUsers}
            />
            <FormTextarea name="description" label="Opis" />
            {leadPolicy.assignUserLeads() && (
              <FormCombobox
                required={false}
                name="user_id"
                label="Użytkownik odpowiedzialny"
                options={userOptions}
                isLoading={isLoadingUsers}
              />
            )}
          </div>
        </CardContent>
        <CardFooter className="gap-3 items-center border-t px-6 py-4">
          <Button
            type="submit"
            title="Zapisz"
            leftIcon={<Check size={20} />}
            isLoading={updateLeadMutation.isPending}
          />
          <Button
            title="Anuluj"
            type="button"
            leftIcon={<X size={20} />}
            variant="destructive"
            onClick={() => navigate(`${routes.leads}/${leadId}`)}
          />
        </CardFooter>
      </Form>
    </Card>
  );
};
