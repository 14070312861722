import { WarehouseDetailsPage } from "@/pages/Warehouse/WarehouseDetailsPage";
import { WarehousesPage } from "@/pages/Warehouse/WarehousesPage";
import { routes } from "@/routes";
import { Route } from "react-router-dom";

export const WarehouseRoutes = () => (
  <>
    <Route exact path={routes.warehouses} element={<WarehousesPage />} />
    <Route exact path={routes.warehouse} element={<WarehouseDetailsPage />} />
  </>
);
