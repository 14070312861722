import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormDatePicker } from "@/components/forms/FormDatePicker";
import { FormField } from "@/components/forms/FormField";
import { FormSelect } from "@/components/forms/FormSelect";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { InstallationStatusContext } from "@/context/InstallationStatusContext";
import { UsersContext } from "@/context/UsersContext";
import i18n from "@/i18n";
import { useInstallationPolicy } from "@/policies/installation/useInstallationPolicy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { parse } from "date-fns";
import { Check, X } from "lucide-react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

export const InstallationEditDialog = ({ trigger, installation }) => {
  const queryClient = useQueryClient();
  const installationPolicy = useInstallationPolicy();
  const { statusOptions } = useContext(InstallationStatusContext);
  const { userOptions, isLoading: isLoadingUsers } = useContext(UsersContext);

  const [isOpen, setIsOpen] = useState(false);

  const validationSchema = Yup.object({
    user_id: Yup.string().optional().nullable(),
    name: Yup.string().required(i18n.t("Pole jest wymagane")),
    installation_status_id: Yup.string().required(i18n.t("Pole jest wymagane")),
    description: Yup.string().nullable(),
    date: Yup.date().optional().nullable(),
  });

  const onSubmit = (data) => {
    updateInstallationMutation.mutate({ installationId: installation.id, data });
  };

  const defaultValues = {
    user_id: installation?.user?.id ?? undefined,
    installation_status_id: installation?.status?.id ?? undefined,
    date: installation.date ? parse(installation.date, "yyyy-MM-dd", new Date()) : undefined,
    description: installation.description,
    name: installation.name,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const updateInstallationMutation = useMutation({
    mutationFn: apiClient.updateInstallation,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["installations"] });
      queryClient.invalidateQueries({ queryKey: ["installation", installation.id] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie zapisano." /> });
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  if (!installationPolicy.update()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Edycja montażu")}</DialogTitle>
          <DialogDescription>{installation.name}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormField name="name" label="Nazwa" />
            {installationPolicy.viewAll() && (
              <FormCombobox
                label="Użytkownik odpowiedzialny"
                name="user_id"
                options={userOptions}
                isLoading={isLoadingUsers}
              />
            )}
            <FormSelect label="Status" name="installation_status_id" options={statusOptions} />
            <FormDatePicker name="date" label="Termin montażu" />
            <FormTextarea name="description" label="Opis" />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Zapisz"
              leftIcon={<Check size={20} />}
              isLoading={updateInstallationMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
