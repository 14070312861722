import apiClient from "@/api/apiClient";
import { Loader } from "@/components/Loader";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import i18n from "@/i18n";
import { useContractPolicy } from "@/policies/contracts/useContractPolicy";
import { routes } from "@/routes";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";

export const ApplicationContractsCard = ({ application }) => {
  const contractPolicy = useContractPolicy();
  const leadId = application.lead.id;

  const { isLoading, data: response } = useQuery({
    queryKey: ["lead", leadId, "contracts"],
    queryFn: () => apiClient.getLeadContracts({ leadId, filters: {} }),
  });

  return (
    <Card className="flex-1 h-fit shadow-md pr-2">
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5">
          <CardTitle>{i18n.t("Umowy klienta")}</CardTitle>
          <CardDescription>{i18n.t("Lita wszystkich umów tego klienta")}</CardDescription>
        </div>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-3 justify-center items-center">
          {isLoading ? (
            <Loader />
          ) : (
            response?.data?.map((contract) => {
              return (
                <Card key={contract.id} className="p-4 shadow-md border-l-4 border-l-primary w-full">
                  <div className="flex flex-row justify-between items-start w-full gap-3">
                    <div className="flex flex-col gap-2 items-center sm:items-start w-full whitespace-nowrap">
                      {contractPolicy.view() ? (
                        <Link to={`${routes.contracts}/${contract.id}`}>
                          <p className="m-0 p-0 h-fit text-lg font-semibold">{contract.identifier}</p>
                        </Link>
                      ) : (
                        <span className="font-semibold text-lg">{contract.identifier}</span>
                      )}
                      <div className="flex flex-wrap gap-3 flex-row items-center">
                        {contract.products.map((product) => (
                          <Badge key={product.id} className="h-8 rounded-md" style={{ backgroundColor: product.color }}>
                            {product.name}
                          </Badge>
                        ))}
                      </div>
                    </div>
                  </div>
                </Card>
              );
            })
          )}
        </div>
      </CardContent>
    </Card>
  );
};
