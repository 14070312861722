import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { EmptyState } from "@/components/EmptyState";
import { Combobox } from "@/components/forms/Combobox";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { InfoToast } from "@/components/toast/InfoToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import i18n from "@/i18n";
import { useInstallationCrewPolicy } from "@/policies/installation/useInstallationCrewPolicy";
import { useInstallationPolicy } from "@/policies/installation/useInstallationPolicy";
import { routes } from "@/routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Minus, UserPlus } from "lucide-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const InstallationCrewCard = ({ installation, contract }) => {
  const installationId = installation.id;
  const installationPolicy = useInstallationPolicy();
  const installationCrewPolicy = useInstallationCrewPolicy();
  const queryClient = useQueryClient();
  const installationContract = contract ? contract : installation.contract;
  const [crewId, setCrewId] = useState();

  const [crewOptions, setCrewOptions] = useState([]);
  const filters = {
    voivodeships: [installationContract?.address?.voivodeship],
  };

  const deleteInstallationNoteMutation = useMutation({
    mutationFn: apiClient.deleteInstallationInstallationCrew,
    onSuccess: (res) => {
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie usunięto ekipę z tego montażu." /> });
        queryClient.invalidateQueries({ queryKey: ["installation", installationId] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const createInstallationInstallationCrewMutation = useMutation({
    mutationFn: apiClient.createInstallationInstallationCrew,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["installation", installationId] });
        toast({ title: <SuccessToast title="Ekipa została przypisana." /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const {
    isLoading,
    data: response,
    error,
  } = useQuery({
    queryKey: ["installationCrews", filters],
    queryFn: () => apiClient.getInstallationCrews(filters),
  });

  const handleCreate = (installationCrewId) => {
    if (!installation.date) {
      setCrewId(null);
      toast({ title: <InfoToast title="Zanim przypiszesz ekipę, wyznacz termin montażu" /> });
    } else {
      createInstallationInstallationCrewMutation.mutate({ installationId: installation.id, installationCrewId });
    }
  };

  const handleDelete = () => {
    const installationCrewId = installation.installationCrew?.id;
    if (installationCrewId) deleteInstallationNoteMutation.mutate({ installationId, installationCrewId });
  };

  useEffect(() => {
    if (!isLoading && !error && response) {
      setCrewOptions(response.data.map((crew) => ({ name: crew.name, value: crew.id })));
    }
  }, [isLoading, error, response]);

  useEffect(() => {
    if (crewId) {
      handleCreate(crewId);
    }
  }, [crewId]);

  return (
    <Card className="flex-1 h-fit shadow-md pr-2">
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5">
          <CardTitle>{i18n.t("Ekipa montażowa")}</CardTitle>
          <CardDescription>{i18n.t("Ekipa obsługująca ten montaż")}</CardDescription>
        </div>
        {installationPolicy.update() && installationCrewPolicy.viewAny() && !installation?.installationCrew && (
          <Combobox
            isLoading={isLoading}
            options={crewOptions}
            setValue={setCrewId}
            value={crewId}
            placeholder="Przypisz ekipę"
            className="mt-0 w-1/2"
            variant="outline"
            leftIcon={<UserPlus size={20} />}
          />
        )}
        {installationPolicy.update() && installation.installationCrew && (
          <DeleteAlertDialog
            message="Czy na pewno chcesz usunąć tą ekipę z tego montażu?"
            onConfirm={handleDelete}
            trigger={<Button variant="outline" title="Usuń ekipę" leftIcon={<Minus size={20} />} />}
          />
        )}
      </CardHeader>
      <CardContent>
        {installation?.installationCrew && (
          <Card className="p-4 shadow-md border-l-4 border-l-primary">
            <div className="flex flex-row justify-between items-start w-full gap-3">
              <div className="flex flex-col gap-2 items-center sm:items-start w-full whitespace-nowrap">
                {installationCrewPolicy.view() ? (
                  <Link to={`${routes.installationCrews}/${installation.installationCrew.id}`}>
                    <Button
                      variant="link"
                      title={installation.installationCrew.name}
                      className="m-0 p-0 h-fit text-lg font-semibold"
                    />
                  </Link>
                ) : (
                  <span className="font-semibold text-lg">{installation.installationCrew.name}</span>
                )}
                <div className="text-muted-foreground">{installation.installationCrew.phone_number}</div>
                <div className="text-muted-foreground">{installation.installationCrew.email}</div>
              </div>
            </div>
          </Card>
        )}
        {!installation.installationCrew && <EmptyState title="Montaż nie ma jeszcze przypisanej ekipy" />}
      </CardContent>
    </Card>
  );
};
