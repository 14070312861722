import { ClearFiltersButton } from "@/components/Filters/ClearFiltersButton";
import { ColumnFilter } from "@/components/Filters/ColumnFilter";
import { SearchFilter } from "@/components/Filters/SearchFilter";
import { useFilters } from "@/hooks/table/useFilters";

export const BillingSettingFilters = ({ table }) => {
  const { filter, setFilter } = useFilters(table);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row flex-wrap gap-3 mb-3">
        <div className="flex flex-row gap-3 w-full lg:max-w-sm">
          <ClearFiltersButton table={table} />
          <SearchFilter value={filter("search")} onChange={(value) => setFilter("search", value)} />
        </div>
        <ColumnFilter table={table} />
      </div>
    </div>
  );
};
