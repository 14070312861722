import { DefaultNotification } from "@/components/Notifications/NotificationContent/DefaultNotification";
import { LeadImportFinishedNotification } from "@/components/Notifications/NotificationContent/LeadImportFinishedNotification";
import { NotificationType } from "@/enums/NotificationTypeEnum";

export const NotificationContent = ({ notification }) => {
  switch (notification.type) {
    case NotificationType.LEAD_IMPORT_FINISHED:
      return <LeadImportFinishedNotification notification={notification} />;
    default:
      return <DefaultNotification notification={notification} />;
  }
};
