import { Toaster } from "@/components/ui/toaster";
import { AppProviders } from "@/context/AppProviders";
import { AppRoutes } from "@/routes/AppRoutes";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

function App() {
  return (
    <AppProviders>
      <AppRoutes />
      <Toaster />
      <ReactQueryDevtools initialIsOpen={false} />
    </AppProviders>
  );
}

export default App;
