export const useFilters = (table) => {
  const columnFilters = table.getState().columnFilters;
  const setColumnFilters = table.setColumnFilters;

  const filter = (id) => (columnFilters && columnFilters.find((f) => f.id === id)?.value) || "";
  const setFilter = (id, value) =>
    setColumnFilters((prev) =>
      prev
        .filter((f) => f.id !== id)
        .concat({
          id,
          value,
        }),
    );

  return { filter, setFilter };
};
