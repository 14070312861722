import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";

export const SearchFilter = ({ onChange, value: initialValue, debounce = 500, className, ...rest }) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (initialValue !== value) {
      const timeout = setTimeout(() => {
        onChange(value);
      }, debounce);

      return () => clearTimeout(timeout);
    }
  }, [value]);

  return (
    <Input
      {...rest}
      className={cn("flex-1 lg:flex-initial lg:max-w-sm", value ? "border-primary" : "", className)}
      placeholder="Szukaj"
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};
