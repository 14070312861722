import { CalculatorProvider } from "@/context/CalculatorContext";
import { CalculatorConfigurationPage } from "@/pages/Calculators/CalculatorConfigurationPage";
import { CalculatorPage } from "@/pages/Calculators/CalculatorPage";
import { CalculatorsSettingsPage } from "@/pages/Calculators/CalculatorsSettingsPage";
import { routes } from "@/routes";
import { Route } from "react-router-dom";

export const CalculatorRoutes = () => (
  <>
    <Route exact path={routes.calculatorsSettings} element={<CalculatorsSettingsPage />} />
    <Route exact path={routes.calculator} element={<CalculatorPage />} />
    <Route
      exact
      path={routes.calculatorConfiguration}
      element={
        <CalculatorProvider>
          <CalculatorConfigurationPage />
        </CalculatorProvider>
      }
    />
  </>
);
