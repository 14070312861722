import { Checkbox as ShadCheckbox } from "@/components/ui/checkbox";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";

export const Checkbox = ({ name, label, description, isError, ...rest }) => {
  return (
    <div className="flex items-center space-x-2">
      <ShadCheckbox id={name} {...rest} />
      {(label || description) && (
        <div className="flex flex-col space-y-1 leading-none">
          {label && (
            <label
              htmlFor={name}
              className={cn(
                "text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
                isError && "text-destructive",
              )}
            >
              {i18n.t(label)}
            </label>
          )}
          {description && (
            <span className="text-sm text-muted-foreground mt-1">
              {typeof description === "string" ? i18n.t(description) : description}
            </span>
          )}
        </div>
      )}
    </div>
  );
};
