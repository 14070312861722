import apiClient from "@/api/apiClient";
import { Table } from "@/components/Table/Table";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { ContractProductContext } from "@/context/ContractProductContext";
import { useContractProductsTableColumns } from "@/hooks/table/useContractProductsTableColumns";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useContext, useMemo, useState } from "react";

export const ContractProducts = () => {
  const { products, isLoading } = useContext(ContractProductContext);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const queryClient = useQueryClient();

  const handleEdit = (contractProductId, data) => {
    updateContractProductMutation.mutate({ contractProductId, data });
  };

  const updateContractProductMutation = useMutation({
    mutationFn: apiClient.updateContractProduct,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["contractProducts"] });
        queryClient.invalidateQueries({ queryKey: ["contractsStats"] });
        queryClient.invalidateQueries({ queryKey: ["contracts"] });
        toast({ title: <SuccessToast title="Pomyślnie zapisano" /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const deleteContractProductMutation = useMutation({
    mutationFn: apiClient.deleteContractProduct,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["contractProducts"] });
        queryClient.invalidateQueries({ queryKey: ["contractsStats"] });
        queryClient.invalidateQueries({ queryKey: ["contracts"] });
        toast({ title: <SuccessToast title="Pomyślnie usunięto" /> });
      } else {
        if (res.status === 400) {
          toast({ title: <ErrorToast title="Nie można usunąć tego produktu ponieważ są umowy które go posiadają" /> });
        } else {
          toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
        }
      }
    },
  });

  const handleDelete = (contractProductId) => {
    deleteContractProductMutation.mutate({ contractProductId });
  };

  const { columns } = useContractProductsTableColumns(handleEdit, handleDelete);
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : products,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
    rowCount: products.length,
  });

  return <Table table={table} isLoading={isLoading} />;
};
