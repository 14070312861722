import { useAuth } from "@/hooks/useAuth";

export const useCalculationPolicy = () => {
  const { hasPermission, user } = useAuth();

  const view = (calculation) => {
    return user?.id === calculation?.user?.id || hasPermission("show_any_lead_calculation");
  };

  const viewMargin = () => {
    return hasPermission("access_calculation_margin");
  };

  return {
    view,
    viewMargin,
  };
};
