import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";

export const ColorPicker = ({ value, name, onChange, id, onBlur, className }) => {
  return (
    <div className="w-24 h-11">
      <Label
        style={{ backgroundColor: value }}
        className={cn("border block w-24 h-10 rounded-md cursor-pointer", className)}
        htmlFor={id ?? "color"}
      />
      <input
        type="color"
        name={name}
        onBlur={onBlur}
        value={value ?? "#FFFFFF"}
        onChange={onChange}
        id={id ?? "color"}
        className="opacity-0 w-0 h-0 p-0 m-0"
      />
    </div>
  );
};
