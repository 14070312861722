import { Button } from "@/components/Button";
import { CalculationSummaryDialog } from "@/components/Calculators/CalculationSummaryDialog";
import CalculatorBuilder from "@/components/Calculators/CalculatorBuilder";
import { CalculatorSection } from "@/components/Calculators/Layout/Sections/CalculatorSection";
import { Form } from "@/components/forms/Form";
import { ProductsContext } from "@/context/ProductsContext";
import { CalculatorFieldType } from "@/enums/CalculatorFieldTypeEnum";
import { useCalculatorCustomFields } from "@/hooks/useCalculatorCustomFields";
import i18n from "@/i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import { Check } from "lucide-react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const createValidationSchema = (fields) => {
  const schemaShape = {};

  fields.forEach((field) => {
    let fieldSchema = Yup.string();
    let quantitySchema = Yup.number(i18n.t("Wartość musi być liczbą"))
      .min(1, i18n.t("Ilość musi być większa lub równa 1"))
      .nullable()
      .transform((value, originalValue) => (originalValue === "" ? null : value));

    if (field.required) {
      fieldSchema = fieldSchema.required(i18n.t("Pole jest wymagane"));
      if (field.has_quantity) {
        quantitySchema = quantitySchema.when([`${field.identifier}`], {
          is: (val) => val !== undefined && val !== "" && val !== null && val !== "null" && val !== 0 && val !== "0",
          then: (schema) => schema.required(i18n.t("Ilość jest wymagana")),
        });
      }
    }

    if (field.type === CalculatorFieldType.NUMBER.value) {
      fieldSchema = Yup.number(i18n.t("Wartość musi być liczbą"))
        .min(0, i18n.t("Wartość musi być większa lub równa 0"))
        .transform((value, originalValue) => (originalValue === "" ? null : value));

      if (field.required) {
        fieldSchema = fieldSchema.required(i18n.t("Pole jest wymagane"));
      }
    } else if (field.type === CalculatorFieldType.LIST.value) {
      if (field.required) {
        fieldSchema = Yup.string()
          .oneOf(
            field.field_options.map((option) => option.name),
            i18n.t("Wybierz poprawną wartość"),
          )
          .required(i18n.t("Pole jest wymagane"));
      } else {
        const oneOf = field.required
          ? field.field_options.map((option) => option.name)
          : [...field.field_options.map((option) => option.name), "Brak"];
        fieldSchema = Yup.string().oneOf(oneOf, i18n.t("Wybierz poprawną wartość"));
      }
    } else if (field.type === CalculatorFieldType.VAT.value) {
      fieldSchema = Yup.string()
        .oneOf(
          field.field_options.map((option) => option.value),
          i18n.t("Wybierz poprawną wartość"),
        )
        .required(i18n.t("Pole jest wymagane"));
    }

    schemaShape[field.identifier] = fieldSchema;

    if (field.has_quantity) {
      schemaShape[`${field.identifier}_quantity`] = quantitySchema;
    }
  });

  return Yup.object().shape(schemaShape);
};

export const Calculator = ({ calculator }) => {
  const { products } = useContext(ProductsContext);
  const fields = calculator.sections.flatMap((section) => section.fields);
  const validationSchema = createValidationSchema(fields);
  const customFields = useCalculatorCustomFields();

  const [showPrice, setShowPrice] = useState(false);
  const [calculation, setCalculation] = useState(null);

  const form = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const onSubmit = (values) => {
    const calculatorBuilder = new CalculatorBuilder(calculator, products, customFields);
    const builtCalculator = calculatorBuilder
      .setValues(values)
      .setCalculatedPrices()
      .setMargins()
      .calculateAdditionalCosts()
      .setTotals()
      .setCustomFields()
      .build();
    setCalculation(() => builtCalculator);
    setShowPrice(true);
    // builtCalculator.sections
    //   .flatMap((section) => section.fields)
    //   .map(({ name, calculatedPrice, value, marginValue }) => {
    //     console.log(name, { calculatedPrice, value, marginValue });
    //   });
    // builtCalculator.additionalCosts.map(({ name, calculatedPrice, marginValue }) => {
    //   console.log(name, { calculatedPrice, marginValue });
    // });
    // console.log(builtCalculator.customFields);
  };

  return (
    <Form form={form} onSubmit={onSubmit}>
      <div className="columns-1 lg:columns-2 gap-x-5">
        {calculator.sections.map((section) => (
          <CalculatorSection key={section.id} section={section} />
        ))}
        {fields.length > 0 && (
          <div className="flex flex-row justify-center">
            <Button title="Oblicz" leftIcon={<Check />} type="submit" />
          </div>
        )}
      </div>
      <CalculationSummaryDialog calculation={calculation} setShowPrice={setShowPrice} showPrice={showPrice} />
    </Form>
  );
};
