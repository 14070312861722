import apiClient from "@/api/apiClient";
import { FileFilters } from "@/components/File/Filters/FileFilters";
import { Table } from "@/components/Table/Table";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { toast } from "@/components/ui/use-toast";
import { ButtonContext } from "@/context/ButtonContext";
import { useApplicationFilesTableColumns } from "@/hooks/table/useApplicationFilesTableColumns";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const ApplicationFilesTable = () => {
  const { id: applicationId } = useParams();
  const queryClient = useQueryClient();
  const { showButton, hideButton } = useContext(ButtonContext);

  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([
    {
      id: "original_name",
      desc: false,
    },
  ]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery({
    queryKey: ["application", applicationId, "files", pagination, sorting, columnFilters],
    queryFn: () => apiClient.getApplicationFiles({ applicationId, filters: { pagination, sorting, columnFilters } }),
  });

  const deleteApplicationFileMutation = useMutation({
    mutationFn: apiClient.deleteApplicationFile,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["application", applicationId], exact: true });
      queryClient.invalidateQueries({ queryKey: ["application", applicationId, "files"] });
      if (!res.ok) {
        toast({ title: <ErrorToast title="Błąd podczas usuwania." /> });
      }
    },
  });

  const handleDelete = async (fileId) => {
    deleteApplicationFileMutation.mutate({ applicationId, fileId });
  };

  const { columns } = useApplicationFilesTableColumns(handleDelete);
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : response.data,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    rowCount: response?.meta?.total ?? response?.data?.length,
    onPaginationChange: setPagination,
    manualPagination: true,
    onSortingChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: 10 });
      setSorting((old) => (updater instanceof Function ? updater(old) : updater));
    },
    onColumnFiltersChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: 10 });
      setColumnFilters((old) => (updater instanceof Function ? updater(old) : updater));
    },
    state: {
      pagination,
      sorting,
      columnFilters,
    },
  });

  useEffect(() => {
    showButton("addApplicationFiles");

    return () => {
      hideButton("addApplicationFiles");
    };
  }, []);

  return (
    <Table table={table} Filters={FileFilters} meta={response?.meta} isFetching={isFetching} isLoading={isLoading} />
  );
};
