import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { EmptyState } from "@/components/EmptyState";
import { Form } from "@/components/forms/Form";
import { FormField } from "@/components/forms/FormField";
import { Loader } from "@/components/Loader";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Separator } from "@/components/ui/separator";
import { toast } from "@/components/ui/use-toast";
import { formatMoney } from "@/helpers/formatMoney";
import i18n from "@/i18n";
import { useUserCostPolicy } from "@/policies/user/useUserCostPolicy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { MoreVertical, Plus, Trash, X } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string().required(i18n.t("Pole jest wymagane")),
  cost: Yup.string().required(i18n.t("Pole jest wymagane")),
});

export const UserCostsCard = () => {
  const userCostPolicy = useUserCostPolicy();
  const queryClient = useQueryClient();
  const { id: userId } = useParams();
  const [create, setCreate] = useState(false);

  const form = useForm({
    defaultValues: {
      name: undefined,
      cost: undefined,
    },
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  const { isLoading, data: response } = useQuery({
    queryKey: ["user", userId, "costs"],
    queryFn: () => apiClient.getUserCosts(userId),
    staleTime: (1000 * 60) ^ 5,
    enabled: userCostPolicy.viewAny(),
  });

  const deleteLeadNoteMutation = useMutation({
    mutationFn: apiClient.deleteUserCost,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["user", userId, "costs"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const createUserCostMutation = useMutation({
    mutationFn: apiClient.createUserCost,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user", userId, "costs"] });
      form.reset();
      setCreate(false);
    },
  });

  const onSubmit = (data) => {
    createUserCostMutation.mutate({ userId, data });
  };

  const handleDelete = (costId) => {
    deleteLeadNoteMutation.mutate({ userId, costId });
  };

  if (!userCostPolicy.viewAny()) return null;

  return (
    <Card className="w-1/3">
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5">
          <CardTitle>{i18n.t("Koszty stałe")}</CardTitle>
          <CardDescription>{i18n.t("Lista wszystkich kosztów stałych użytkownika")}</CardDescription>
        </div>
        {userCostPolicy.create() && (
          <Button
            variant="outline"
            title="Dodaj koszt"
            leftIcon={<Plus size={20} />}
            onClick={() => setCreate(!create)}
          />
        )}
      </CardHeader>
      <CardContent className="divide-y divide-y-border">
        <ul className="grid gap-3">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {response?.data?.length === 0 && <EmptyState title="Brak kosztów" />}
              {response?.data?.map((cost) => (
                <>
                  <li key={cost.id} className="flex items-center justify-between">
                    <span className="text-muted-foreground w-1/2">{cost.name}</span>
                    <div className="flex flex-row gap-3 items-center">
                      <span>{formatMoney(cost.cost)}</span>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button
                            variant="ghost"
                            className="h-8 w-8 p-0"
                            leftIcon={<MoreVertical className="h-4 w-4" />}
                          />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          {userCostPolicy.destroy() && (
                            <DeleteAlertDialog
                              onConfirm={() => handleDelete(cost.id)}
                              message="Czy na pewno chcesz usunąć ten koszt? Tej operacji nie można cofnąć"
                              trigger={
                                <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                                  <Trash size={16} className="mr-1 text-primary" />
                                  <span className="font-semibold">{i18n.t("Usuń")}</span>
                                </DropdownMenuItem>
                              }
                            />
                          )}
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  </li>
                  <Separator className="my-2" />
                </>
              ))}
            </>
          )}
          {create && (
            <Form form={form} onSubmit={onSubmit} className="flex flex-col gap-3">
              <FormField name="name" autoComplete="off" label="Nazwa" placeholder="Nazwa kosztu" />
              <FormField type="number" name="cost" inputMode="numeric" label="Koszt" placeholder="Wartość" />
              <div className="flex flex-row gap-3 items-center">
                <Button leftIcon={<Plus size={16} />} title="Dodaj" type="submit" />
                <Button
                  type="button"
                  variant="destructive"
                  leftIcon={<X size={16} />}
                  title="Anuluj"
                  onClick={() => setCreate(false)}
                />
              </div>
            </Form>
          )}
          {response?.data?.length > 0 && (
            <li className="flex items-center justify-between">
              <span className="text-muted-foreground w-1/2">{i18n.t("Suma")}</span>
              <div className="flex flex-row gap-3 items-center">
                <span>
                  {formatMoney(
                    response?.data?.reduce((acc, { cost }) => {
                      return acc + cost;
                    }, 0),
                  )}
                </span>
              </div>
            </li>
          )}
        </ul>
      </CardContent>
    </Card>
  );
};
