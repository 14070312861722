import { LeadStatusCreateDialog } from "@/components/Leads/Settings/Statuses/LeadStatusCreateDialog";
import { LeadStatuses } from "@/components/Leads/Settings/Statuses/LeadStatuses";
import { LeadSources } from "@/components/Leads/Sources/LeadSources";
import { ProcessCreateDialog } from "@/components/Process/ProcessCreateDialog";
import { ProcessSettings } from "@/components/Process/ProcessSettings";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import i18n from "@/i18n";
import { useLeadSourcePolicy } from "@/policies/lead/useLeadSourcePolicy";
import { useLeadStatusPolicy } from "@/policies/lead/useLeadStatusPolicy";
import { useProcessPolicy } from "@/policies/useProcessPolicy";
import { useState } from "react";

export const LeadSettings = () => {
  const [activeTab, setActiveTab] = useState("statuses");
  const leadSourcePolicy = useLeadSourcePolicy();
  const leadStatusPolicy = useLeadStatusPolicy();
  const processPolicy = useProcessPolicy();

  return (
    <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value)}>
      <TabsList className="mb-6">
        {leadStatusPolicy.create() && <TabsTrigger value="statuses">{i18n.t("Statusy")}</TabsTrigger>}
        {leadSourcePolicy.create() && <TabsTrigger value="sources">{i18n.t("Źródła")}</TabsTrigger>}
        {processPolicy.create() && <TabsTrigger value="processes">{i18n.t("Procesy")}</TabsTrigger>}
      </TabsList>
      {leadStatusPolicy.create() && (
        <TabsContent value="statuses">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Statusy")}</h2>
            <LeadStatusCreateDialog />
          </div>
          <LeadStatuses />
        </TabsContent>
      )}
      {leadSourcePolicy.create() && (
        <TabsContent value="sources">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Źródła")}</h2>
          </div>
          <LeadSources />
        </TabsContent>
      )}
      {processPolicy.create() && (
        <TabsContent value="processes">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Procesy")}</h2>
            <ProcessCreateDialog appliesTo="Lead" />
          </div>
          <ProcessSettings model="Lead" />
        </TabsContent>
      )}
    </Tabs>
  );
};
