import { Checkbox } from "@/components/forms/Checkbox";
import { ErrorMessage } from "@/components/forms/ErrorMessage";
import { FormControl, FormDescription, FormItem, FormLabel } from "@/components/ui/form";
import { Controller, useFormContext } from "react-hook-form";

export function FormCheckboxGroup({ name, label, description, options, fallback, ...rest }) {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={() => (
        <div className="w-full">
          <div className="mb-4">
            <FormLabel className="text-base">{label}</FormLabel>
            {description && <FormDescription>{description}</FormDescription>}
          </div>
          {options.length === 0 && fallback ? fallback : null}
          {options.map((item) => (
            <Controller
              key={item.value}
              control={control}
              name={name}
              render={({ field, index }) => {
                return (
                  <FormItem
                    key={item.value + "_checkbox_" + index}
                    className="flex flex-row items-start space-x-3 space-y-0"
                  >
                    <FormControl>
                      <Checkbox
                        checked={field.value?.includes(item.value)}
                        onCheckedChange={(checked) => {
                          return checked
                            ? field.onChange([...field.value, item.value])
                            : field.onChange(field.value?.filter((value) => value !== item.value));
                        }}
                        {...rest}
                      />
                    </FormControl>
                    <FormLabel className="font-normal">{item.name}</FormLabel>
                  </FormItem>
                );
              }}
            />
          ))}
          <ErrorMessage error={errors[name]?.message} />
        </div>
      )}
    />
  );
}
