import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

export const UserAvatar = ({ user, ...rest }) => {
  if (!user || !user.avatar) {
    return (
      <Avatar {...rest}>
        <AvatarFallback />
      </Avatar>
    );
  }

  return (
    <Avatar {...rest}>
      <AvatarImage src={user.avatar} alt="avatar" />
      <AvatarFallback />
    </Avatar>
  );
};
