import apiClient from "@/api/apiClient";
import { useAuth } from "@/hooks/useAuth";
import { useLeadSourcePolicy } from "@/policies/lead/useLeadSourcePolicy";
import { useQuery } from "@tanstack/react-query";
import { createContext, useEffect, useState } from "react";

export const LeadSourceContext = createContext();

export function LeadSourceProvider({ children }) {
  const { user } = useAuth();
  const leadSourcePolicy = useLeadSourcePolicy();
  const [sources, setSources] = useState([]);
  const [sourceOptions, setSourceOptions] = useState([]);

  const {
    isLoading,
    data: response,
    error,
  } = useQuery({
    queryKey: ["leadSources"],
    queryFn: apiClient.getLeadsSources,
    staleTime: 1000 * 60 * 30,
    enabled: user !== null && leadSourcePolicy.viewAny(),
  });

  useEffect(() => {
    if (!isLoading && !error && response) {
      setSources(response.data);
      setSourceOptions(response.data.map((source) => ({ name: source.name, value: source.id })));
    }
  }, [response, isLoading, error]);

  return (
    <LeadSourceContext.Provider value={{ sources, isLoading, sourceOptions }}>{children}</LeadSourceContext.Provider>
  );
}
