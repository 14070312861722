import { Button } from "@/components/Button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { Check, ChevronsUpDown, Plus } from "lucide-react";
import { forwardRef, useEffect, useState } from "react";

const CreateSelect = forwardRef(
  (
    {
      options,
      setValue,
      placeholder = "Wybierz z listy",
      description,
      required = true,
      disabled,
      value,
      leftIcon,
      variant = "outline",
      isLoading = false,
      className,
      onCreate = () => {},
      ...rest
    },
    ref,
  ) => {
    const [open, setOpen] = useState(false);
    const [comboValue, setComboValue] = useState(value);
    const [query, setQuery] = useState("");

    useEffect(() => {
      setComboValue(value);
    }, [value]);

    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            variant={variant}
            role="combobox"
            aria-expanded={open}
            disabled={disabled}
            isLoading={isLoading}
            leftIcon={leftIcon}
            rightIcon={<ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />}
            title={comboValue ? options.find((option) => option.value === comboValue)?.name : placeholder}
            className={cn("w-full", className, isLoading ? "justify-start" : "justify-between")}
          />
        </PopoverTrigger>
        {description && (
          <span className="text-sm text-muted-foreground mt-1">
            {typeof description === "string" ? i18n.t(description) : description}
          </span>
        )}
        <PopoverContent className="popover-content-width-same-as-its-trigger p-0">
          <Command
            filter={(value, search) => {
              const item = options.find((item) => item.value === value);
              if (!item) return 0;
              if (item.name.toLowerCase().includes(search.toLowerCase())) return 1;
            }}
            {...rest}
            onChange={() => {}}
          >
            <CommandInput
              placeholder={i18n.t("Wyszukaj lub stwórz wpisując frazę...")}
              value={query}
              onValueChange={(value) => setQuery(value)}
            />
            {query.length > 0 ? (
              <CommandEmpty
                onClick={() => {
                  if (onCreate) {
                    onCreate(query);
                    setQuery("");
                  }
                }}
                className="relative flex cursor-pointer hover:bg-accent select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none aria-selected:bg-accent aria-selected:text-accent-foreground data-[disabled='true']:pointer-events-none data-[disabled='true']:opacity-50"
              >
                <Plus className={cn("mr-2 h-4 w-4")} />
                <p>{i18n.t("Dodaj " + query)}</p>
              </CommandEmpty>
            ) : (
              <CommandEmpty>{i18n.t("Brak wyników.")}</CommandEmpty>
            )}
            <CommandGroup>
              <CommandList>
                {options.map((option) => (
                  <CommandItem
                    key={option.name}
                    value={option.value}
                    onSelect={(currentValue) => {
                      if (required) {
                        setValue(currentValue);
                        setComboValue(currentValue);
                      } else {
                        setComboValue(currentValue === comboValue ? "" : currentValue);
                        setValue(currentValue === comboValue ? "" : currentValue);
                      }
                      setOpen(false);
                    }}
                  >
                    <Check className={cn("mr-2 h-4 w-4", comboValue === option.value ? "opacity-100" : "opacity-0")} />
                    {option.name}
                  </CommandItem>
                ))}
              </CommandList>
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    );
  },
);

CreateSelect.displayName = "CreateSelect";

export { CreateSelect };
