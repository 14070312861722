export const CalculatorAdditionalCostType = Object.freeze({
  FIXED: {
    value: "FIXED",
    name: "Stały",
  },
  DYNAMIC: {
    value: "DYNAMIC",
    name: "Dynamiczny",
  },
  RANGE: {
    value: "RANGE",
    name: "Zakresowy",
  },
  CONDITIONAL: {
    value: "CONDITIONAL",
    name: "Warunkowy",
  },

  getValues() {
    return Object.values(this).filter((value) => typeof value === "object" && value !== null);
  },

  getNames() {
    return this.getValues().map((status) => status.name);
  },
});
