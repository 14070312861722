import { Input } from "@/components/forms/Input";
import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";

export const PostalCodeFilter = ({ onChange, value: initialValue, debounce = 500, className, ...rest }) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (initialValue !== value) {
      const timeout = setTimeout(() => {
        if (isValidPostalCode(value)) {
          onChange(value);
        }
      }, debounce);

      return () => clearTimeout(timeout);
    }
  }, [value]);

  const isValidPostalCode = (postalCode) => {
    return /^[0-9]{2}-[0-9]{3}$/.test(postalCode);
  };

  const formatPostalCode = (input) => {
    const sanitized = input.replace(/[^0-9]/g, "");

    if (sanitized.length <= 2) {
      return sanitized;
    } else if (sanitized.length <= 5) {
      return `${sanitized.slice(0, 2)}-${sanitized.slice(2)}`;
    } else {
      return `${sanitized.slice(0, 2)}-${sanitized.slice(2, 5)}`;
    }
  };

  const handleChange = (e) => {
    const formattedValue = formatPostalCode(e.target.value);
    setValue(formattedValue);
  };

  return (
    <div>
      <Input
        {...rest}
        className={cn("w-fit", value ? "border-primary" : "", className)}
        placeholder="Kod pocztowy"
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};
