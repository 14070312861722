import apiClient from "@/api/apiClient";
import { UserRole } from "@/enums/UserRoleEnum";
import { useAuth } from "@/hooks/useAuth";
import { useDepartmentPolicy } from "@/policies/useDepartmentPolicy";
import { useUserPolicy } from "@/policies/user/useUserPolicy";
import { useQuery } from "@tanstack/react-query";
import { createContext, useEffect, useState } from "react";

export const UsersContext = createContext();

export function UsersProvider({ children }) {
  const { user: authUser } = useAuth();
  const departmentPolicy = useDepartmentPolicy();
  const userPolicy = useUserPolicy();
  const [users, setUsers] = useState([]);
  const [userOptions, setUserOptions] = useState([]);

  const {
    isLoading,
    data: response,
    error,
  } = useQuery({
    queryKey: ["Users"],
    queryFn: apiClient.getUsers,
    staleTime: 1000 * 60 * 30,
    enabled: authUser !== null && userPolicy.viewAny(),
  });

  useEffect(() => {
    if (!isLoading && !error && response) {
      setUsers(response.data);
      setUserOptions(
        response.data
          .filter((u) => u.roles[0]?.name !== UserRole.SUPERADMIN.value)
          .map((user) => ({
            name: user.name,
            content: (
              <span className="flex flex-col gap-1 justify-center">
                <p className=" font-semibold">{user.name}</p>
                {user.department && departmentPolicy.viewAny() ? (
                  <p className="text-xs">
                    {user.department.name} - {UserRole[user.roles[0]?.name]?.name}
                  </p>
                ) : (
                  <p className="text-xs">{UserRole[user.roles[0]?.name]?.name}</p>
                )}
              </span>
            ),
            value: user.id,
          })),
      );
    }
  }, [response, isLoading, error]);

  return <UsersContext.Provider value={{ users, isLoading, userOptions }}>{children}</UsersContext.Provider>;
}
