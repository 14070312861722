import apiClient from "@/api/apiClient";
import { CalendarView } from "@/components/CalendarView";
import { FullPageLoader } from "@/components/FullPageLoader";
import { ActionType } from "@/enums/ActionTypeEnum";
import { routes } from "@/routes";
import { useQuery } from "@tanstack/react-query";
import { parse } from "date-fns";
import { useEffect, useState } from "react";

export const LeadsCalendar = () => {
  const [events, setEvents] = useState([]);
  const filters = {
    columnFilters: [{ id: "withActions", value: true }],
  };

  const {
    isLoading: isLoadingLeads,
    data: leads,
    error: errorLeads,
  } = useQuery({
    queryKey: ["leads", filters],
    queryFn: () => apiClient.getLeads(filters),
  });

  useEffect(() => {
    setEvents(() => []);
    if (!isLoadingLeads && leads && !errorLeads) {
      if (leads.data.length) {
        const actions = leads.data.flatMap((lead) => lead.actions);
        setEvents(
          actions.map((action) => ({
            title: `${action.name} - ${leads.data.find((ins) => ins.id === action.actionable.id)?.name}`,
            link: `${routes.leads}/${action?.actionable?.id}`,
            icon: ActionType[action.type]?.icon,
            date: parse(action.action_at, "yyyy-MM-dd HH:mm:ss", new Date()),
            description: action.description,
            name: ActionType[action.type]?.name ?? "Działanie",
            className: ActionType[action.type]?.color ?? "bg-yellow-600 hover:bg-yellow-600/90",
          })),
        );
      }
    }
  }, [isLoadingLeads, leads, errorLeads]);

  return isLoadingLeads ? <FullPageLoader /> : <CalendarView events={events} />;
};
