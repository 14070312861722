import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { ClickAwayListener } from "@/components/ClickAwayListener";
import { EmptyState } from "@/components/EmptyState";
import { Textarea } from "@/components/forms/Textarea";
import { Loader } from "@/components/Loader";
import { Note } from "@/components/Notes/Note";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { toast } from "@/components/ui/use-toast";
import i18n from "@/i18n";
import { useContractNotePolicy } from "@/policies/contracts/useContractNotePolicy";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { StickyNote } from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router-dom";

export const ContractNotesCard = () => {
  const { id: contractId } = useParams();
  const contractNotePolicy = useContractNotePolicy();
  const queryClient = useQueryClient();

  const [create, setCreate] = useState(false);
  const [note, setNote] = useState("");

  const { isLoading, data: response } = useQuery({
    queryKey: ["contract", contractId, "notes"],
    queryFn: () => apiClient.getContractNotes(contractId),
  });

  const deleteContractNoteMutation = useMutation({
    mutationFn: apiClient.deleteContractNote,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["contract", contractId, "notes"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const updateContractNoteMutation = useMutation({
    mutationFn: apiClient.updateContractNote,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["contract", contractId, "notes"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const createContractNoteMutation = useMutation({
    mutationFn: apiClient.createContractNote,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["contract", contractId, "notes"] });
        setCreate(false);
        setNote("");
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const handleEdit = (noteId, note) => {
    updateContractNoteMutation.mutate({ contractId, noteId, note });
  };

  const handleDelete = (noteId) => {
    deleteContractNoteMutation.mutate({ contractId, noteId });
  };

  const handleCreate = () => {
    if (note.length > 0) createContractNoteMutation.mutate({ contractId, note });
  };

  return (
    <Card className="flex-1 h-fit shadow-md pr-2">
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5">
          <CardTitle>{i18n.t("Notatki")}</CardTitle>
          <CardDescription>{i18n.t("Notatki napisane do tej umowy")}</CardDescription>
        </div>
        {contractNotePolicy.create() && (
          <Button
            variant="outline"
            title="Dodaj notatkę"
            leftIcon={<StickyNote size={20} />}
            onClick={() => setCreate(!create)}
          />
        )}
      </CardHeader>
      <ScrollArea className="w-full flex max-h-96 flex-col">
        <CardContent>
          <div className="flex flex-col gap-3 justify-center items-center">
            {create && (
              <Card className="w-full mt-1">
                <ClickAwayListener onClickAway={handleCreate}>
                  <Textarea value={note} onChange={(e) => setNote(e.target.value)} />
                </ClickAwayListener>
              </Card>
            )}
            {isLoading && !response ? (
              <Loader />
            ) : (
              <>
                {!create && !response.data.length && <EmptyState title="Brak notatek" />}
                {response.data.map((note) => {
                  return (
                    <Note
                      key={note.id}
                      note={note}
                      handleDelete={handleDelete}
                      handleEdit={handleEdit}
                      canDelete={contractNotePolicy.destroy(note)}
                      canEdit={contractNotePolicy.update(note)}
                    />
                  );
                })}
              </>
            )}
          </div>
        </CardContent>
      </ScrollArea>
    </Card>
  );
};
