import { FileCheck2, FileClock } from "lucide-react";

export const BillingGroupStatus = Object.freeze({
  PENDING: {
    value: "PENDING",
    name: "Oczekuje na rozliczenie",
    icon: () => <FileClock className="mr-2 h-6 w-6 text-yellow-500" />,
  },
  SETTLED: {
    value: "SETTLED",
    name: "Rozliczona",
    icon: () => <FileCheck2 className="mr-2 h-6 w-6 text-green-500" />,
  },

  getValues() {
    return Object.values(this).filter((value) => typeof value === "object" && value !== null);
  },

  getNames() {
    return this.getValues().map((status) => status.name);
  },
});
