import { CalculationFieldsSummary } from "@/components/Calculations/CalculationDetails/CalculationFieldsSummary";
import { CalculationPriceSummaryCardContent } from "@/components/Calculators/CalculationPriceSummaryCardContent";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import i18n from "@/i18n";
import { useCalculationPolicy } from "@/policies/useCalculationPolicy";

export const CalculationDetails = ({ calculation }) => {
  const calculationPolicy = useCalculationPolicy();

  return (
    <div className="flex flex-row gap-5 items-start">
      <CalculationFieldsSummary calculation={calculation} />
      {calculationPolicy.view(calculation) ? (
        <Card className="flex-1">
          <CardHeader>
            <CardTitle>{i18n.t("Podsumowanie")}</CardTitle>
            <CardDescription>{i18n.t("Kwoty oraz pola dodatkowe wyliczone podczas kalkulacji")}</CardDescription>
          </CardHeader>
          <CardContent className="p-0">
            <CalculationPriceSummaryCardContent calculator={calculation.calculator} />
          </CardContent>
        </Card>
      ) : (
        <div className="flex-1"></div>
      )}
    </div>
  );
};
