import apiClient from "@/api/apiClient";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Layout } from "@/components/layout/Layout";
import { ProductEditForm } from "@/components/Product/ProductEditForm";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const ProductEditPage = () => {
  const { id: productId } = useParams();
  const { isLoading, data: response } = useQuery({
    queryKey: ["product", productId],
    queryFn: () => apiClient.getProduct(productId),
  });
  useDocumentTitle(response?.data ? `Edycja produktu ${response?.data?.name}` : null);

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col justify-between items-center sm:flex-row">
        <Breadcrumbs idAlias={response?.data?.name} />
      </div>
      <ProductEditForm product={response?.data} />
    </Layout>
  );
};
