import apiClient from "@/api/apiClient";
import { ContractDepartmentStat } from "@/components/Contracts/Stats/ContractDepartmentStat";
import { ContractIncomeStat } from "@/components/Contracts/Stats/ContractIncomeStat";
import { ContractProductStat } from "@/components/Contracts/Stats/ContractProductStat";
import { ContractSourceStat } from "@/components/Contracts/Stats/ContractSourceStat";
import { useContractPolicy } from "@/policies/contracts/useContractPolicy";
import { useQuery } from "@tanstack/react-query";

export const ContractsStats = ({ dateRange }) => {
  const contractPolicy = useContractPolicy();
  const { isLoading, data: response } = useQuery({
    queryKey: ["contractsStats", dateRange],
    queryFn: () => apiClient.getContractsStats(dateRange),
    staleTime: 1000 * 60 * 5,
    enabled: contractPolicy.viewStats(),
  });

  return (
    <div className="w-full flex flex-col gap-5">
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-5">
        <ContractSourceStat dateRange={dateRange} data={response?.data?.source_stat} isLoading={isLoading} />
        <ContractDepartmentStat dateRange={dateRange} data={response?.data?.department_stat} isLoading={isLoading} />
      </div>
      <div className="w-full grid grid-cols-1 lg:grid-cols-1 gap-5">
        <ContractProductStat dateRange={dateRange} data={response?.data?.product_stat} isLoading={isLoading} />
      </div>
      <div className="w-full grid grid-cols-1">
        <ContractIncomeStat dateRange={dateRange} data={response?.data?.income_stat} isLoading={isLoading} />
      </div>
    </div>
  );
};
