import { useAuth } from "@/hooks/useAuth";

export const useApplicationPolicy = () => {
  const { hasPermission, hasAnyPermission, user } = useAuth();

  const viewAny = () => {
    return hasAnyPermission(["access_all_applications", "access_own_applications"]);
  };

  const viewAll = () => {
    return hasPermission("access_all_applications");
  };

  const view = () => {
    return hasAnyPermission(["show_any_application", "show_own_application"]);
  };

  const create = () => {
    return hasPermission("create_application");
  };

  const update = () => {
    return hasAnyPermission(["update_any_application", "update_own_application"]);
  };

  const updateStatus = () => {
    return hasPermission(["update_applications_status"]);
  };

  const destroy = (application) => {
    if (hasPermission("delete_any_application")) {
      return true;
    }
    if (hasPermission("delete_own_application")) {
      return user?.id === application?.user?.id;
    }
  };

  const viewProcesses = () => {
    return hasPermission("access_application_processes");
  };

  const updateProcess = () => {
    return hasPermission("update_application_process");
  };

  return {
    viewAny,
    viewAll,
    view,
    create,
    update,
    updateStatus,
    destroy,
    viewProcesses,
    updateProcess,
  };
};
