import { Button } from "@/components/Button";
import { ClickAwayListener } from "@/components/ClickAwayListener";
import { Input } from "@/components/ui/input";
import { useEffect, useState } from "react";

export const EditableCell = ({ value, onSave, formatter = null, ...rest }) => {
  const [inputValue, setInputValue] = useState(value);
  const [editMode, setEditMode] = useState(false);

  const handleClickAway = () => {
    if (inputValue !== value) {
      onSave(inputValue);
    }
    setEditMode(false);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return editMode ? (
    <ClickAwayListener onClickAway={handleClickAway} className="flex flex-col gap-1 justify-center">
      <Input className="w-fit" value={inputValue} onChange={(e) => setInputValue(e.target.value)} {...rest} />
    </ClickAwayListener>
  ) : (
    <Button variant="outline" onClick={() => setEditMode(true)} className="flex flex-row gap-2 items-center">
      {value && formatter ? formatter(value) : value}
    </Button>
  );
};
