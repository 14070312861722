import authStorage from "@/helpers/authStorage";
import { useAuth } from "@/hooks/useAuth";
import { routes } from "@/routes";
import { Navigate, Outlet } from "react-router-dom";

export function ProtectedRoute() {
  const { user } = useAuth();
  const token = authStorage.getToken();

  if (!user || !token) {
    return <Navigate to={routes.login} />;
  }
  return <Outlet />;
}
