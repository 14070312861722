import { useAuth } from "@/hooks/useAuth";

export const useContractChangePolicy = () => {
  const { hasPermission } = useAuth();

  const viewAny = () => {
    return hasPermission("access_contract_changes");
  };

  return {
    viewAny,
  };
};
