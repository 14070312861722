import apiClient from "@/api/apiClient";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { DashboardRoleBasedOverview } from "@/components/Dashboard/DashboardRoleBasedOverview";
import { Layout } from "@/components/layout/Layout";
import { Tabs, TabsContent } from "@/components/ui/tabs";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useDashboardPolicy } from "@/policies/useDashboardPolicy";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const DashboardPage = () => {
  useDocumentTitle("Pulpit");
  const { state } = useLocation();
  const dashboardPolicy = useDashboardPolicy();
  const [activeTab, setActiveTab] = useState("overview");

  const { isLoading, data: response } = useQuery({
    queryKey: ["dashboard", "overview"],
    queryFn: apiClient.getDashboardOverview,
    staleTime: 1000 * 60 * 10,
    enabled: dashboardPolicy.viewOverview(),
  });

  useEffect(() => {
    if (state?.tab) {
      setActiveTab(state.tab);
    }
  }, [state]);

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col justify-between gap-4">
        <Breadcrumbs />
        <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value)}>
          {/* {(dashboardPolicy.viewStats() || dashboardPolicy.viewOverview()) && (
            <TabsList className="mb-6">
              <TabsTrigger value="overview">{i18n.t("Przegląd")}</TabsTrigger>
              <TabsTrigger value="stats">{i18n.t("Statystyki")}</TabsTrigger>
            </TabsList>
          )} */}
          {dashboardPolicy.viewOverview() && response && (
            <TabsContent value="overview">
              <DashboardRoleBasedOverview data={response.data} />
            </TabsContent>
          )}
          {/* {dashboardPolicy.viewStats() && (
            <TabsContent value="stats">
              <DashboardStats />
            </TabsContent>
          )} */}
        </Tabs>
      </div>
    </Layout>
  );
};
