import { CheckCircle, Circle, Timer } from "lucide-react";

export const TaskStatus = Object.freeze({
  NEW: {
    value: "NEW",
    name: "Nowy",
    color: "bg-primary/10 border-l-primary border-l-4",
    icon: () => <Circle className="mr-2 h-5 w-5 text-muted-foreground" />,
  },
  IN_PROGRESS: {
    value: "IN_PROGRESS",
    name: "W trakcie",
    color: "bg-yellow-500/30 border-l-yellow-500 border-l-4",
    icon: () => <Timer className="mr-2 h-5 w-5 text-muted-foreground" />,
  },
  DONE: {
    value: "DONE",
    name: "Zrobiony",
    color: "bg-green-500 bg-opacity-20 border-l-green-500 border-l-4",
    icon: () => <CheckCircle className="mr-2 h-5 w-5 text-muted-foreground" />,
  },

  getValues() {
    return Object.values(this).filter((value) => typeof value === "object" && value !== null);
  },

  getNames() {
    return this.getValues().map((status) => status.name);
  },
});
