import apiClient from "@/api/apiClient";
import { CalendarView } from "@/components/CalendarView";
import { FullPageLoader } from "@/components/FullPageLoader";
import { routes } from "@/routes";
import { useQuery } from "@tanstack/react-query";
import { parse } from "date-fns";
import { CalendarClock, Wrench } from "lucide-react";
import { useEffect, useState } from "react";

export const AuditsCalendar = () => {
  const [events, setEvents] = useState([]);
  const filters = {
    columnFilters: [{ id: "withActions", value: true }],
  };

  const {
    isLoading: isLoadingAudits,
    data: audits,
    error: errorAudits,
  } = useQuery({
    queryKey: ["audits", filters],
    queryFn: () => apiClient.getAudits(filters),
  });

  useEffect(() => {
    setEvents(() => []);
    if (!isLoadingAudits && audits && !errorAudits) {
      if (audits.data.length) {
        const actions = audits.data.flatMap((audit) => audit.actions);
        setEvents([
          ...actions.map((action) => ({
            title: `${action.name} - ${audits.data.find((ins) => ins.id === action.actionable.id)?.lead?.name}`,
            link: `${routes.audits}/${action?.actionable?.id}`,
            icon: (props) => <CalendarClock {...props} />,
            date: parse(action.action_at, "yyyy-MM-dd HH:mm:ss", new Date()),
            description: action.description,
            name: "Działanie",
            className: "bg-yellow-600 hover:bg-yellow-600/90",
          })),
          ...audits.data
            .filter((audit) => audit.date)
            .map((audit) => ({
              title: `Audyt - ${audit.lead.name}`,
              link: `${routes.audits}/${audit?.id}`,
              icon: (props) => <Wrench {...props} />,
              date: parse(audit.date, "yyyy-MM-dd", new Date()),
              description: audit.description,
              name: "Audyt",
              className: "bg-blue-500 hover:bg-blue-500/90",
            })),
        ]);
      }
    }
  }, [isLoadingAudits, audits, errorAudits]);

  return isLoadingAudits ? <FullPageLoader /> : <CalendarView events={events} />;
};
