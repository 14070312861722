import { useAuth } from "@/hooks/useAuth";

export const useContractMessagePolicy = () => {
  const { user, hasPermission } = useAuth();

  const viewAny = () => {
    return hasPermission("access_contract_messages");
  };

  const create = () => {
    return hasPermission("create_contract_message");
  };

  const update = (contractMessage) => {
    return contractMessage?.user?.id === user?.id && hasPermission("update_contract_message");
  };

  const destroy = (contractMessage) => {
    return (
      hasPermission("delete_any_contract_message") ||
      (contractMessage?.user?.id === user?.id && hasPermission("delete_contract_message"))
    );
  };

  return {
    viewAny,
    create,
    update,
    destroy,
  };
};
