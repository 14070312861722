import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormDatePicker } from "@/components/forms/FormDatePicker";
import { FormField } from "@/components/forms/FormField";
import { FormMultipleSelect } from "@/components/forms/FormMultipleSelect";
import { FormSelect } from "@/components/forms/FormSelect";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { ContractProductContext } from "@/context/ContractProductContext";
import { ContractPaymentType } from "@/enums/ContractPaymentTypeEnum";
import { getVoivodeshipOptions } from "@/helpers/address/getVoivodeshipOptions";
import { formatMoney } from "@/helpers/formatMoney";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { useContractPolicy } from "@/policies/contracts/useContractPolicy";
import { routes } from "@/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { parse } from "date-fns";
import { Check, X } from "lucide-react";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  first_name: Yup.string().required(i18n.t("Pole jest wymagane")),
  last_name: Yup.string().required(i18n.t("Pole jest wymagane")),
  phone_number: Yup.string()
    .required(i18n.t("Pole jest wymagane"))
    .min(9, "Numer telefonu musi mieć co najmniej 9 cyfr")
    .max(15, "Numer telefonu może mieć maksymalnie 15 cyfr"),
  email: Yup.string().email(i18n.t("To pole musi być poprawnym adresem email.")).required(i18n.t("Pole jest wymagane")),
  street_number: Yup.string().required(i18n.t("Pole jest wymagane")),
  postal_code: Yup.string().required(i18n.t("Pole jest wymagane")),
  city: Yup.string().required(i18n.t("Pole jest wymagane")),
  voivodeship: Yup.string().required(i18n.t("Pole jest wymagane")),
  personal_identity_number: Yup.string().required(i18n.t("Pole jest wymagane")),
  identifier: Yup.string().required(i18n.t("Pole jest wymagane")),
  signed_at: Yup.date(i18n.t("Pole musi być poprawną datą.")).required(i18n.t("Pole jest wymagane")),
  contract_product_ids: Yup.array()
    .of(Yup.string())
    .required(i18n.t("Pole jest wymagane"))
    .min(1, i18n.t("Pole jest wymagane")),
  description: Yup.string().optional().nullable(),
  reason: Yup.string().required(i18n.t("Pole jest wymagane")),
  calculation_id: Yup.string().required(i18n.t("Pole jest wymagane")),
  comission: Yup.string().required(i18n.t("Pole jest wymagane")),
});

export const ContractEditForm = ({ contract }) => {
  const { id: contractId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const contractPolicy = useContractPolicy();
  const paymentDisabled = contract.contractPayments?.length > 0;
  const { productOptions, isLoading: isLoadingProducts } = useContext(ContractProductContext);

  const { voivodeshipOptions } = getVoivodeshipOptions();

  const defaultValues = {
    first_name: contract.first_name,
    last_name: contract.last_name,
    phone_number: contract.phone_number,
    email: contract.email,
    street_number: contract.address.street_number,
    postal_code: contract.address.postal_code,
    city: contract.address.city,
    voivodeship: contract.address.voivodeship,
    personal_identity_number: contract.personal_identity_number,
    identifier: contract.identifier,
    signed_at: parse(contract.signed_at, "yyyy-MM-dd", new Date()),
    contract_product_ids: contract.products.map((product) => product.id),
    calculation_id: contract.calculation.id,
    description: contract.description || undefined,
    comission: contract.comission,
    tranche_count: "1",
    tranches: [],
    net_price: contract.net_price,
    gross_price: contract.gross_price,
    payment_type: contract.payment_type,
    down_payment: contract.down_payment,
    vat: contract.vat.toString(),
    reason: undefined,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const watch = form.watch();

  const onSubmit = async (data) => {
    updateContractMutation.mutate({ contractId, data });
  };

  const updateContractMutation = useMutation({
    mutationFn: apiClient.updateContract,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["contracts"] });
      queryClient.invalidateQueries({ queryKey: ["contract", contractId], exact: true });
      queryClient.invalidateQueries({ queryKey: ["contract", contractId, "changes"] });
      queryClient.invalidateQueries({ queryKey: ["sidebar"] });
      if (res.ok) {
        return navigate(`${routes.contracts}/${contractId}`);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const getTrancheSum = () => {
    return watch.tranches.reduce((sum, tranche) => sum + parseFloat(tranche ?? 0), 0);
  };

  useEffect(() => {
    form.resetField("tranches");
    form.resetField("down_payment");
  }, [watch.payment_type]);

  useEffect(() => {
    if (watch.gross_price) {
      if (watch.payment_type === ContractPaymentType.CASH.value) {
        if (watch.tranche_count === "1") {
          form.resetField("tranches");
          form.setValue("tranches[0]", watch.gross_price);
        } else if (watch.tranche_count === "2") {
          const first = Math.floor(watch.gross_price / 2);
          const second = parseFloat(watch.gross_price - first).toFixed(2);
          form.resetField("tranches");
          form.setValue("tranches[0]", first);
          form.setValue("tranches[1]", second);
        } else if (watch.tranche_count === "3") {
          const firstSecond = Math.floor(watch.gross_price / 3);
          const third = parseFloat(watch.gross_price - firstSecond * 2).toFixed(2);
          form.resetField("tranches");
          form.setValue("tranches[0]", firstSecond);
          form.setValue("tranches[1]", firstSecond);
          form.setValue("tranches[2]", third);
        }
      }
    }
  }, [watch.payment_type, watch.tranche_count, watch.gross_price]);

  return (
    <Form form={form} onSubmit={onSubmit}>
      <div className="flex flex-col xl:flex-row w-full gap-5 mt-5">
        <Card className="w-full h-fit">
          <CardHeader>
            <CardTitle className="text-base text-primary">{i18n.t("Informacje")}</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex flex-col gap-4">
              <FormField name="first_name" label="Imię" autoComplete="first_name" />
              <FormField name="last_name" label="Nazwisko" autoComplete="last_name" />
              <FormField name="email" type="email" autoComplete="email" label="Email" />
              <FormField name="phone_number" autoComplete="phone_number" label="Numer telefonu" inputMode="numeric" />
              <FormField name="street_number" label="Ulica i numer" />
              <div className="flex flex-row gap-3 items-center">
                <FormField name="postal_code" label="Kod pocztowy" />
                <FormField name="city" label="Miasto" />
              </div>
              <FormCombobox options={voivodeshipOptions} name="voivodeship" label="Województwo" required={false} />
              <FormField name="personal_identity_number" label="PESEL" />
              <FormField name="identifier" label="Numer umowy" />
              <FormDatePicker name="signed_at" label="Data podpisania umowy" />
              <FormMultipleSelect
                options={productOptions}
                isLoading={isLoadingProducts}
                name="contract_product_ids"
                label="Produkty"
              />
              <FormTextarea name="description" label="Opis" placeholder="Wszystkie niestandardowe ustalenia" />
              <FormField name="reason" label="Powód zmiany" />
            </div>
          </CardContent>
          <CardFooter className="gap-3 items-center border-t px-6 py-4">
            <Button
              type="submit"
              title="Zapisz"
              leftIcon={<Check size={20} />}
              isLoading={updateContractMutation.isPending}
            />
            <Button
              title="Anuluj"
              type="button"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => navigate(-1)}
            />
          </CardFooter>
        </Card>
        <div className="w-full">
          <Card>
            <CardHeader>
              <CardTitle className="text-base text-primary">{i18n.t("Informacje o płatności")}</CardTitle>
              {paymentDisabled && (
                <CardDescription className="text-yellow-500">
                  {i18n.t("Nie można edytować tych pól płatności ponieważ umowa posiada dodane płatności.")}
                </CardDescription>
              )}
            </CardHeader>
            <CardContent className="space-y-5">
              <FormField name="net_price" disabled={true} label="Wartość netto" type="number" step=".01" />
              <FormSelect
                options={[
                  { name: "8%", value: "8" },
                  { name: "23%", value: "23" },
                ]}
                disabled={true}
                name="vat"
                label="VAT"
                type="number"
              />
              <FormField name="gross_price" disabled={true} label="Wartość brutto" type="number" step=".01" />
              <FormField name="comission" label="Prowizja" type="number" disabled={!contractPolicy.updateComission()} />
              <FormSelect
                label="Typ płatności"
                options={ContractPaymentType.getValues()}
                name="payment_type"
                disabled={paymentDisabled}
              />
              {[ContractPaymentType.LOAN.value, ContractPaymentType.PREFINANCED.value].includes(watch.payment_type) && (
                <FormField
                  type="number"
                  step=".01"
                  name="down_payment"
                  label="Wpłata własna klienta"
                  disabled={paymentDisabled}
                />
              )}
              {watch.payment_type === ContractPaymentType.CASH.value && (
                <>
                  <FormSelect
                    options={[
                      { name: "Jedna", value: "1" },
                      { name: "Dwie", value: "2" },
                      { name: "Trzy", value: "3" },
                    ]}
                    defaultOption={{ name: "Jedna", value: "1" }}
                    name="tranche_count"
                    disabled={paymentDisabled}
                    label="Liczba transz płatności"
                  />
                  {watch.tranche_count > 0 &&
                    Array.from({ length: parseInt(watch.tranche_count) }, (_, i) => (
                      <FormField
                        key={`tranche_${i + 1}`}
                        type="number"
                        name={`tranches[${i}]`}
                        disabled={paymentDisabled}
                        label={`Transza ${i + 1}`}
                        placeholder={`Wartość transzy ${i + 1} w zł`}
                      />
                    ))}
                  <span
                    className={cn(
                      "flex flex-row gap-1",
                      getTrancheSum() === parseFloat(watch.gross_price) ? "text-green-500" : "text-red-500",
                    )}
                  >
                    <p>{i18n.t("Suma transz")}</p>
                    <p>{formatMoney(getTrancheSum())}</p>
                  </span>
                </>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    </Form>
  );
};
