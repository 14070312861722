import { UserCreatePage } from "@/pages/User/UserCreatePage";
import { UserDetailsPage } from "@/pages/User/UserDetailsPage";
import { UserEditPage } from "@/pages/User/UserEditPage";
import { UsersPage } from "@/pages/User/UsersPage";
import { routes } from "@/routes";
import { Route } from "react-router-dom";

export const UserRoutes = () => (
  <>
    <Route exact path={routes.users} element={<UsersPage />} />
    <Route exact path={routes.userCreate} element={<UserCreatePage />} />
    <Route exact path={routes.userEdit} element={<UserEditPage />} />
    <Route exact path={routes.user} element={<UserDetailsPage />} />
  </>
);
