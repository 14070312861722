import apiClient from "@/api/apiClient";
import { DepartmentLeadCountStat } from "@/components/Departments/Stats/DepartmentLeadCountStat";
import { DepartmentUserContractCountStat } from "@/components/Departments/Stats/DepartmentUserContractCountStat";
import { useDepartmentPolicy } from "@/policies/useDepartmentPolicy";
import { useQuery } from "@tanstack/react-query";

export const DepartmentStats = ({ id, dateRange }) => {
  const departmentPolicy = useDepartmentPolicy();

  const { isLoading, data: response } = useQuery({
    queryKey: ["department", id, "stats", dateRange],
    queryFn: () => apiClient.getDepartmentStats(id, dateRange),
    staleTime: 1000 * 60 * 5,
    enabled: departmentPolicy.view({ id }),
  });

  return (
    <div className="w-full flex flex-col gap-5">
      <div className="w-full grid grid-cols-1 lg:grid-cols-4 gap-5">
        <DepartmentLeadCountStat data={response?.data?.conversion_stat} dateRange={dateRange} isLoading={isLoading} />
        <DepartmentUserContractCountStat
          data={response?.data?.user_contract_count_stat}
          dateRange={dateRange}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};
