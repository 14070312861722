export const FileExtension = Object.freeze({
  PDF: { name: "PDF", value: "pdf" },
  DOCX: { name: "DOCX", value: "docx" },
  ODT: { name: "ODT", value: "odt" },
  XLSX: { name: "XLSX", value: "xlsx" },
  ODS: { name: "ODS", value: "ods" },
  PNG: { name: "PNG", value: "png" },
  JPG: { name: "JPG", value: "jpg" },
  JPEG: { name: "JPEG", value: "jpeg" },
  HEIC: { name: "HEIC", value: "heic" },

  getValues() {
    return Object.values(this).filter((value) => typeof value === "object" && value !== null);
  },

  getNames() {
    return this.getValues().map((extension) => extension.name);
  },
});
