import { ThemeContext } from "@/context/ThemeContext";
import { cn } from "@/lib/utils";
import { Moon, Sun } from "lucide-react";
import { useContext } from "react";

export const ThemeToggle = ({ className }) => {
  const { toggleTheme, theme } = useContext(ThemeContext);

  return (
    <a
      title="Zmień motyw"
      className={cn(
        "flex items-center justify-center p-3 box-content rounded-md w-fit hover:bg-accent hover:cursor-pointer",
        className,
      )}
      onClick={toggleTheme}
    >
      {theme === "dark" ? <Moon size={20} /> : <Sun size={20} />}
    </a>
  );
};
