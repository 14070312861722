import { ClearFiltersButton } from "@/components/Filters/ClearFiltersButton";
import { ColumnFilter } from "@/components/Filters/ColumnFilter";
import { MultipleSelectFilter } from "@/components/Filters/MultipleSelectFilter";
import { SearchFilter } from "@/components/Filters/SearchFilter";
import { UsersContext } from "@/context/UsersContext";
import { BillingStatus } from "@/enums/BillingStatusEnum";
import { useFilters } from "@/hooks/table/useFilters";
import { useBillingPolicy } from "@/policies/billing/useBillingPolicy";
import { useContext } from "react";

export const BillingFilters = ({ table }) => {
  const billingPolicy = useBillingPolicy();
  const { filter, setFilter } = useFilters(table);
  const { userOptions, isLoading: isLoadingUsers } = useContext(UsersContext);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row flex-wrap gap-3 mb-3">
        <div className="flex flex-row gap-3 w-full lg:max-w-sm">
          <ClearFiltersButton table={table} />
          <SearchFilter value={filter("search")} onChange={(value) => setFilter("search", value)} />
        </div>
        <MultipleSelectFilter
          options={BillingStatus.getValues()}
          setValue={(value) => setFilter("status", value)}
          title="Status"
          value={filter("status")}
        />
        {billingPolicy.viewAll() && (
          <MultipleSelectFilter
            options={userOptions}
            setValue={(value) => setFilter("user", value)}
            title="Użytkownik"
            isLoading={isLoadingUsers}
            value={filter("user")}
          />
        )}
        <ColumnFilter table={table} />
      </div>
    </div>
  );
};
