import { Select } from "@/components/forms/Select";
import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";

export const EditableSelectCell = ({ value, onSave, options, className, ...rest }) => {
  const [inputValue, setInputValue] = useState(value);

  const onChange = (value) => {
    onSave(value);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <Select
      className={cn("w-fit whitespace-nowrap gap-1", className)}
      options={options}
      setValue={onChange}
      value={inputValue}
      {...rest}
    />
  );
};
