import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { Layout } from "@/components/layout/Layout";
import { ProductsTable } from "@/components/Product/ProductsTable";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useProductPolicy } from "@/policies/useProductPolicy";
import { routes } from "@/routes";
import { Plus } from "lucide-react";
import { useNavigate } from "react-router-dom";

export const ProductsPage = () => {
  useDocumentTitle("Produkty");
  const navigate = useNavigate();
  const productPolicy = useProductPolicy();

  const handleCreate = () => {
    return navigate(routes.productCreate);
  };

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {productPolicy.create() && (
            <Button variant="outline" title="Dodaj produkt" leftIcon={<Plus size={20} />} onClick={handleCreate} />
          )}
        </div>
      </div>
      <ProductsTable />
    </Layout>
  );
};
