export const OrderStatus = Object.freeze({
  NEW: {
    value: "NEW",
    name: "Nowe",
    color: "bg-primary dark:bg-primary/90 hover:bg-primary hover:dark:bg-primary/90",
  },
  COMPLETED: {
    value: "COMPLETED",
    name: "Skompletowane",
    color: "bg-sky-500 dark:bg-sky-500/90 hover:bg-sky-500 hover:dark:bg-sky-500/90",
  },
  SHIPPED: {
    value: "SHIPPED",
    name: "Wysłane",
    color: "bg-green-500 dark:bg-green-500/90 hover:bg-green-500 hover:dark:bg-green-500/90",
  },

  getValues() {
    return Object.values(this).filter((value) => typeof value === "object" && value !== null);
  },

  getNames() {
    return this.getValues().map((status) => status.name);
  },
});
