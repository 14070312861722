import { createContext, useEffect, useState } from "react";

export const LanguageContext = createContext();

export function LanguageProvider({ children }) {
  const [language, setLanguage] = useState();

  const setCurrentLanguage = async () => {
    if (localStorage.getItem("lang")) {
      setLanguage(localStorage.getItem("lang") ?? "pl");
    }
  };

  useEffect(() => {
    setCurrentLanguage();
  }, []);

  return <LanguageContext.Provider value={{ language, setLanguage }}>{children}</LanguageContext.Provider>;
}
