import { FormProvider } from "react-hook-form";

export const Form = ({ form, onSubmit, children, ...rest }) => {
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} {...rest}>
        {children}
      </form>
    </FormProvider>
  );
};
