import { ApiInterceptors } from "@/api/ApiInterceptors";
import ErrorBoundary from "@/components/ErrorBoundary";
import { ApplicationStatusProvider } from "@/context/ApplicationStatusContext";
import { ApplicationTypeProvider } from "@/context/ApplicationTypeContext";
import { AuditStatusProvider } from "@/context/AuditStatusContext";
import { AuthProvider } from "@/context/AuthContext";
import { ButtonProvider } from "@/context/ButtonContext";
import { ContractProductProvider } from "@/context/ContractProductContext";
import { ContractStatusProvider } from "@/context/ContractStatusContext";
import { FileCategoryProvider } from "@/context/FileCategoryContext";
import { InstallationStatusProvider } from "@/context/InstallationStatusContext";
import { LanguageProvider } from "@/context/LanguageContext";
import { LeadSourceProvider } from "@/context/LeadSourceContext";
import { LeadStatusProvider } from "@/context/LeadStatusContext";
import { PermissionProvider } from "@/context/PermissionContext";
import { ProductsProvider } from "@/context/ProductsContext";
import { ProductTypeProvider } from "@/context/ProductTypeContext";
import { ThemeProvider } from "@/context/ThemeContext";
import { UsersProvider } from "@/context/UsersContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { setDefaultOptions } from "date-fns";
import { pl } from "date-fns/locale";
import { BrowserRouter } from "react-router-dom";

export const AppProviders = ({ children }) => {
  setDefaultOptions({ locale: pl });
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 10_000,
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <PermissionProvider>
        <ThemeProvider>
          <LanguageProvider>
            <AuthProvider>
              <ButtonProvider>
                <BrowserRouter>
                  <ErrorBoundary>
                    <UsersProvider>
                      <FileCategoryProvider>
                        <LeadStatusProvider>
                          <LeadSourceProvider>
                            <ContractProductProvider>
                              <ContractStatusProvider>
                                <ProductTypeProvider>
                                  <InstallationStatusProvider>
                                    <AuditStatusProvider>
                                      <ApplicationStatusProvider>
                                        <ApplicationTypeProvider>
                                          <ProductsProvider>
                                            <ApiInterceptors />
                                            {children}
                                          </ProductsProvider>
                                        </ApplicationTypeProvider>
                                      </ApplicationStatusProvider>
                                    </AuditStatusProvider>
                                  </InstallationStatusProvider>
                                </ProductTypeProvider>
                              </ContractStatusProvider>
                            </ContractProductProvider>
                          </LeadSourceProvider>
                        </LeadStatusProvider>
                      </FileCategoryProvider>
                    </UsersProvider>
                  </ErrorBoundary>
                </BrowserRouter>
              </ButtonProvider>
            </AuthProvider>
          </LanguageProvider>
        </ThemeProvider>
      </PermissionProvider>
    </QueryClientProvider>
  );
};
