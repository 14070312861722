import apiClient from "@/api/apiClient";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { saveAs } from "file-saver";

export const downloadFile = async (file) => {
  const response = await apiClient.downloadFile(file.id);
  if (response.ok) {
    saveAs(response.data, file.original_name);
    toast({ title: <SuccessToast title="Plik został pobrany" /> });
  } else {
    toast({ title: <ErrorToast title="Błąd podczas pobierania pliku" /> });
  }
};
