import apiClient from "@/api/apiClient";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { CalendarView } from "@/components/CalendarView";
import { SearchSelectFilter } from "@/components/Filters/SearchSelectFilter";
import { Layout } from "@/components/layout/Layout";
import { UsersContext } from "@/context/UsersContext";
import { getCalendarEvents } from "@/helpers/calendar/getCalendarEvents";
import { useAuth } from "@/hooks/useAuth";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useUserPolicy } from "@/policies/user/useUserPolicy";
import { useQuery } from "@tanstack/react-query";
import { XCircle } from "lucide-react";
import { useContext, useEffect, useState } from "react";

export const CalendarPage = () => {
  useDocumentTitle("Kalendarz");
  const { hasPermission, user: authUser } = useAuth();
  const [filters, setFilters] = useState({ user: authUser.id });
  const [events, setEvents] = useState([]);
  const { users, isLoading: isLoadingUsers } = useContext(UsersContext);
  const [userOptions, setUserOptions] = useState([]);
  const userPolicy = useUserPolicy();

  const {
    isLoading,
    data: calendar,
    error,
  } = useQuery({
    queryKey: ["calendar", filters],
    queryFn: () => apiClient.getCalendar(filters),
    staleTime: 1000 * 60 * 5,
    enabled: hasPermission("access_calendar"),
  });

  useEffect(() => {
    if (!isLoadingUsers && users) {
      setUserOptions(
        users.map((user) => {
          if (user.id === authUser.id) return { name: "Mój kalendarz", value: user.id };
          else return { name: user.name, value: user.id };
        }),
      );
    }
  }, [isLoadingUsers, users]);

  useEffect(() => {
    setEvents(() => []);
    if (!isLoading && calendar && !error) {
      setEvents(getCalendarEvents(calendar.data));
    }
  }, [isLoading, calendar, error]);

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col justify-between items-center sm:flex-row">
        <Breadcrumbs />
      </div>
      {hasPermission("access_calendar") && calendar && (
        <CalendarView
          events={events}
          Filters={
            userPolicy.viewCalendar() && (
              <>
                {filters.user && filters.user !== authUser.id && (
                  <Button
                    variant="outline"
                    leftIcon={<XCircle size={20} />}
                    size="icon"
                    onClick={() => setFilters({ user: authUser.id })}
                  />
                )}
                <div className="w-full lg:w-56">
                  <SearchSelectFilter
                    isLoading={isLoadingUsers}
                    options={userOptions}
                    filters={filters}
                    value={filters.user}
                    setValue={(value) => setFilters({ user: value })}
                    placeholder="Użytkownik"
                    name="user"
                  />
                </div>
              </>
            )
          }
        />
      )}
    </Layout>
  );
};
