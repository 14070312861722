import { ClearFiltersButton } from "@/components/Filters/ClearFiltersButton";
import { ColumnFilter } from "@/components/Filters/ColumnFilter";
import { MultipleSelectFilter } from "@/components/Filters/MultipleSelectFilter";
import { SearchFilter } from "@/components/Filters/SearchFilter";
import { SelectFilter } from "@/components/Filters/SelectFilter";
import { TaskStatus } from "@/enums/TaskStatusEnum";
import { useFilters } from "@/hooks/table/useFilters";

export const TasksFilters = ({ table }) => {
  const { filter, setFilter } = useFilters(table);

  return (
    <div className="flex flex-row flex-wrap gap-3 mb-3">
      <div className="flex flex-row gap-3 w-full lg:max-w-sm">
        <ClearFiltersButton table={table} />
        <SearchFilter value={filter("search")} onChange={(value) => setFilter("search", value)} />
      </div>
      <MultipleSelectFilter
        options={TaskStatus.getValues()}
        setValue={(value) => setFilter("status", value)}
        title="Status"
        value={filter("status")}
      />
      <SelectFilter
        options={[
          { name: "Tak", value: "true" },
          { name: "Nie", value: "false" },
        ]}
        setValue={(value) => setFilter("recurring", value)}
        title="Powtarzające"
        value={filter("recurring")}
      />
      <ColumnFilter table={table} />
    </div>
  );
};
