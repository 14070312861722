import { CardRow } from "@/components/ui/CardRow";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import i18n from "@/i18n";

export const DepartmentInformationCard = ({ department }) => {
  return (
    <Card className="w-1/2 shadow-md">
      <CardHeader>
        <CardTitle>{i18n.t("Informacje")}</CardTitle>
        <CardDescription>{i18n.t("Informacje na temat oddziału")}</CardDescription>
      </CardHeader>
      <CardContent className="divide-y divide-y-border">
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Nazwa oddziału")}</p>
          <p>{department.name}</p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Data utworzenia")}</p>
          <p>{department.created_at.split(" ")[0]}</p>
        </CardRow>
      </CardContent>
    </Card>
  );
};
