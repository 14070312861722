import { useAuth } from "@/hooks/useAuth";

export const useProcessPolicy = () => {
  const { hasPermission } = useAuth();

  const viewAny = () => {
    return hasPermission("access_processes");
  };

  const create = () => {
    return hasPermission("create_process");
  };

  const update = () => {
    return hasPermission("update_process");
  };

  const destroy = () => {
    return hasPermission("delete_process");
  };

  return {
    viewAny,
    create,
    update,
    destroy,
  };
};
