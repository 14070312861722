import apiClient from "@/api/apiClient";
import { AuditCreateDialog } from "@/components/Audits/AuditCreateDialog";
import { UserTooltip } from "@/components/Users/UserTooltip";
import { Select } from "@/components/forms/Select";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { CardRow } from "@/components/ui/CardRow";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { LeadStatusContext } from "@/context/LeadStatusContext";
import { LeadStatus } from "@/enums/LeadStatusEnum";
import { isAddressNotEmpty } from "@/helpers/address/isAddressNotEmpty";
import { capitalize } from "@/helpers/capitalize";
import { formatPhoneNumber } from "@/helpers/formatPhoneNumber";
import i18n from "@/i18n";
import { useAuditPolicy } from "@/policies/audit/useAuditPolicy";
import { useLeadPolicy } from "@/policies/lead/useLeadPolicy";
import { useLeadSourcePolicy } from "@/policies/lead/useLeadSourcePolicy";
import { routes } from "@/routes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format, parse } from "date-fns";
import { useContext } from "react";
import { Link, useParams } from "react-router-dom";

export const LeadInformationCard = ({ lead }) => {
  const leadPolicy = useLeadPolicy();
  const leadSourcePolicy = useLeadSourcePolicy();
  const auditPolicy = useAuditPolicy();
  const { statusOptions, isLoading: isLoadingStatuses } = useContext(LeadStatusContext);

  const { id: leadId } = useParams();
  const queryClient = useQueryClient();

  const handleStatusClick = (value) => {
    if (value) {
      const data = { lead_status_id: value };
      updateLeadStatusMutation.mutate({ id: leadId, data });
    }
  };

  const updateLeadStatusMutation = useMutation({
    mutationFn: apiClient.updateLeadsStatus,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["leads"] });
      queryClient.invalidateQueries({ queryKey: ["lead", leadId] });
      queryClient.invalidateQueries({ queryKey: ["sidebar"] });
      if (!res.ok) {
        toast({ title: <ErrorToast title="Błąd podczas zapisywania." /> });
      }
    },
  });

  return (
    <Card className="flex-1 h-fit shadow-md">
      <CardHeader>
        <CardTitle>{i18n.t("Informacje")}</CardTitle>
        <CardDescription>{i18n.t("Informacje o kontakcie")}</CardDescription>
      </CardHeader>
      <CardContent className="divide-y divide-y-border">
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Imię i nazwisko")}</p>
          <p>{lead.name}</p>
        </CardRow>
        {leadSourcePolicy.viewAny() && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Źródło")}</p>
            <p>{lead?.source?.name ?? i18n.t("Brak")}</p>
          </CardRow>
        )}
        {leadPolicy.viewStats() && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Data utworzenia")}</p>
            <p title={lead.created_at}>
              {format(parse(lead.created_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMM yyyy")}
            </p>
          </CardRow>
        )}
        {lead.assigned_at && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Data przypisania")}</p>
            <p className={lead.status.name === LeadStatus.EXPIRED ? "text-red-500" : ""}>
              {lead.assigned_at
                ? format(parse(lead.assigned_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMM yyyy")
                : i18n.t("Nieprzypisany")}
            </p>
          </CardRow>
        )}
        {leadPolicy.assignUserLeads() && lead.created_by && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Utworzony przez")}</p>
            <UserTooltip user={lead.created_by} />
          </CardRow>
        )}
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Status kontaktu")}</p>
          {leadPolicy.updateStatus() || lead.processes.length === 0 ? (
            <Select
              className="w-fit text-primary-foreground dark:text-foreground dark:bg-opacity-70"
              style={{ backgroundColor: lead.status.color }}
              options={statusOptions}
              setValue={handleStatusClick}
              value={lead.status.id}
              isLoading={isLoadingStatuses || updateLeadStatusMutation.isPending}
            />
          ) : (
            <Badge className="h-8 rounded-md" style={{ backgroundColor: lead.status.color }}>
              {lead.status.name}
            </Badge>
          )}
        </CardRow>
        {auditPolicy.create() && (
          <CardRow className="hover:bg-background">
            <p className="text-xs sm:text-base font-medium">{i18n.t("Status audytu")}</p>
            {lead.audit ? (
              <>
                {auditPolicy.view() ? (
                  <Link to={`${routes.audits}/${lead.audit.id}`}>
                    <Badge
                      className="h-8 rounded-md cursor-pointer"
                      style={{ backgroundColor: lead.audit.status.color }}
                    >
                      {lead.audit.status.name}
                    </Badge>
                  </Link>
                ) : (
                  <Badge className="h-8 rounded-md" style={{ backgroundColor: lead.audit.status.color }}>
                    {lead.audit.status.name}
                  </Badge>
                )}
              </>
            ) : (
              <AuditCreateDialog lead={lead} />
            )}
          </CardRow>
        )}
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Adres")}</p>
          {isAddressNotEmpty(lead) ? (
            <div className="flex flex-col justify-end sm:items-end">
              <span className="flex gap-1 items-center">
                <p className="italic text-xs font-bold">{i18n.t("Adres: ")}</p>
                {lead?.address?.street_number ?? i18n.t("Brak")}
              </span>
              <span className="flex gap-1 items-center">
                <p className="italic text-xs font-bold">{i18n.t("Kod pocztowy: ")}</p>
                {lead?.address?.postal_code ?? i18n.t("Brak")}
              </span>
              <span className="flex gap-1 items-center">
                <p className="italic text-xs font-bold">{i18n.t("Miasto: ")}</p>
                {lead?.address?.city ?? i18n.t("Brak")}
              </span>
              <span className="flex gap-1 items-center">
                <p className="italic text-xs font-bold">{i18n.t("Woj. ") + ":"}</p>
                {lead?.address?.voivodeship ? capitalize(lead.address.voivodeship) : i18n.t("Brak")}
              </span>
            </div>
          ) : (
            <p>{i18n.t("Brak")}</p>
          )}
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Numer telefonu")}</p>
          {lead.phone_number ? (
            <a className="font-semibold" href={`tel:${lead.phone_number}`}>
              {formatPhoneNumber(lead.phone_number)}
            </a>
          ) : (
            <p>{i18n.t("Brak")}</p>
          )}
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Adres e-mail")}</p>
          {lead.email ? (
            <a className="font-semibold" href={`mailto:${lead.email}`}>
              {lead.email}
            </a>
          ) : (
            <p>{i18n.t("Brak")}</p>
          )}
        </CardRow>
        {leadPolicy.assignUserLeads() && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Użytkownik odpowiedzialny")}</p>
            {lead.user ? (
              <UserTooltip user={lead.user} linkClassName="whitespace-break-spaces text-right" />
            ) : (
              i18n.t("Brak")
            )}
          </CardRow>
        )}
        {lead.description && (
          <CardRow className="flex lg:flex-col lg:items-start gap-1">
            <p className="text-xs sm:text-base font-medium">{i18n.t("Opis")}</p>
            <p className="text-muted-foreground">{lead.description ?? i18n.t("Brak")}</p>
          </CardRow>
        )}
      </CardContent>
    </Card>
  );
};
