import { useAuth } from "@/hooks/useAuth";

export const useInstallationNotePolicy = () => {
  const { user, hasPermission } = useAuth();

  const viewAny = () => {
    return hasPermission("access_installation_notes");
  };

  const create = () => {
    return hasPermission("create_installation_note");
  };

  const update = (installationNote) => {
    return installationNote?.user?.id === user?.id && hasPermission("update_installation_note");
  };

  const destroy = (installationNote) => {
    if (hasPermission("delete_any_installation_note")) return true;
    return installationNote?.user?.id === user?.id && hasPermission("delete_installation_note");
  };

  return {
    viewAny,
    create,
    update,
    destroy,
  };
};
