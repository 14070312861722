import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { Progress } from "@/components/Progress";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { UserAvatarLink } from "@/components/Users/UserAvatarLink";
import i18n from "@/i18n";
import { useContractPaymentPolicy } from "@/policies/contracts/useContractPaymentPolicy";
import { useContractPolicy } from "@/policies/contracts/useContractPolicy";
import { routes } from "@/routes";
import { format, parse } from "date-fns";
import { ArrowDown, ArrowUp, ArrowUpDown, MoreHorizontal, Pencil, Trash } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";

export const useContractsTableColumns = (handleDelete) => {
  const contractPolicy = useContractPolicy();
  const contractPaymentPolicy = useContractPaymentPolicy();
  const navigate = useNavigate();

  const columns = [
    {
      id: "name",
      name: "Imię i nazwisko",
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Imię i nazwisko")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        if (contractPolicy.view()) {
          return (
            <Link
              to={`${routes.contracts}/${row.original.id}`}
              className="flex flex-row items-center gap-3 font-medium hover:underline"
            >
              {row.getValue("name")}
            </Link>
          );
        } else {
          return <span>{row.getValue("name")}</span>;
        }
      },
    },
    {
      id: "status",
      name: "Status",
      accessorKey: "status",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Status")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const contract = row.original;
        return (
          <span
            className="rounded-md px-2 py-1 font-semibold whitespace-nowrap text-primary-foreground dark:text-foreground dark:bg-opacity-60"
            style={{ backgroundColor: contract.status.color }}
          >
            {contract.status.name}
          </span>
        );
      },
    },
    {
      id: "products",
      name: "Produkt",
      accessorKey: "products",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Produkty")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const contract = row.original;
        return (
          <div className="flex flex-row gap-2 items-center flex-wrap">
            {contract.products.map((product) => (
              <span
                className="px-2 py-1 w-fit rounded-md font-medium whitespace-nowrap text-primary-foreground dark:text-foreground dark:bg-opacity-60"
                style={{ backgroundColor: product.color }}
                key={product.id}
              >
                {product.name}
              </span>
            ))}
          </div>
        );
      },
    },
    {
      id: "created_by",
      name: "Dodana przez",
      accessorKey: "created_by",
      header: i18n.t("Dodana przez"),
      cell: ({ row }) => {
        const creator = row.original.created_by;
        return <UserAvatarLink user={creator} />;
      },
    },
    contractPaymentPolicy.viewAny()
      ? {
          id: "payment_sum",
          name: "Postęp płatności",
          accessorKey: "payment_sum",
          header: ({ column }) => {
            return (
              <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
                {i18n.t("Postęp płatności")}
                {column.getIsSorted() === "asc" ? (
                  <ArrowDown className="h-4 w-4" />
                ) : column.getIsSorted() === "desc" ? (
                  <ArrowUp className="h-4 w-4" />
                ) : (
                  <ArrowUpDown className="h-4 w-4" />
                )}
              </Button>
            );
          },
          cell: ({ row }) => {
            const contract = row.original;
            return (
              <div className="w-[200px]">
                <Progress total={contract.gross_price} current={contract.payment_sum} />
              </div>
            );
          },
        }
      : { id: "payment_sum" },
    {
      id: "signed_at",
      name: "Data podpisania",
      accessorKey: "signed_at",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Data podpisania")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const contract = row.original;
        return <span>{format(parse(contract.signed_at, "yyyy-MM-dd", new Date()), "dd MMM yyyy")}</span>;
      },
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const contract = row.original;
        if ((!contractPolicy.destroy() || !handleDelete) && !contractPolicy.update(contract)) return null;
        const handleEdit = () => {
          return navigate(`${routes.contracts}/${contract.id}/edytuj`);
        };

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {contractPolicy.update(contract) && (
                <DropdownMenuItem onClick={handleEdit} className="flex flex-row items-center">
                  <Pencil size={16} className="mr-1 text-primary" />
                  <span className="font-semibold">{i18n.t("Edytuj")}</span>
                </DropdownMenuItem>
              )}
              {contractPolicy.destroy() && handleDelete && (
                <>
                  <DropdownMenuSeparator />
                  <DeleteAlertDialog
                    onConfirm={() => handleDelete(contract.id)}
                    message="Czy na pewno chcesz usunąć tę umowę? Tej operacji nie można cofnąć"
                    trigger={
                      <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                        <Trash size={16} className="mr-1 text-primary" />
                        <span className="font-semibold">{i18n.t("Usuń")}</span>
                      </DropdownMenuItem>
                    }
                  />
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
