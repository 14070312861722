import { ProductsContext } from "@/context/ProductsContext";
import { ProductTypeContext } from "@/context/ProductTypeContext";
import { CalculatorFieldType } from "@/enums/CalculatorFieldTypeEnum";
import { Metadata } from "@/enums/MetadataEnum";
import { ProductType } from "@/enums/ProductTypeEnum";
import i18n from "@/i18n";
import { useContext } from "react";

export const useCalculatorCustomFields = () => {
  const { products } = useContext(ProductsContext);
  const { types: productTypes } = useContext(ProductTypeContext);
  return {
    moc_instalacji: {
      name: i18n.t("Moc instalacji"),
      description: i18n.t(
        `Pole dodatkowe. Wyliczane na podstawie pola typu Produkt z typem produktu "Panele fotowoltaiczne" poprzez metadane o nazwie "${Metadata.POWER}".`,
      ),
      calculate: (fields) => {
        let power = 0;
        const panelType = productTypes.find((type) => type.name === ProductType.SOLAR_PANEL);
        const panel = fields.find(
          (field) => field.type === CalculatorFieldType.PRODUCT.value && field.product_type === panelType.id,
        );
        if (!panel) return power;
        const product = products.find((product) => product.id === panel.value);
        if (!product) return power;
        const powerMetadata = product.metadata?.find((metadata) => metadata.name === Metadata.POWER) || 0;
        if (!powerMetadata) return power;
        if (panel.quantity) {
          power = parseFloat(powerMetadata.value) * parseInt(panel.quantity);
        } else power = parseFloat(powerMetadata.value);
        return power.toFixed(2);
      },
      unit: "kWp",
    },
    pojemnosc_magazynow: {
      name: i18n.t("Pojemność magazynów energii"),
      description: i18n.t(
        `Pole dodatkowe. Wyliczane na podstawie pola typu Produkt z typem produktu "Magazyn energii" poprzez metadane o nazwie "${Metadata.CAPACITY}".`,
      ),
      calculate: (fields) => {
        let capacity = 0;
        const storageType = productTypes.find((type) => type.name === ProductType.ENERGY_STORAGE);
        const storage = fields.find(
          (field) => field.type === CalculatorFieldType.PRODUCT.value && field.product_type === storageType.id,
        );
        if (!storage) return capacity;
        const product = products.find((product) => product.id === storage.value);
        if (!product) return capacity;
        const capacityMetadata = product?.metadata?.find((metadata) => metadata.name === Metadata.CAPACITY) || 0;
        if (!capacityMetadata) return capacity;
        if (storage.quantity) {
          capacity = parseFloat(capacityMetadata.value) * parseInt(storage.quantity);
        } else capacity = parseFloat(capacityMetadata.value);
        return capacity.toFixed(2);
      },
      unit: "kWh",
    },
  };
};
