import { DepartmentDetailsPage } from "@/pages/Department/DepartmentDetailsPage";
import { DepartmentsPage } from "@/pages/Department/DepartmentsPage";
import { routes } from "@/routes";
import { Route } from "react-router-dom";

export const DepartmentRoutes = () => (
  <>
    <Route exact path={routes.departments} element={<DepartmentsPage />} />
    <Route exact path={routes.department} element={<DepartmentDetailsPage />} />
  </>
);
