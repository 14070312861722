import { Button } from "@/components/Button";
import { Container } from "@/components/layout/Container";
import { Sidebar } from "@/components/layout/Sidebar";
import i18n from "@/i18n";
import { ChevronLeft } from "lucide-react";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

export const ErrorLayout = ({ onRetry }) => {
  const navigate = useNavigate();
  const timeoutRef = useRef(null);

  const handleRetry = () => {
    navigate(-1);
    timeoutRef.current = setTimeout(() => {
      onRetry();
    }, 100);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <Sidebar />
      <Container>
        <div className="text-center flex justify-center items-center flex-col fixed inset-0">
          <p className="text-base font-semibold text-primary">{i18n.t("Upsss...")}</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight sm:text-5xl">{i18n.t("Wystąpił błąd")}</h1>
          <p className="mt-6 text-base leading-7">
            {i18n.t("Informacja o błędzie została już do nas wysłana i naprawimy go najszybciej jak to możliwe")}
          </p>
          <p className="text-base leading-7">{i18n.t("Do tego czasu spróbuj ponownie lub przejdź na inną stronę.")}</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Button onClick={handleRetry} leftIcon={<ChevronLeft size={16} />}>
              {i18n.t("Powrót")}
            </Button>
          </div>
        </div>
      </Container>
    </>
  );
};
