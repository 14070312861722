import { Select } from "@/components/forms/Select";
import { Text } from "@/components/Text";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { useTheme } from "@/hooks/useTheme";
import { Moon, Sun } from "lucide-react";

export const ChangeTheme = () => {
  const { theme, toggleTheme } = useTheme();

  const handleChange = (val) => {
    if (theme !== val) toggleTheme();
  };

  return (
    <Card className="w-1/2">
      <CardHeader>
        <CardTitle>
          <Text translate="Motyw aplikacji" />
        </CardTitle>
        <CardDescription>
          <Text translate="Tu możesz wybrać czy chcesz korzystać z jasnego czy ciemnego motywu" />
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-8">
          <Select
            className="flex flex-row gap-2"
            options={[
              { name: "Ciemny", value: "dark", icon: () => <Moon className="mr-2" size={20} /> },
              { name: "Jasny", value: "light", icon: () => <Sun className="mr-2" size={20} /> },
            ]}
            setValue={handleChange}
            value={theme}
          />
        </div>
      </CardContent>
    </Card>
  );
};
