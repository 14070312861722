import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Layout } from "@/components/layout/Layout";
import { LeadImportForm } from "@/components/Leads/LeadImportForm";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";

export const LeadImportPage = () => {
  useDocumentTitle("Import kontaktów");
  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
      </div>
      <div>
        <LeadImportForm />
      </div>
    </Layout>
  );
};
