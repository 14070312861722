import { ChartContainerWrapper } from "@/components/Charts/ChartContainerWrapper";
import { StatCardDescription } from "@/components/StatCardDescription";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";
import i18n from "@/i18n";
import { format, parse } from "date-fns";
import { Bar, BarChart, CartesianGrid, XAxis } from "recharts";

export const UserProfitabilityStat = ({ data, dateRange, isLoading }) => {
  const chartData =
    data &&
    Object.entries(data)
      .map(([month, values]) => {
        return {
          month: format(parse(month, "yyyy-MM", new Date()), "MMM yyyy"),
          costs: values.costs,
          margin: values.margin,
          leadCost: values.leadCost,
        };
      })
      .sort(
        (a, b) => parse(a.month, "MMM yyyy", new Date()).getTime() - parse(b.month, "MMM yyyy", new Date()).getTime(),
      );

  const chartConfig = {
    margin: {
      label: "Marża",
      color: "hsl(var(--chart-2))",
    },
    leadCost: {
      label: <span className="mr-3">{i18n.t("Sumaryczny koszt kontaktów")}</span>,
      color: "hsl(var(--chart-3))",
    },
    costs: {
      label: "Koszty stałe",
      color: "hsl(var(--chart-5))",
    },
  };

  return (
    <div className="col-span-2">
      <Card>
        <CardHeader>
          <CardTitle>{i18n.t("Opłacalność")}</CardTitle>
          <StatCardDescription dateRange={dateRange} />
        </CardHeader>
        <CardContent>
          <ChartContainerWrapper
            isLoading={isLoading}
            data={chartData}
            config={chartConfig}
            className="w-full max-h-[300px]"
          >
            <BarChart accessibilityLayer data={chartData}>
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="month"
                tickLine={false}
                tickMargin={10}
                axisLine={false}
                tickFormatter={(value) => value.slice(0, 3)}
              />
              <ChartTooltip cursor={false} content={<ChartTooltipContent indicator="dashed" />} />
              <Bar dataKey="margin" fill="var(--color-margin)" radius={4} />
              <Bar dataKey="leadCost" fill="var(--color-leadCost)" radius={4} />
              <Bar dataKey="costs" fill="var(--color-costs)" radius={4} />
            </BarChart>
          </ChartContainerWrapper>
        </CardContent>
      </Card>
    </div>
  );
};
