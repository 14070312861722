import { useAuth } from "@/hooks/useAuth";

export const useInstallationPolicy = () => {
  const { hasPermission, hasAnyPermission, user } = useAuth();

  const viewAny = () => {
    return hasAnyPermission(["access_all_installations", "access_own_installations"]);
  };

  const viewAll = () => {
    return hasPermission("access_all_installations");
  };

  const view = () => {
    return hasAnyPermission(["show_any_installation", "show_own_installation"]);
  };

  const create = () => {
    return hasPermission("create_installation");
  };

  const update = () => {
    return hasAnyPermission(["update_any_installation", "update_own_installation"]);
  };

  const updateStatus = () => {
    return hasPermission("update_installations_status");
  };

  const destroy = (installation) => {
    if (hasPermission("delete_any_installation")) {
      return true;
    }
    if (hasPermission("delete_own_installation")) {
      return user?.id === installation?.user?.id;
    }
  };

  const viewProcesses = () => {
    return hasPermission("access_installation_processes");
  };

  const updateProcess = () => {
    return hasPermission("update_installation_process");
  };

  return {
    viewAny,
    viewAll,
    view,
    create,
    update,
    updateStatus,
    destroy,
    viewProcesses,
    updateProcess,
  };
};
