export const UserRole = Object.freeze({
  SUPERADMIN: { value: "SUPERADMIN", name: "Super Admin" },
  ADMIN: { value: "ADMIN", name: "Admin" },
  MANAGER: { value: "MANAGER", name: "Manager" },
  SALES: { value: "SALES", name: "Handlowiec" },
  OFFICE: { value: "OFFICE", name: "Biuro" },
  AUDITOR: { value: "AUDITOR", name: "Audytor" },
  INSTALLATION_COORDINATOR: { value: "INSTALLATION_COORDINATOR", name: "Koordynator montaży" },
  APPLICANT: { value: "APPLICANT", name: "Obsługa wniosków" },
  ACCOUNTANT: { value: "ACCOUNTANT", name: "Księgowość" },
  WAREHOUSEMAN: { value: "WAREHOUSEMAN", name: "Magazynier" },

  getValues() {
    return Object.values(this).filter((value) => typeof value === "object" && value !== null);
  },

  getNames() {
    return this.getValues().map((status) => status.name);
  },

  getByPermission(hasPermission) {
    if (hasPermission("create_any_user")) {
      return [
        this.ADMIN,
        this.MANAGER,
        this.OFFICE,
        this.SALES,
        this.AUDITOR,
        this.INSTALLATION_COORDINATOR,
        this.APPLICANT,
        this.ACCOUNTANT,
        this.WAREHOUSEMAN,
      ];
    } else if (hasPermission("create_department_user")) {
      return [this.SALES];
    }
    return [];
  },

  getForDepartment() {
    return [this.MANAGER, this.SALES];
  },
});
