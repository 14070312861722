import { Button } from "@/components/Button";
import { EmptyState } from "@/components/EmptyState";
import { UserAvatar } from "@/components/Users/UserAvatar";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { UserRole } from "@/enums/UserRoleEnum";
import { useAuth } from "@/hooks/useAuth";
import i18n from "@/i18n";
import { useUserPolicy } from "@/policies/user/useUserPolicy";
import { routes } from "@/routes";
import { Plus } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";

export const DepartmentUsers = ({ department }) => {
  const { user: loggedInUser } = useAuth();
  const userPolicy = useUserPolicy();
  const navigate = useNavigate();
  const users = department.users;

  const handleCreateUser = () => {
    return navigate(routes.userCreate, { state: { departmentId: department.id } });
  };

  return (
    <Card className="w-1/2 shadow-md">
      <CardHeader className="flex flex-row justify-between items-start">
        <div className="flex flex-col gap-1.5">
          <CardTitle>{i18n.t("Użytkownicy")}</CardTitle>
          <CardDescription>{i18n.t("Lista wszystkich użytkowników w tym oddziale")}</CardDescription>
        </div>
        <div>
          {userPolicy.create() && (
            <Button
              variant="outline"
              title="Dodaj użytkownika"
              leftIcon={<Plus size={20} />}
              onClick={handleCreateUser}
            />
          )}
        </div>
      </CardHeader>
      <CardContent>
        {users?.length === 0 && <EmptyState title="Brak użytkowników" />}
        {users.map((user) =>
          user.id === loggedInUser.id ? (
            <span key={user.id} className="flex flex-row justify-between items-start w-full py-3 px-2 rounded-md">
              <div className="flex flex-row gap-2 items-center justify-center">
                <UserAvatar user={user} />
                <div className="flex flex-col justify-center">
                  <div className="block sm:hidden font-semibold">{UserRole[user.roles[0].name].name}</div>
                  <p className="whitespace-break-spaces">{user.name}</p>
                  <p className="text-primary">{user.email}</p>
                </div>
              </div>
              <div className="hidden sm:block">{UserRole[user.roles[0].name].name}</div>
            </span>
          ) : (
            <Link
              to={routes.users + `/${user.id}`}
              key={user.id}
              className="flex flex-row justify-between items-start w-full hover:bg-accent py-3 px-2 rounded-md"
            >
              <div className="flex flex-row gap-2 items-center justify-center">
                <UserAvatar user={user} />
                <div className="flex flex-col justify-center">
                  <div className="block sm:hidden font-semibold">{UserRole[user.roles[0].name].name}</div>
                  <p className="whitespace-break-spaces">{user.name}</p>
                  <p className="text-primary">{user.email}</p>
                </div>
              </div>
              <div className="hidden sm:block">{UserRole[user.roles[0].name].name}</div>
            </Link>
          ),
        )}
      </CardContent>
    </Card>
  );
};
