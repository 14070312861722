import { Button } from "@/components/Button";
import { cn } from "@/lib/utils";
import { ChevronRight, CircleCheck } from "lucide-react";
import { Link } from "react-router-dom";

export const LeadImportFinishedNotification = ({ notification }) => {
  if (!notification.data) return;
  const { allRecords, insertedRecords, existingRecords, failedRecords, link, title } = notification.data;

  return (
    <div className="flex flex-row gap-3">
      <div>
        <CircleCheck className="text-green-500" size={32} />
      </div>
      <div className="w-full">
        <div className={cn("text-base font-semibold flex flex-col items-start justify-center gap-1")}>
          <span>{title}</span>
          <span className="font-normal">
            <div>
              <p>
                Spośród <span className="font-semibold">{allRecords}</span> kontaktów:
              </p>
              <ul>
                <li className="text-green-500">
                  Nowych: <span className="font-semibold">{insertedRecords}</span>
                </li>
                <li className="text-yellow-500">
                  Istniejących: <span className="font-semibold">{existingRecords}</span>
                </li>
                <li className="text-red-500">
                  Nie dodanych: <span className="font-semibold">{failedRecords}</span>
                </li>
              </ul>
            </div>
          </span>
        </div>
        <Link to={link}>
          <Button
            variant="link"
            className="px-0 mx-0 flex flex-row items-center"
            title="Przejdź do kontaktów"
            rightIcon={<ChevronRight size={16} />}
          />
        </Link>
      </div>
    </div>
  );
};
