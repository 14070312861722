import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormField } from "@/components/forms/FormField";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import i18n from "@/i18n";
import { useCalculatorPolicy } from "@/policies/useCalculatorPolicy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Check, Pencil, X } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string().required(i18n.t("Pole jest wymagane")),
});

export const CalculatorEditDialog = ({ calculator }) => {
  const queryClient = useQueryClient();
  const calculatorPolicy = useCalculatorPolicy();

  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (data) => {
    updateCalculatorMutation.mutate({ calculatorId: calculator.id, data });
  };

  const defaultValues = {
    name: calculator.name,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onSubmit",
  });

  const updateCalculatorMutation = useMutation({
    mutationFn: apiClient.updateCalculator,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["calculators"] });
      queryClient.invalidateQueries({ queryKey: ["calculator", calculator.id] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie zapisano." /> });
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  if (!calculatorPolicy.update()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <DialogTrigger asChild>
        <Button variant="outline" title="Zmień nazwę" leftIcon={<Pencil size={20} />} />
      </DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Edycja kalkulatora")}</DialogTitle>
          <DialogDescription>{calculator.name}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormField label="Nazwa" placeholder="Nazwa kalkulatora" name="name" autoComplete="off" />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Zapisz"
              leftIcon={<Check size={20} />}
              isLoading={updateCalculatorMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
