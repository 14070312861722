import { flexRender } from "@tanstack/react-table";

import { Button } from "@/components/Button";
import { Loader } from "@/components/Loader";
import { TableLoader } from "@/components/Table/TableLoader";
import { TablePagination } from "@/components/Table/TablePagination";
import { Table as ShadTable, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import i18n from "@/i18n";

export function Table({ table, meta, Filters = null, isLoading = false, isFetching = false }) {
  return (
    <div className="w-full">
      {Filters && Filters({ table })}
      <div className="rounded-md border">
        <ShadTable>
          {isFetching && !isLoading && <TableLoader />}
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          {isLoading && (
            <TableBody>
              <TableRow className="w-full">
                <TableCell colSpan={table.getAllColumns().length}>
                  <Loader />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          <TableBody>
            {table.getRowModel().rows?.length
              ? table.getRowModel().rows.map((row) => {
                  return (
                    <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                      ))}
                    </TableRow>
                  );
                })
              : !isLoading && (
                  <TableRow>
                    <TableCell colSpan={table.getAllColumns().length} className="h-24 text-center">
                      {i18n.t("Brak wyników.")}
                    </TableCell>
                  </TableRow>
                )}
          </TableBody>
        </ShadTable>
      </div>
      {meta ? (
        <TablePagination meta={meta} table={table} />
      ) : (
        <div className="flex items-center justify-end space-x-2 py-4">
          <div className="space-x-2 flex flex-row">
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {i18n.t("Wstecz")}
            </Button>
            <Button variant="outline" size="sm" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
              {i18n.t("Dalej")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
