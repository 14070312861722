import Map from "@/assets/mapa_wojewodztw.svg";
import { removePolishCharacters } from "@/helpers/stringHelpers";
import { useEffect } from "react";

export const InstallationCrewVoivodeships = ({ highlightedRegions }) => {
  useEffect(() => {
    const allRegions = document.querySelectorAll("svg path");
    allRegions.forEach((region) => {
      region.classList.remove("voivodeship-active");
    });

    highlightedRegions.forEach((regionId) => {
      const regionElement = document.getElementById(removePolishCharacters(regionId));
      if (regionElement) {
        regionElement.classList.add("voivodeship-active");
      }
    });
  }, [highlightedRegions]);

  return (
    <div className="map-container">
      <Map />
    </div>
  );
};
