import apiClient from "@/api/apiClient";
import { AuditFilters } from "@/components/Audits/AuditFilters";
import { AuditsMap } from "@/components/Audits/Map/AuditsMap";
import { Table } from "@/components/Table/Table";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { useAuditsTableColumns } from "@/hooks/table/useAuditsTableColumns";
import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";

export const AuditsTable = ({ showMap }) => {
  const queryClient = useQueryClient();
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});

  const handleDelete = (auditId) => {
    deleteAuditMutation.mutate(auditId);
  };

  const handleEdit = async (auditId, data) => {
    updateAuditMutation.mutate({ auditId, data });
  };

  const deleteAuditMutation = useMutation({
    mutationFn: apiClient.deleteAudit,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["audits"] });
      queryClient.invalidateQueries({ queryKey: ["sidebar"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie usunięto." /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const updateAuditMutation = useMutation({
    mutationFn: apiClient.updateAudit,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["audits"] });
      if (!res.ok) {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      } else {
        toast({ title: <SuccessToast title="Pomyślnie zapisano." /> });
      }
    },
  });

  const {
    isFetching,
    isLoading,
    data: response,
  } = useQuery({
    queryKey: ["audits", pagination, sorting, columnFilters],
    queryFn: () => apiClient.getAudits({ pagination, sorting, columnFilters }),
    placeholderData: keepPreviousData,
  });

  const { columns } = useAuditsTableColumns(handleEdit, handleDelete);
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : response.data,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    rowCount: response?.meta?.total ?? response?.data?.length,
    onPaginationChange: setPagination,
    manualPagination: true,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    onSortingChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: 10 });
      setSorting((old) => (updater instanceof Function ? updater(old) : updater));
    },
    onColumnFiltersChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: 10 });
      setColumnFilters((old) => (updater instanceof Function ? updater(old) : updater));
    },
    state: {
      pagination,
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <div className="w-full flex-col flex">
      <div className="flex flex-col gap-5 w-full">
        <Table
          table={table}
          Filters={AuditFilters}
          meta={response?.meta}
          isLoading={isLoading}
          isFetching={isFetching}
        />
        {showMap && <AuditsMap audits={response?.data} />}
      </div>
    </div>
  );
};
