import { ChangeAccountInformation } from "@/components/AccountPage/ChangeAccountInformation";
import { ChangePassword } from "@/components/AccountPage/ChangePassword";
import { ChangeTheme } from "@/components/AccountPage/ChangeTheme";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Layout } from "@/components/layout/Layout";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useTheme } from "@/hooks/useTheme";
import i18n from "@/i18n";
import { LockKeyhole, Moon, Sun, User } from "lucide-react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const AccountPage = () => {
  useDocumentTitle("Konto");
  const { state } = useLocation();
  const { theme } = useTheme();
  const [activeTab, setActiveTab] = useState("information");

  useEffect(() => {
    if (state?.tab) {
      setActiveTab(state.tab);
    }
  }, [state]);

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
      </div>
      <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value)}>
        <TabsList className="mb-5">
          <TabsTrigger value="information" className="flex flex-row gap-2 items-center">
            <User size={16} />
            {i18n.t("Informacje")}
          </TabsTrigger>
          <TabsTrigger value="password" className="flex flex-row gap-2 items-center">
            <LockKeyhole size={16} />
            {i18n.t("Hasło")}
          </TabsTrigger>
          <TabsTrigger value="theme" className="flex flex-row gap-2 items-center">
            {theme === "dark" ? <Moon size={16} /> : <Sun size={16} />}
            {i18n.t("Motyw")}
          </TabsTrigger>
        </TabsList>
        <TabsContent value="information">
          <ChangeAccountInformation />
        </TabsContent>
        <TabsContent value="password">
          <ChangePassword />
        </TabsContent>
        <TabsContent value="theme">
          <ChangeTheme />
        </TabsContent>
      </Tabs>
    </Layout>
  );
};
