import { useAuth } from "@/hooks/useAuth";

export const useLeadNotePolicy = () => {
  const { user, hasPermission } = useAuth();

  const viewAny = () => {
    return hasPermission("access_lead_notes");
  };

  const create = () => {
    return hasPermission("create_lead_note");
  };

  const update = (leadNote) => {
    return leadNote?.user?.id === user?.id && hasPermission("update_lead_note");
  };

  const destroy = (leadNote) => {
    if (hasPermission("delete_any_lead_note")) return true;
    return leadNote?.user?.id === user?.id && hasPermission("delete_lead_note");
  };

  return {
    viewAny,
    create,
    update,
    destroy,
  };
};
