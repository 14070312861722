import { ErrorMessage } from "@/components/forms/ErrorMessage";
import { FileUploadInput } from "@/components/forms/FileUploadInput";
import { Label } from "@/components/ui/label";
import i18n from "@/i18n";
import { Controller, useFormContext } from "react-hook-form";

export const FormFileUpload = ({ name, label, description, ...rest }) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className="w-full flex flex-col gap-1">
          {label && (
            <Label htmlFor="contractFiles" className="flex flex-row items-center mb-1">
              {i18n.t(label)}
            </Label>
          )}
          <FileUploadInput
            {...rest}
            id={name}
            selectedFiles={field.value || []}
            onFilesSelect={(files) => field.onChange(files)}
          />
          {description && (
            <span className="text-sm text-muted-foreground">
              {typeof description === "string" ? i18n.t(description) : description}
            </span>
          )}
          <ErrorMessage error={errors[name]?.message} />
        </div>
      )}
    />
  );
};
