import apiClient from "@/api/apiClient";
import { useAuth } from "@/hooks/useAuth";
import { useQuery } from "@tanstack/react-query";
import { createContext, useEffect, useState } from "react";

export const LeadStatusContext = createContext();

export function LeadStatusProvider({ children }) {
  const [statuses, setStatuses] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const { user } = useAuth();
  const {
    isLoading,
    data: response,
    error,
  } = useQuery({
    queryKey: ["leadStatuses"],
    queryFn: apiClient.getLeadStatuses,
    staleTime: 1000 * 60 * 30,
    enabled: user !== null,
  });

  useEffect(() => {
    if (!isLoading && !error && response) {
      setStatuses(response.data);
      setStatusOptions(response.data.map((status) => ({ name: status.name, value: status.id })));
    }
  }, [response, isLoading, error]);

  return (
    <LeadStatusContext.Provider value={{ statuses, isLoading, statusOptions }}>{children}</LeadStatusContext.Provider>
  );
}
