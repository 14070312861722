import { InstallationStatusCreateDialog } from "@/components/Installations/Settings/Statuses/InstallationStatusCreateDialog";
import { InstallationStatuses } from "@/components/Installations/Settings/Statuses/InstallationStatuses";
import { ProcessCreateDialog } from "@/components/Process/ProcessCreateDialog";
import { ProcessSettings } from "@/components/Process/ProcessSettings";
import i18n from "@/i18n";
import { useInstallationStatusPolicy } from "@/policies/installation/useInstallationStatusPolicy";
import { useProcessPolicy } from "@/policies/useProcessPolicy";

export const InstallationSettings = () => {
  const installationStatusPolicy = useInstallationStatusPolicy();
  const processPolicy = useProcessPolicy();

  return (
    <div className="flex flex-col gap-5 w-full">
      {installationStatusPolicy.update() && (
        <div>
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Statusy")}</h2>
            <InstallationStatusCreateDialog />
          </div>
          <InstallationStatuses />
        </div>
      )}
      {processPolicy.create() && (
        <div>
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Procesy")}</h2>
            <ProcessCreateDialog appliesTo="Installation" />
          </div>
          <ProcessSettings model="Installation" />
        </div>
      )}
    </div>
  );
};
