import { ContractStatus } from "@/enums/ContractStatusEnum";
import { useAuth } from "@/hooks/useAuth";

export const useContractPaymentPolicy = () => {
  const { hasPermission } = useAuth();

  const viewAny = () => {
    return hasPermission("access_contract_payments");
  };

  const create = () => {
    return hasPermission("create_contract_payment");
  };

  const destroy = (contract) => {
    return hasPermission("delete_contract_payment") && contract?.status !== ContractStatus.COMPLETED;
  };

  return {
    viewAny,
    create,
    destroy,
  };
};
