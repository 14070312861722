import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";

export const MobileMenuLink = ({ menuItem, getActive }) => {
  const [expanded, setExpanded] = useState(false);
  if (menuItem.visible) {
    if (menuItem.submenu?.length > 0) {
      return (
        <div className="w-full">
          <div
            onClick={() => setExpanded(!expanded)}
            className={`w-full px-3 py-2 hover:bg-border flex flex-row font-semibold items-center gap-2 rounded-md justify-between hover:cursor-pointer ${
              getActive(menuItem, location) ? "bg-border" : ""
            }`}
          >
            <div className="flex flex-row gap-2 items-center">
              <menuItem.icon className="h-6 w-6" />
              {menuItem.title}
              {menuItem.count > 0 && (
                <>
                  <Separator orientation="vertical" className=" bg-foreground h-4" />
                  <Badge>{menuItem.count}</Badge>
                </>
              )}
            </div>
            {expanded ? <ChevronUp className="h-6 w-6" /> : <ChevronDown className="h-6 w-6" />}
          </div>
          <div className="w-full flex flex-col">
            {expanded
              ? menuItem.submenu.map(
                  (submenuItem) =>
                    submenuItem.visible && (
                      <Link
                        key={submenuItem.title}
                        to={submenuItem.to}
                        className={`pl-5 w-full px-3 mt-2 box-border py-2 hover:bg-border flex flex-row font-semibold items-center gap-2 rounded-md justify-between ${
                          getActive(submenuItem, location) ? "bg-border" : ""
                        }`}
                      >
                        <span className="flex flex-row gap-2 items-center">
                          {submenuItem.icon && submenuItem.icon({ size: 20 })}
                          {submenuItem.title}
                        </span>
                        {submenuItem.count > 0 && (
                          <>
                            <Badge>{submenuItem.count}</Badge>
                          </>
                        )}
                      </Link>
                    ),
                )
              : null}
          </div>
        </div>
      );
    } else {
      return (
        <Link
          to={menuItem.to}
          className={`w-full px-3 py-2 hover:bg-border flex flex-row font-semibold items-center gap-2 rounded-md ${
            getActive(menuItem) ? "bg-border" : ""
          }`}
        >
          <menuItem.icon className="h-6 w-6" />
          {menuItem.title}
          {menuItem.count > 0 && (
            <>
              <Separator orientation="vertical" className="bg-foreground h-4" />
              <Badge>{menuItem.count}</Badge>
            </>
          )}
        </Link>
      );
    }
  } else return null;
};
