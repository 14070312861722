import apiClient from "@/api/apiClient";
import { EmptyState } from "@/components/EmptyState";
import { Checkbox } from "@/components/forms/Checkbox";
import { Loader } from "@/components/Loader";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { toast } from "@/components/ui/use-toast";
import i18n from "@/i18n";
import { useLeadPolicy } from "@/policies/lead/useLeadPolicy";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const LeadProcessesCard = () => {
  const { id: leadId } = useParams();
  const leadPolicy = useLeadPolicy();
  const queryClient = useQueryClient();

  const [selectedTab, setSelectedTab] = useState(0);

  const { isLoading, data: response } = useQuery({
    queryKey: ["lead", leadId, "processes"],
    queryFn: () => apiClient.getLeadProcesses(leadId),
    enabled: leadPolicy.viewProcesses(),
  });

  const updateLeadProcessProgressMutation = useMutation({
    mutationFn: apiClient.updateLeadProcessProgress,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["lead", leadId], exact: true });
        queryClient.invalidateQueries({ queryKey: ["lead", leadId, "processes"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const [processesData, setProcessesData] = useState([]);

  useEffect(() => {
    if (response && !isLoading) {
      setProcessesData(
        response.data.map((process) => {
          const progress = JSON.parse(process.progress);
          return process.steps.map((step) => ({
            ...step,
            completed: progress[step.id] || false,
            children: step.children.map((child) => ({
              ...child,
              completed: progress[child.id] || false,
            })),
          }));
        }),
      );
    }
  }, [response, isLoading]);

  const handleUpdateLeadProcess = (processId, step, completed) => {
    updateLeadProcessProgressMutation.mutate({ leadId, processId, data: { step_id: step.id, completed } });
  };

  if (!leadPolicy.viewProcesses() || response?.data.length === 0) return null;

  return (
    <Card className="w-full lg:w-1/3 h-fit shadow-md">
      <CardHeader className="flex flex-row justify-between items-center pb-3">
        <div className="flex flex-col gap-1.5">
          <CardTitle>{i18n.t("Procesy")}</CardTitle>
          <CardDescription>{i18n.t("Procesy do obsługi tego kontaktu")}</CardDescription>
        </div>
      </CardHeader>
      <CardContent>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {processesData.length ? (
              <Tabs value={selectedTab} onValueChange={(value) => setSelectedTab(value)}>
                {response.data.length > 1 && (
                  <TabsList className="mb-5">
                    {response.data.map((process, index) => (
                      <TabsTrigger key={process.id} value={index}>
                        {process.name}
                      </TabsTrigger>
                    ))}
                  </TabsList>
                )}
                {response.data.map((process, index) => (
                  <TabsContent key={process.id} value={index} className="flex flex-col gap-2">
                    {processesData[index].map((step, stepIndex) => (
                      <div key={step.id} className="flex flex-col justify-center gap-1">
                        <h3 className="flex flex-row gap-2 items-center">
                          <Checkbox
                            checked={step.completed}
                            disabled={!leadPolicy.updateProcess()}
                            onCheckedChange={(value) => handleUpdateLeadProcess(process.id, step, value)}
                          />
                          {stepIndex + 1}. {step.name}
                        </h3>
                        <ul className="flex flex-col gap-2 ml-5">
                          {step?.children?.map((subStep, subStepIndex) => (
                            <li key={subStep.id} className="flex flex-row gap-2 items-center">
                              <Checkbox
                                checked={subStep.completed}
                                disabled={!leadPolicy.updateProcess()}
                                onCheckedChange={(value) => handleUpdateLeadProcess(process.id, subStep, value)}
                              />
                              {subStepIndex + 1}. {subStep.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </TabsContent>
                ))}
              </Tabs>
            ) : (
              <EmptyState title="Brak procesów" />
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};
