import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { CalculatorEditDialog } from "@/components/Calculators/CalculatorEditDialog";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { Form } from "@/components/forms/Form";
import { FormCheckbox } from "@/components/forms/FormCheckbox";
import { FormCheckboxGroup } from "@/components/forms/FormCheckboxGroup";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import i18n from "@/i18n";
import { useCalculatorPolicy } from "@/policies/useCalculatorPolicy";
import { routes } from "@/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Check, Cog, Copy, Trash } from "lucide-react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  department_ids: Yup.array().of(Yup.string()),
});

export const CalculatorSetting = ({ calculator, departmentOptions }) => {
  useDocumentTitle(`Ustawienia kalkulatora ${calculator.name}`);
  const queryClient = useQueryClient();
  const calculatorPolicy = useCalculatorPolicy();
  const navigate = useNavigate();

  const updateCalculatorVisibilityMutation = useMutation({
    mutationFn: apiClient.updateCalculatorVisibility,
    onSuccess: (res) => {
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie zapisano" /> });
        queryClient.invalidateQueries({ queryKey: ["calculators"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const deleteCalculatorMutation = useMutation({
    mutationFn: apiClient.deleteCalculator,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["calculators"] });
        toast({ title: <SuccessToast title="Pomyślnie usunięto" /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const duplicateCalculatorMutation = useMutation({
    mutationFn: apiClient.duplicateCalculator,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["calculators"] });
        toast({ title: <SuccessToast title="Pomyślnie zduplikowano." /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const form = useForm({
    defaultValues: {
      department_ids: calculator.departments.map((department) => department.id),
      is_global: calculator.is_global,
    },
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  const onSubmit = (data) => {
    updateCalculatorVisibilityMutation.mutate({ calculatorId: calculator.id, data });
  };

  const handleDelete = () => {
    deleteCalculatorMutation.mutate({ calculatorId: calculator.id });
  };

  const handleDuplicate = () => {
    duplicateCalculatorMutation.mutate({ calculatorId: calculator.id });
  };

  return (
    <Card className="w-full h-fit break-inside-avoid mb-5">
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5">
          <Link to={`${routes.calculators}/${calculator.id}`}>
            <CardTitle>{calculator.name}</CardTitle>
            <CardDescription>{i18n.t("Kalkulator")}</CardDescription>
          </Link>
        </div>
        <div className="flex flex-row gap-3 items-center">
          {calculatorPolicy.create() && (
            <Button
              leftIcon={<Copy size={20} />}
              variant="outline"
              title="Duplikuj"
              onClick={handleDuplicate}
              isLoading={duplicateCalculatorMutation.isPending}
            />
          )}
          {calculatorPolicy.update() && (
            <Button
              leftIcon={<Cog size={20} />}
              variant="outline"
              title="Konfiguracja"
              onClick={() => navigate(`${routes.calculators}/${calculator.id}/konfiguracja`)}
            />
          )}
          <CalculatorEditDialog calculator={calculator} />
          {calculatorPolicy.destroy() && (
            <DeleteAlertDialog
              message="Czy na pewno chcesz usunąć ten kalkulator? Tej operacji nie można cofnąć"
              onConfirm={handleDelete}
              trigger={
                <Button
                  variant="destructive"
                  title="Usuń"
                  leftIcon={<Trash size={20} />}
                  isLoading={deleteCalculatorMutation.isLoading}
                />
              }
            />
          )}
        </div>
      </CardHeader>
      <CardContent>
        <Form onSubmit={onSubmit} form={form} className="flex flex-col gap-5">
          <FormCheckbox
            name="is_global"
            label="Widoczny dla użytkowników bez oddziału"
            description="Zaznacz tą opcję jeśli kalkulator ma być widoczny dla użytkowników którzy nie są w żadnym oddziale"
          />
          <FormCheckboxGroup
            name="department_ids"
            options={departmentOptions}
            label="Widoczny dla oddziałów"
            description="Dodatkowo możesz zaznaczyć które oddziały powinny widzieć ten kalkulator"
          />
          <div className="flex flex-row gap-3 items-center">
            <Button
              type="submit"
              title="Zapisz"
              leftIcon={<Check size={16} />}
              isLoading={updateCalculatorVisibilityMutation.isPending}
            />
          </div>
        </Form>
      </CardContent>
    </Card>
  );
};
