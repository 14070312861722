import apiClient from "@/api/apiClient";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormCreateSelect } from "@/components/forms/FormCreateSelect";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { toast } from "@/components/ui/use-toast";
import { LeadSourceContext } from "@/context/LeadSourceContext";
import { useLeadSourcePolicy } from "@/policies/lead/useLeadSourcePolicy";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";

export const LeadSourceInput = () => {
  const leadSourcePolicy = useLeadSourcePolicy();
  const queryClient = useQueryClient();
  const { sourceOptions, isLoading: isLoadingSources } = useContext(LeadSourceContext);

  const { setValue } = useFormContext();

  const createLeadSourceMutation = useMutation({
    mutationFn: apiClient.createLeadSource,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["leadSources"] });
      if (res.ok) {
        setValue("lead_source_id", res.data.id);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleCreateSource = (source) => {
    createLeadSourceMutation.mutate({ name: source });
  };

  if (!leadSourcePolicy.viewAny()) return null;

  return leadSourcePolicy.create() ? (
    <FormCreateSelect
      options={sourceOptions}
      isLoading={isLoadingSources}
      name="lead_source_id"
      label="Źródło"
      required={false}
      onCreate={handleCreateSource}
    />
  ) : (
    <FormCombobox options={sourceOptions} isLoading={isLoadingSources} name="lead_source_id" label="Źródło" />
  );
};
