import { AuditStatusProvider } from "@/context/AuditStatusContext";
import { AuditDetailsPage } from "@/pages/Audit/AuditDetailsPage";
import { AuditsPage } from "@/pages/Audit/AuditsPage";
import { routes } from "@/routes";
import { Route } from "react-router-dom";

export const AuditRoutes = () => (
  <>
    <Route
      exact
      path={routes.audits}
      element={
        <AuditStatusProvider>
          <AuditsPage />
        </AuditStatusProvider>
      }
    />
    <Route
      exact
      path={routes.audit}
      element={
        <AuditStatusProvider>
          <AuditDetailsPage />
        </AuditStatusProvider>
      }
    />
  </>
);
