import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCheckbox } from "@/components/forms/FormCheckbox";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormField } from "@/components/forms/FormField";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  amount: Yup.string().required(i18n.t("Pole jest wymagane")),
  description: Yup.string().required(i18n.t("Pole jest wymagane")),
  contract_id: Yup.string().optional().nullable(),
  from_comission: Yup.boolean().required(i18n.t("Pole jest wymagane")),
});

export const BillingCreateForm = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [contractOptions, setContractOptions] = useState([]);

  const defaultValues = {
    amount: "",
    description: "",
    contract_id: undefined,
    from_comission: false,
  };

  const onSubmit = (data) => {
    createBillingMutation.mutate({ data });
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });
  const { contract_id } = form.watch();

  const createBillingMutation = useMutation({
    mutationFn: apiClient.createBilling,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["billings"] });
      if (res.ok) {
        return navigate(routes.billings);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const {
    isLoading,
    data: contracts,
    error,
  } = useQuery({
    queryKey: ["contracts"],
    queryFn: apiClient.getContracts,
    staleTime: 1000 * 60 * 5,
  });

  useEffect(() => {
    if (!isLoading && !error && contracts) {
      setContractOptions(
        contracts.data.map((contract) => ({
          name: `${contract.identifier} - ${contract.name} (${contract.comission} zł prowizji)`,
          value: contract.id,
        })),
      );
    }
  }, [isLoading, error, contracts]);

  return (
    <Card className="mt-5 w-full xl:w-1/2">
      <Form form={form} onSubmit={onSubmit}>
        <CardContent className="pt-5">
          <div className="flex flex-col gap-4">
            <FormField name="amount" label="Kwota" type="number" inputMode="numeric" />
            <FormField name="description" label="Opis" placeholder="Czego dotyczy rozliczenie" />
            <FormCombobox
              name="contract_id"
              label="Umowa (opcjonalnie)"
              required={false}
              options={contractOptions}
              isLoading={isLoading}
            />
            {contract_id && (
              <FormCheckbox
                name="from_comission"
                label="Rozliczenie wlicza się do prowizji"
                description="Zaznacz to pole jeśli to rozliczenie wlicza się do rozliczenia prowizji na umowie"
              />
            )}
          </div>
        </CardContent>
        <CardFooter className="gap-3 items-center border-t px-6 py-4">
          <Button
            type="submit"
            title="Dodaj"
            leftIcon={<Plus size={20} />}
            isLoading={createBillingMutation.isPending}
          />
          <Button
            title="Anuluj"
            type="button"
            leftIcon={<X size={20} />}
            variant="destructive"
            onClick={() => navigate(routes.billings)}
          />
        </CardFooter>
      </Form>
    </Card>
  );
};
