import apiClient from "@/api/apiClient";
import { UserLeadCountStat } from "@/components/Users/UserDetails/Stats/UserLeadCountStat";
import { UserActionsCountStat } from "@/components/Users/UserDetails/Stats/UserActionsCountStat";
import { UserProfitabilityStat } from "@/components/Users/UserDetails/Stats/UserProfitabilityStat";
import { useUserPolicy } from "@/policies/user/useUserPolicy";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export const UserStats = ({ dateRange, id }) => {
  const userPolicy = useUserPolicy();
  const [conversion, setConversion] = useState();
  const [actionsCount, setActionsCount] = useState();
  const [profitability, setProfitability] = useState();

  const {
    isLoading,
    data: response,
    error,
  } = useQuery({
    queryKey: ["user", id, "stats", dateRange],
    queryFn: () => apiClient.getUserStats(id, dateRange),
    staleTime: 1000 * 60 * 5,
    enabled: userPolicy.viewStats(),
  });

  useEffect(() => {
    if (!isLoading && !error && response) {
      setConversion(response.data.conversion_stat);
      setActionsCount(response.data.actions_count_stat);
      setProfitability(response.data.profitability_stat);
    }
  }, [isLoading, response, error]);

  return (
    <div className="w-full flex flex-col gap-5">
      <div className="w-full grid grid-cols-1 lg:grid-cols-4 gap-5">
        <UserLeadCountStat data={conversion} dateRange={dateRange} isLoading={isLoading} />
        <UserActionsCountStat data={actionsCount} dateRange={dateRange} isLoading={isLoading} />
        <UserProfitabilityStat data={profitability} dateRange={dateRange} isLoading={isLoading} />
      </div>
    </div>
  );
};
