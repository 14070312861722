import { EmptyState } from "@/components/EmptyState";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { UserAvatar } from "@/components/Users/UserAvatar";
import { ContractPaymentType } from "@/enums/ContractPaymentTypeEnum";
import { formatMoney } from "@/helpers/formatMoney";
import i18n from "@/i18n";

export const AdminDashboardOverviewLastContracts = ({ data }) => {
  const { last_contracts } = data;

  return (
    <Card className="col-span-3 lg:col-span-2">
      <CardHeader>
        <CardTitle>{i18n.t("Ostatnie umowy")}</CardTitle>
        <CardDescription>{i18n.t("Lista najnowszych dodanych umów")}</CardDescription>
      </CardHeader>
      <CardContent className="grid gap-5">
        {last_contracts.length > 0 ? (
          last_contracts.map((contract) => {
            return (
              <div key={contract.id} className="flex items-center gap-4">
                <UserAvatar user={contract.created_by} />
                <div className="grid gap-1">
                  <p className="text-sm font-medium leading-none">{contract?.created_by?.name ?? i18n.t("Brak")}</p>
                  <Badge className="w-fit hover:bg-primary">{ContractPaymentType[contract.payment_type]?.name}</Badge>
                </div>
                <div className="ml-auto font-medium text-green-500 whitespace-nowrap">
                  {formatMoney(contract.gross_price)}
                </div>
              </div>
            );
          })
        ) : (
          <EmptyState title="Brak umów" />
        )}
      </CardContent>
    </Card>
  );
};
