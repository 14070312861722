import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { CalculationPriceSummaryCardContent } from "@/components/Calculators/CalculationPriceSummaryCardContent";
import { Combobox } from "@/components/forms/Combobox";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { Card } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { ProductsContext } from "@/context/ProductsContext";
import { CalculatorFieldType } from "@/enums/CalculatorFieldTypeEnum";
import { useAuth } from "@/hooks/useAuth";
import i18n from "@/i18n";
import { useClientPolicy } from "@/policies/lead/useClientPolicy";
import { useLeadCalculationPolicy } from "@/policies/lead/useLeadCalculationPolicy";
import { useLeadPolicy } from "@/policies/lead/useLeadPolicy";
import { useProductPolicy } from "@/policies/useProductPolicy";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { ChevronLeft, UserPlus } from "lucide-react";
import { useContext, useEffect, useState } from "react";

export const CalculationSummaryDialog = ({ showPrice, setShowPrice, calculation }) => {
  const { user } = useAuth();
  const leadCalculationPolicy = useLeadCalculationPolicy();
  const leadPolicy = useLeadPolicy();
  const productPolicy = useProductPolicy();
  const clientPolicy = useClientPolicy();
  const queryClient = useQueryClient();
  const { products } = useContext(ProductsContext);

  const [leadClientOptions, setLeadClientOptions] = useState([]);

  let productsWithEmptyQuantity =
    calculation &&
    productPolicy.create() &&
    calculation.sections
      .flatMap((section) => section.fields)
      .filter((field) => field.type === CalculatorFieldType.PRODUCT.value)
      .map((field) => {
        return products.find((product) => product.id === field.value);
      })
      .filter((field) => field?.total_quantity === 0);

  const filters = {
    active: true,
  };

  const {
    isLoading: isLoadingLeads,
    data: leads,
    error: errorLeads,
  } = useQuery({
    queryKey: ["leads", filters],
    queryFn: () => apiClient.getLeads(filters),
    staleTime: 1000 * 60 * 5,
    enabled: showPrice && leadPolicy.viewAny(),
  });

  const {
    isLoading: isLoadingClients,
    data: clients,
    error: errorClients,
  } = useQuery({
    queryKey: ["clients", filters],
    queryFn: () => apiClient.getClients(filters),
    staleTime: 1000 * 60 * 5,
    enabled: showPrice && clientPolicy.viewAny(),
  });

  const createLeadCalculationMutation = useMutation({
    mutationFn: apiClient.createLeadCalculation,
  });

  const createCalculation = (leadId) => {
    const lead = leadClientOptions.find((lead) => lead.value === leadId);
    const { margin, comission } = calculateUserComission(calculation.comission, calculation.marginValue);
    console.log(margin, comission);
    const data = {
      calculator: calculation,
      net_price: calculation.netPrice,
      gross_price: calculation.grossPrice,
      vat: calculation.vat,
      name: `Kalkulacja - ${lead.name} - ${format(new Date(), "dd.MM.yyyy")}`,
      comission,
      margin,
    };
    createLeadCalculationMutation.mutate(
      { leadId, data },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries({ queryKey: ["lead", leadId, "calculations"] });
          queryClient.invalidateQueries({ queryKey: ["lead", leadId], exact: true });
          if (res.ok) {
            toast({ title: <SuccessToast title="Zapisano pomyślnie." /> });
          } else {
            toast({ title: <ErrorToast title="Błąd podczas zapisywania." /> });
          }
        },
      },
    );
  };

  const calculateUserComission = (totalComission, totalMargin) => {
    const userLevel = user.level;
    if (!userLevel) {
      const margin = totalMargin + totalComission;
      const comission = 0;
      return { margin, comission };
    }
    const comission = (totalComission * userLevel.comission_percentage) / 100;
    const margin = totalMargin + totalComission - comission;
    return { margin, comission };
  };

  const handleOpenChange = () => {
    createLeadCalculationMutation.reset();
    setShowPrice(!showPrice);
  };

  useEffect(() => {
    setLeadClientOptions(() => []);
    if (!isLoadingLeads && !errorLeads && leads) {
      setLeadClientOptions((prev) => [...prev, ...leads.data.map((lead) => ({ name: lead.name, value: lead.id }))]);
    }
    if (!isLoadingClients && !errorClients && clients) {
      setLeadClientOptions((prev) => [
        ...prev,
        ...clients.data.map((client) => ({ name: client.name, value: client.id })),
      ]);
    }
  }, [isLoadingLeads, leads, errorLeads, errorClients, clients, isLoadingClients]);

  if (!calculation) return null;

  return (
    <Dialog open={showPrice} onOpenChange={handleOpenChange}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>{i18n.t("Podsumowanie")}</DialogTitle>
          <DialogDescription>
            {i18n.t("Podsumowanie kalkulacji, możesz ją zapisać lub wrócić do kalkulatora.")}
          </DialogDescription>
        </DialogHeader>
        <Card className="w-full border-none mt-5">
          <CalculationPriceSummaryCardContent calculator={calculation} />
          <div className="flex flex-row justify-center items-start w-full gap-3 px-8 mt-5">
            {leadCalculationPolicy.create() && (
              <div className="flex flex-col gap-1 items-center w-1/2">
                <Combobox
                  placeholder="Przypisz do kontaktu"
                  className="w-full gap-2 dark:text-foreground dark:bg-opacity-70 justify-center"
                  variant={createLeadCalculationMutation.isSuccess ? "success" : "default"}
                  leftIcon={<UserPlus size={20} />}
                  options={leadClientOptions}
                  isLoading={isLoadingLeads}
                  setValue={(value) => {
                    createCalculation(value);
                  }}
                />
                {productsWithEmptyQuantity.length > 0 && (
                  <p className="text-destructive text-sm font-semibold text-center">
                    {i18n.t(
                      "Uwaga! Kalkulacja posiada produkty których stan magazynowy wynosi 0 lub są nie dodane do magazynów!",
                    )}
                  </p>
                )}
              </div>
            )}
            <Button
              type="button"
              className="w-1/2 my-0 h-full"
              variant="outline"
              title="Powrót do kalkulatora"
              leftIcon={<ChevronLeft size={20} />}
              isLoading={false}
              onClick={handleOpenChange}
            />
          </div>
        </Card>
        <DialogFooter className="mt-5"></DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
