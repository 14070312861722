import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { DateRangePicker } from "@/components/forms/DateRangePicker";
import { Layout } from "@/components/layout/Layout";
import { LeadsCalendar } from "@/components/Leads/LeadsCalendar";
import { LeadsTable } from "@/components/Leads/LeadsTable";
import { LeadSettings } from "@/components/Leads/Settings/LeadSettings";
import { LeadsStats } from "@/components/Leads/Stats/LeadsStats";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import i18n from "@/i18n";
import { useLeadPolicy } from "@/policies/lead/useLeadPolicy";
import { useLeadSourcePolicy } from "@/policies/lead/useLeadSourcePolicy";
import { useLeadStatusPolicy } from "@/policies/lead/useLeadStatusPolicy";
import { routes } from "@/routes";
import { FileInput, Map, Plus } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const LeadsPage = () => {
  useDocumentTitle("Kontakty");
  const navigate = useNavigate();
  const { state } = useLocation();
  const bottomRef = useRef(null);
  const [activeTab, setActiveTab] = useState("table");
  const [showMap, setShowMap] = useState(false);
  const leadPolicy = useLeadPolicy();
  const leadSourcePolicy = useLeadSourcePolicy();
  const leadStatusPolicy = useLeadStatusPolicy();
  const [dateRange, setDateRange] = useState({
    from: null,
    to: null,
  });

  useEffect(() => {
    if (state?.tab) {
      setActiveTab(state.tab);
    }
  }, [state]);

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {activeTab === "stats" && (
            <DateRangePicker onSelect={setDateRange} selected={dateRange} placeholder="Wybierz zakres" />
          )}
          {activeTab === "table" && leadPolicy.viewMap() && (
            <Button
              title="Pokaż mapę"
              variant="outline"
              leftIcon={<Map size={16} />}
              onClick={() => {
                setShowMap(!showMap);
                if (!showMap)
                  setTimeout(() => {
                    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
                  }, 200);
              }}
            />
          )}
          {activeTab === "table" && leadPolicy.importLeads() && (
            <Button
              title="Import CSV"
              variant="outline"
              leftIcon={<FileInput size={16} />}
              onClick={() => navigate(routes.leadImport)}
            />
          )}
          {activeTab === "table" && leadPolicy.create() && (
            <Button
              title="Dodaj kontakt"
              variant="outline"
              leftIcon={<Plus size={20} />}
              onClick={() => navigate(routes.leadCreate)}
            />
          )}
        </div>
      </div>
      <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value)}>
        {(leadPolicy.viewStats() || leadSourcePolicy.create() || leadStatusPolicy.create()) && (
          <TabsList className="mb-6">
            <TabsTrigger value="table">{i18n.t("Tabela")}</TabsTrigger>
            <TabsTrigger value="calendar">{i18n.t("Kalendarz")}</TabsTrigger>
            {leadPolicy.viewStats() && <TabsTrigger value="stats">{i18n.t("Statystyki")}</TabsTrigger>}
            {(leadStatusPolicy.create() || leadSourcePolicy.create()) && (
              <TabsTrigger value="settings">{i18n.t("Ustawienia")}</TabsTrigger>
            )}
          </TabsList>
        )}
        <TabsContent value="table">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <LeadsTable showMap={showMap} />
          </div>
        </TabsContent>
        <TabsContent value="calendar">
          <LeadsCalendar />
        </TabsContent>
        {leadPolicy.viewStats() && (
          <TabsContent value="stats">
            <div className="flex flex-col lg:flex-row w-full gap-5">
              <LeadsStats dateRange={dateRange} />
            </div>
          </TabsContent>
        )}
        {(leadStatusPolicy.create() || leadSourcePolicy.viewAny()) && (
          <TabsContent value="settings">
            <LeadSettings />
          </TabsContent>
        )}
      </Tabs>
      <div ref={bottomRef}></div>
    </Layout>
  );
};
