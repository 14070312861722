import api from "@/api/api";
import { AuthContext } from "@/context/AuthContext";
import authStorage from "@/helpers/authStorage";
import { routes } from "@/routes";
import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const ApiInterceptors = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setUser } = useContext(AuthContext);

  useEffect(() => {
    api.axiosInstance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error?.response?.status === 401) {
          setUser(null);
          authStorage.removeToken();
          if (location.pathname !== routes.login) {
            navigate(routes.login);
          }
        }
        if (error?.response?.status === 403 || error?.response?.status === 404) {
          navigate(routes.notFound, { replace: true });
        }
        return Promise.reject(error);
      },
    );
  }, []);
  return null;
};
