import { Button } from "@/components/Button";
import { ErrorMessage } from "@/components/forms/ErrorMessage";
import { FormField } from "@/components/forms/FormField";
import { Label } from "@/components/ui/label";
import i18n from "@/i18n";
import { Plus, X } from "lucide-react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

export const FormRangesField = ({ name, label }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const addRange = () => {
    append({ from: "", to: "", price: "" });
  };

  return (
    <div className="flex flex-col gap-4">
      <Label>{label}</Label>
      {fields.map((field, index) => (
        <div key={field.id} className="flex items-end gap-4">
          <Controller
            name={`${name}[${index}].from`}
            control={control}
            defaultValue={field.from}
            render={({ field }) => (
              <FormField
                {...field}
                label={i18n.t("Wartość od (>)")}
                type="number"
                inputMode="numeric"
                placeholder={i18n.t("Wartość od (>)")}
                className="flex-1"
              />
            )}
          />
          <Controller
            name={`${name}[${index}].to`}
            control={control}
            defaultValue={field.to}
            render={({ field }) => (
              <FormField
                {...field}
                label={i18n.t("Wartość do (<=)")}
                type="number"
                inputMode="numeric"
                placeholder={i18n.t("Wartość do (<=)")}
                className="flex-1"
              />
            )}
          />
          <Controller
            name={`${name}[${index}].price`}
            control={control}
            defaultValue={field.price}
            render={({ field }) => (
              <FormField
                {...field}
                label={i18n.t("Dolicz")}
                type="number"
                inputMode="numeric"
                placeholder={i18n.t("Dolicz")}
                className="flex-1"
              />
            )}
          />
          {fields.length > 1 && (
            <Button type="button" variant="destructive" onClick={() => remove(index)} leftIcon={<X size={16} />} />
          )}
        </div>
      ))}
      <Button
        type="button"
        variant="outline"
        title={i18n.t("Dodaj zakres")}
        onClick={addRange}
        leftIcon={<Plus size={16} />}
      />
      <ErrorMessage name={name} />
    </div>
  );
};
