import apiClient from "@/api/apiClient";
import { Combobox } from "@/components/forms/Combobox";
import { CreateSelect } from "@/components/forms/CreateSelect";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { toast } from "@/components/ui/use-toast";
import { FileCategoryContext } from "@/context/FileCategoryContext";
import i18n from "@/i18n";
import { useFileCategoryPolicy } from "@/policies/file/useFileCategoryPolicy";
import { useFilePolicy } from "@/policies/file/useFilePolicy";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";

export const FileCategoryCell = ({ file }) => {
  const [categoryId, setCategoryId] = useState(file?.category?.id);
  const filePolicy = useFilePolicy();
  const fileCategoryPolicy = useFileCategoryPolicy();
  const queryClient = useQueryClient();
  const { categoryOptions, isLoading } = useContext(FileCategoryContext);

  const updateFileMutation = useMutation({
    mutationFn: apiClient.updateFile,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["files"] });
      if (!res.ok) {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const createFileCategoryMutation = useMutation({
    mutationFn: apiClient.createFileCategory,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["fileCategories"] });
      if (res.ok) {
        updateFileMutation.mutate({ fileId: file.id, data: { file_category_id: res.data.id } });
        setCategoryId(res.data.id);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleCreateFileCategory = (category) => {
    createFileCategoryMutation.mutate({ name: category });
  };

  const handleEdit = (data) => {
    updateFileMutation.mutate({ fileId: file.id, data });
  };

  useEffect(() => {
    setCategoryId(file?.category?.id);
  }, [file]);

  return filePolicy.update() ? (
    fileCategoryPolicy.create() ? (
      <CreateSelect
        isLoading={isLoading}
        options={categoryOptions}
        value={categoryId}
        onCreate={handleCreateFileCategory}
        setValue={(categoryId) => handleEdit({ file_category_id: categoryId })}
      />
    ) : (
      <Combobox options={categoryOptions} value={categoryId} isLoading={isLoading} />
    )
  ) : (
    file?.category?.name ?? i18n.t("Brak")
  );
};
