import { Button } from "@/components/Button";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

export function DateRangePicker({ className, selected, onSelect, placeholder, ...rest }) {
  return (
    <div className={cn("grid gap-2")}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn("w-fit justify-start text-left font-normal", !selected && "text-muted-foreground", className)}
            leftIcon={<CalendarIcon className="h-4 w-4" />}
          >
            {selected?.from ? (
              selected.to ? (
                <>
                  {format(selected.from, "dd LLL y")} - {format(selected.to, "dd LLL y")}
                </>
              ) : (
                format(selected.from, "LLL dd, y")
              )
            ) : (
              <span>{i18n.t(placeholder ?? "Wybierz zakres")}</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={selected?.from}
            selected={selected}
            onSelect={onSelect}
            numberOfMonths={2}
            {...rest}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
