import { useAuth } from "@/hooks/useAuth";

export const useDashboardPolicy = () => {
  const { hasPermission } = useAuth();

  const view = () => {
    return hasPermission("access_dashboard");
  };

  const viewOverview = () => {
    return hasPermission("access_dashboard_overview");
  };

  const viewStats = () => {
    return hasPermission("access_dashboard_stats");
  };

  return {
    view,
    viewOverview,
    viewStats,
  };
};
