import { ColorPicker } from "@/components/forms/ColorPicker";
import { useEffect, useState } from "react";

export const EditableColorCell = ({ value, onSave, ...rest }) => {
  const [inputValue, setInputValue] = useState(value);

  const handleChangeColor = () => {
    if (inputValue !== value) {
      onSave(inputValue);
    }
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <ColorPicker
      onChange={(e) => setInputValue(e.target.value)}
      onBlur={handleChangeColor}
      value={inputValue}
      {...rest}
    />
  );
};
