import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { Badge } from "@/components/ui/badge";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { UserAvatarLink } from "@/components/Users/UserAvatarLink";
import { ContractPaymentSource } from "@/enums/ContractPaymentSourceEnum";
import { downloadFile } from "@/helpers/files/downloadFile";
import { getFileExtensionIcon } from "@/helpers/files/getFileExtensionIcon";
import { formatMoney } from "@/helpers/formatMoney";
import i18n from "@/i18n";
import { useContractPaymentPolicy } from "@/policies/contracts/useContractPaymentPolicy";
import { useFilePolicy } from "@/policies/file/useFilePolicy";
import { format, parse } from "date-fns";
import { MoreHorizontal, Trash } from "lucide-react";

export const useContractPaymentsTableColumns = (handleDelete, contract) => {
  const contractPaymentPolicy = useContractPaymentPolicy();
  const filePolicy = useFilePolicy();

  const columns = [
    {
      id: "date",
      name: "Data płatności",
      accessorKey: "date",
      header: <span>{i18n.t("Data płatności")}</span>,
      cell: ({ row }) => (
        <Badge className="text-foreground">
          {format(parse(row.getValue("date"), "yyyy-MM-dd", new Date()), "dd LLL yyyy")}
        </Badge>
      ),
    },
    {
      id: "amount",
      name: "Kwota",
      accessorKey: "amount",
      header: <span>{i18n.t("Kwota")}</span>,
      cell: ({ row }) => <span>{formatMoney(row.getValue("amount"))}</span>,
    },
    {
      id: "source",
      name: "Źródło płatności",
      accessorKey: "source",
      header: <span>{i18n.t("Źródło płatności")}</span>,
      cell: ({ row }) => <span>{ContractPaymentSource[row.getValue("source")].name}</span>,
    },
    {
      id: "description",
      name: "Opis",
      accessorKey: "description",
      header: <span>{i18n.t("Opis")}</span>,
      cell: ({ row }) => <span>{row.getValue("description") ?? i18n.t("Brak")}</span>,
    },
    {
      id: "files",
      name: "Pliki",
      accessorKey: "files",
      header: <span>{i18n.t("Pliki")}</span>,
      cell: ({ row }) => {
        const files = row.original.files;
        return (
          <div className="flex flex-col gap-3">
            {files?.length === 0 && i18n.t("Brak")}
            {files.map((file) => (
              <div key={file.id} className="flex flex-row gap-1">
                <img className="w-6 h-6" src={getFileExtensionIcon(file.extension)} />
                {filePolicy.download() ? (
                  <a
                    className="font-bold flex flex-row gap-1 items-center cursor-pointer hover:underline"
                    onClick={() => downloadFile(file)}
                  >
                    {file.original_name}
                  </a>
                ) : (
                  <span>{file.original_name}</span>
                )}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      id: "created_by",
      name: "Dodana przez",
      accessorKey: "created_by",
      enableSorting: false,
      header: () => <div className="px-4">{i18n.t("Dodana przez")}</div>,
      cell: ({ row }) => {
        const creator = row.original.user;
        return <UserAvatarLink user={creator} />;
      },
    },
    {
      id: "created_at",
      name: "Data utworzenia",
      accessorKey: "created_at",
      header: <span className="text-right"> {i18n.t("Data utworzenia")} </span>,
      cell: ({ row }) => (
        <span className="text-right">
          {format(parse(row.getValue("created_at"), "yyyy-MM-dd HH:mm:ss", new Date()), "dd LLL yyyy HH:mm")}
        </span>
      ),
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const contractPayment = row.original;
        if (!contractPaymentPolicy.destroy(contract)) return null;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {contractPaymentPolicy.destroy(contract) && (
                <DeleteAlertDialog
                  onConfirm={() => handleDelete(contractPayment.id)}
                  message="Czy na pewno chcesz usunąć tę płatność? Tej operacji nie można cofnąć"
                  trigger={
                    <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                      <Trash size={16} className="mr-1 text-primary" />
                      <span className="font-semibold">{i18n.t("Usuń")}</span>
                    </DropdownMenuItem>
                  }
                />
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
