import { ContractApplicationsTab } from "@/components/Contracts/Details/Applications/ContractApplicationsTab";
import { ContractBillingsTable } from "@/components/Contracts/Details/Billings/ContractBillingsTable";
import { ContractChanges } from "@/components/Contracts/Details/Changes/ContractChanges";
import { ContractInformationCard } from "@/components/Contracts/Details/ContractInformationCard";
import { ContractMessagesCard } from "@/components/Contracts/Details/Messages/ContractMessagesCard";
import { ContractNotesCard } from "@/components/Contracts/Details/ContractNotesCard";
import { ContractProcessesCard } from "@/components/Contracts/Details/ContractProcessesCard";
import { ContractUsersCard } from "@/components/Contracts/Details/ContractUsersCard";
import { ContractFilesTable } from "@/components/Contracts/Details/Files/ContractFilesTable";
import { ContractInstallationTab } from "@/components/Contracts/Details/Installation/ContractInstallationTab";
import { ContractOrderTab } from "@/components/Contracts/Details/Order/ContractOrderTab";
import { ContractPaymentInformationCard } from "@/components/Contracts/Details/Payments/ContractPaymentInformationCard";
import { ContractPaymentsTable } from "@/components/Contracts/Details/Payments/ContractPaymentsTable";
import { ContractTasksTable } from "@/components/Contracts/Details/Tasks/ContractTasksTable";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import i18n from "@/i18n";
import { useApplicationPolicy } from "@/policies/application/useApplicationPolicy";
import { useBillingPolicy } from "@/policies/billing/useBillingPolicy";
import { useContractChangePolicy } from "@/policies/contracts/useContractChangePolicy";
import { useContractFilePolicy } from "@/policies/contracts/useContractFilePolicy";
import { useContractMessagePolicy } from "@/policies/contracts/useContractMessagePolicy";
import { useContractNotePolicy } from "@/policies/contracts/useContractNotePolicy";
import { useContractPaymentPolicy } from "@/policies/contracts/useContractPaymentPolicy";
import { useContractPolicy } from "@/policies/contracts/useContractPolicy";
import { useContractUserPolicy } from "@/policies/contracts/useContractUserPolicy";
import { useInstallationPolicy } from "@/policies/installation/useInstallationPolicy";
import { useOrderPolicy } from "@/policies/order/useOrderPolicy";
import { useTaskPolicy } from "@/policies/useTaskPolicy";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const ContractDetails = ({ contract }) => {
  const { state } = useLocation();
  const [activeTab, setActiveTab] = useState("information");
  const contractPolicy = useContractPolicy();
  const billingPolicy = useBillingPolicy();
  const contractFilesPolicy = useContractFilePolicy();
  const contractNotePolicy = useContractNotePolicy();
  const contractMessagePolicy = useContractMessagePolicy();
  const contractPaymentPolicy = useContractPaymentPolicy();
  const contractUserPolicy = useContractUserPolicy();
  const contractChangesPolicy = useContractChangePolicy();
  const installationPolicy = useInstallationPolicy();
  const applicationPolicy = useApplicationPolicy();
  const orderPolicy = useOrderPolicy();
  const taskPolicy = useTaskPolicy();

  useEffect(() => {
    if (state?.tab) {
      setActiveTab(state.tab);
    }
  }, [state]);

  return (
    <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value)}>
      {contractFilesPolicy.viewAny() && (
        <TabsList className="mb-5">
          <TabsTrigger value="information">{i18n.t("Informacje")}</TabsTrigger>
          <TabsTrigger value="files">{i18n.t("Pliki")}</TabsTrigger>
          {contractPaymentPolicy.viewAny() && <TabsTrigger value="payments">{i18n.t("Płatności")}</TabsTrigger>}
          {applicationPolicy.viewAny() && <TabsTrigger value="applications">{i18n.t("Wnioski klienta")}</TabsTrigger>}
          {installationPolicy.viewAny() && <TabsTrigger value="installations">{i18n.t("Montaże")}</TabsTrigger>}
          {orderPolicy.viewAny() && <TabsTrigger value="order">{i18n.t("Zamówienie")}</TabsTrigger>}
          {billingPolicy.viewAny() && <TabsTrigger value="billings">{i18n.t("Rozliczenia")}</TabsTrigger>}
          {taskPolicy.viewAny() && <TabsTrigger value="tasks">{i18n.t("Zadania")}</TabsTrigger>}
          {contractChangesPolicy.viewAny() && <TabsTrigger value="changes">{i18n.t("Historia zmian")}</TabsTrigger>}
        </TabsList>
      )}
      <TabsContent value="information">
        <div className="flex flex-col lg:flex-row w-full gap-5">
          <div className="flex w-1/3 flex-col gap-5 h-fit">
            <ContractInformationCard contract={contract} />
            <ContractPaymentInformationCard contract={contract} />
          </div>
          {(contractUserPolicy.viewAny() || contractNotePolicy.viewAny() || contractMessagePolicy.viewAny()) && (
            <div className="flex w-1/3 flex-col gap-5 h-fit">
              {contractUserPolicy.viewAny() && <ContractUsersCard contract={contract} />}
              {contractMessagePolicy.viewAny() && <ContractMessagesCard />}
              {contractNotePolicy.viewAny() && <ContractNotesCard />}
            </div>
          )}
          {contractPolicy.viewProcesses() && (
            <div className="flex w-1/3 flex-col gap-5 h-fit">
              {contractPolicy.viewProcesses() && <ContractProcessesCard />}
            </div>
          )}
        </div>
      </TabsContent>
      {contractFilesPolicy.viewAny() && (
        <TabsContent value="files">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <ContractFilesTable key="files_list" />
          </div>
        </TabsContent>
      )}
      {contractPaymentPolicy.viewAny() && (
        <TabsContent value="payments">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <ContractPaymentsTable key="payments_list" contract={contract} />
          </div>
        </TabsContent>
      )}
      {applicationPolicy.viewAny() && (
        <TabsContent value="applications">
          <ContractApplicationsTab key="applications" contract={contract} />
        </TabsContent>
      )}
      {installationPolicy.viewAny() && (
        <TabsContent value="installations">
          <ContractInstallationTab key="installations" contract={contract} />
        </TabsContent>
      )}
      {orderPolicy.viewAny() && (
        <TabsContent value="order">
          <ContractOrderTab key="order" contract={contract} />
        </TabsContent>
      )}
      {billingPolicy.viewAny() && (
        <TabsContent value="billings">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <ContractBillingsTable key="billings_list" contract={contract} />
          </div>
        </TabsContent>
      )}
      {taskPolicy.viewAny() && (
        <TabsContent value="tasks">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <ContractTasksTable key="tasks_list" />
          </div>
        </TabsContent>
      )}
      {contractChangesPolicy.viewAny() && (
        <TabsContent value="changes">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <ContractChanges key="changes" />
          </div>
        </TabsContent>
      )}
    </Tabs>
  );
};
