import apiClient from "@/api/apiClient";
import { Table } from "@/components/Table/Table";
import { TasksFilters } from "@/components/Tasks/TasksFilters";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { toast } from "@/components/ui/use-toast";
import { ButtonContext } from "@/context/ButtonContext";
import { useContractTasksTableColumns } from "@/hooks/table/useContractTasksTableColumns";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const ContractTasksTable = () => {
  const { id: contractId } = useParams();
  const queryClient = useQueryClient();
  const { showButton, hideButton } = useContext(ButtonContext);
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([
    {
      id: "due_date",
      desc: false,
    },
  ]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const handleDelete = (taskId) => {
    deleteTaskMutation.mutate(taskId);
  };

  const deleteTaskMutation = useMutation({
    mutationFn: apiClient.deleteTask,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["contract", contractId, "tasks"] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery({
    queryKey: ["contract", contractId, "tasks", columnFilters, sorting, pagination],
    queryFn: () => apiClient.getContractTasks({ contractId, filters: { columnFilters, sorting, pagination } }),
  });

  const { columns } = useContractTasksTableColumns(handleDelete);
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : response.data,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    rowCount: response?.meta?.total ?? response?.data?.length,
    onPaginationChange: setPagination,
    manualPagination: true,
    enableColumnResizing: true,
    onSortingChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: 10 });
      setSorting((old) => (updater instanceof Function ? updater(old) : updater));
    },
    onColumnFiltersChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: 10 });
      setColumnFilters((old) => (updater instanceof Function ? updater(old) : updater));
    },
    state: {
      pagination,
      sorting,
      columnFilters,
    },
  });

  useEffect(() => {
    showButton("addContractTask");

    return () => {
      hideButton("addContractTask");
    };
  }, []);

  return (
    <Table table={table} meta={response?.meta} isFetching={isFetching} isLoading={isLoading} Filters={TasksFilters} />
  );
};
