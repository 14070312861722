import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormSelect } from "@/components/forms/FormSelect";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { ApplicationStatusContext } from "@/context/ApplicationStatusContext";
import { ApplicationTypeContext } from "@/context/ApplicationTypeContext";
import { UsersContext } from "@/context/UsersContext";
import i18n from "@/i18n";
import { useApplicationPolicy } from "@/policies/application/useApplicationPolicy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

export const ApplicationCreateDialog = ({ lead, buttonVariant = "outline" }) => {
  const { statusOptions } = useContext(ApplicationStatusContext);
  const { typeOptions } = useContext(ApplicationTypeContext);
  const { userOptions, isLoading } = useContext(UsersContext);
  const queryClient = useQueryClient();
  const applicationPolicy = useApplicationPolicy();

  const [isOpen, setIsOpen] = useState(false);
  const filters = {
    columnFilters: [{ id: "withoutApplication", value: true }],
  };

  const validationSchema = Yup.object({
    user_id: Yup.string().optional().nullable(),
    lead_id: Yup.string().required(i18n.t("Pole jest wymagane")),
    application_status_id: Yup.string().required(i18n.t("Pole jest wymagane")),
    application_type_id: Yup.string().required(i18n.t("Pole jest wymagane")),
    description: Yup.string().optional().nullable(),
  });

  const onSubmit = (data) => {
    createApplicationMutation.mutate({ data });
  };

  const defaultValues = {
    lead_id: lead.id,
    application_type_id: undefined,
    user_id: undefined,
    application_status_id: statusOptions[0]?.value,
    description: "",
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onSubmit",
  });

  const createApplicationMutation = useMutation({
    mutationFn: apiClient.createApplication,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["applications"] });
      queryClient.invalidateQueries({ queryKey: ["contracts", filters] });
      queryClient.invalidateQueries({ queryKey: ["contract"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Wniosek dodany." /> });
        form.reset();
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  if (!applicationPolicy.create()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <DialogTrigger asChild>
        <Button variant={buttonVariant} title="Dodaj wniosek" leftIcon={<Plus size={20} />} />
      </DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Dodaj wniosek")}</DialogTitle>
          <DialogDescription>{i18n.t("Dodawanie nowego wniosku.")}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormSelect options={typeOptions} name="application_type_id" label="Typ wniosku" />
            {applicationPolicy.viewAll() && (
              <FormCombobox
                label="Użytkownik odpowiedzialny"
                name="user_id"
                options={userOptions}
                isLoading={isLoading}
              />
            )}
            <FormTextarea name="description" label="Opis" />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Dodaj"
              leftIcon={<Plus size={20} />}
              isLoading={createApplicationMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
