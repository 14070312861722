import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { EmptyState } from "@/components/EmptyState";
import { Form } from "@/components/forms/Form";
import { FormDateTimePicker } from "@/components/forms/FormDateTimePicker";
import { FormField } from "@/components/forms/FormField";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { InstallationAction } from "@/components/Installations/InstallationDetails/Actions/InstallationAction";
import { Loader } from "@/components/Loader";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { ActionType } from "@/enums/ActionTypeEnum";
import { groupActionsArchivedUpcoming } from "@/helpers/actions/groupActions";
import { useAuth } from "@/hooks/useAuth";
import i18n from "@/i18n";
import { useInstallationActionPolicy } from "@/policies/installation/useInstallationActionPolicy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { roundToNearestMinutes } from "date-fns";
import { CalendarPlus, Check, X } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  action_at: Yup.date(i18n.t("Pole musi być poprawną datą.")).required(i18n.t("Pole jest wymagane")),
  description: Yup.string().nullable(),
});

export const InstallationActionsCard = () => {
  const { id: installationId } = useParams();
  const { user } = useAuth();
  const installationActionPolicy = useInstallationActionPolicy();
  const [create, setCreate] = useState(false);
  const queryClient = useQueryClient();

  const defaultValues = {
    action_at: roundToNearestMinutes(new Date(), { nearestTo: 15 }),
    description: "",
    name: "",
    type: ActionType.INSTALLATION_EVENT.value,
  };

  const onSubmit = (data) => {
    createInstallationActionMutation.mutate({ installationId, action: data });
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const { isLoading, data: response } = useQuery({
    queryKey: ["installation", installationId, "actions"],
    queryFn: () => apiClient.getInstallationActions(installationId),
  });

  const createInstallationActionMutation = useMutation({
    mutationFn: apiClient.createInstallationAction,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["installation", installationId, "actions"] });
        queryClient.invalidateQueries({ queryKey: ["installations"] });
        queryClient.invalidateQueries({ queryKey: ["user", user.id, "calendar"] });
        setCreate(false);
        form.reset();
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const { upcomingActions, archiveActions } = groupActionsArchivedUpcoming(response ? response.data : []);

  return (
    <Card className="flex-1 h-fit shadow-md pr-2">
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5">
          <CardTitle>{i18n.t("Działania")}</CardTitle>
          <CardDescription>{i18n.t("Działania tego montażu")}</CardDescription>
        </div>
        {installationActionPolicy.create() && (
          <Button
            variant="outline"
            title="Dodaj działanie"
            leftIcon={<CalendarPlus size={20} />}
            onClick={() => setCreate(!create)}
          />
        )}
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-5">
          {create && (
            <Card className="shadow-md border-l-4 border-l-primary">
              <CardHeader>
                <CardTitle>{i18n.t("Nowe działanie")}</CardTitle>
              </CardHeader>
              <Form onSubmit={onSubmit} form={form}>
                <CardContent>
                  <div className="flex flex-col gap-4">
                    <FormField name="name" label="Nazwa działania" />
                    <FormDateTimePicker name="action_at" placeholder="Data działania" label="Data działania" />
                    <FormTextarea name="description" label="Opis działania" placeholder="Opis działania (opcjonalne)" />
                  </div>
                </CardContent>
                <CardFooter className="gap-3 items-center border-t px-6 py-4">
                  <Button
                    type="submit"
                    title="Zapisz"
                    leftIcon={<Check size={20} />}
                    isLoading={createInstallationActionMutation.isPending}
                  />
                  <Button
                    title="Anuluj"
                    type="button"
                    leftIcon={<X size={20} />}
                    variant="destructive"
                    onClick={() => setCreate(false)}
                  />
                </CardFooter>
              </Form>
            </Card>
          )}
          {isLoading && !response ? (
            <Loader />
          ) : (
            <>
              {!create && !response.data.length && <EmptyState title="Brak działań" />}
              {upcomingActions.map((action) => {
                return <InstallationAction key={action.id} action={action} />;
              })}
              {archiveActions.map((action) => {
                return <InstallationAction key={action.id} action={action} />;
              })}
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
};
