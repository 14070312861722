import { ErrorLayout } from "@/components/layout/ErrorLayout";
import * as Sentry from "@sentry/react";
import { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, { extra: errorInfo });
  }

  handleRetry = () => {
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      return (
        (this.props.fallback && this.props.fallback({ onRetry: this.handleRetry })) || (
          <ErrorLayout onRetry={this.handleRetry} />
        )
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
