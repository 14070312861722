import { Button } from "@/components/Button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { Check, ChevronDown, ChevronUp } from "lucide-react";
import { useState } from "react";

export const SearchSelectFilter = ({
  options,
  setValue,
  placeholder = "Wybierz z listy",
  description,
  disabled,
  value,
  leftIcon,
  className,
  isLoading,
  ...rest
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          leftIcon={leftIcon}
          rightIcon={
            open ? (
              <ChevronUp className="h-4 w-4 shrink-0 opacity-50" />
            ) : (
              <ChevronDown className="h-4 w-4 shrink-0 opacity-50" />
            )
          }
          isLoading={isLoading}
          aria-expanded={open}
          disabled={disabled || isLoading}
          className={cn("w-full justify-between", value ? "border border-primary" : "", className)}
          title={value ? options.find((option) => option.value === value)?.name : placeholder}
        />
      </PopoverTrigger>
      {description && (
        <span className="text-sm text-muted-foreground mt-1">
          {typeof description === "string" ? i18n.t(description) : description}
        </span>
      )}
      <PopoverContent className="popover-content-width-same-as-its-trigger p-0">
        <Command
          filter={(value, search) => {
            const item = options.find((item) => item.value === value);
            if (!item) return 0;
            if (item.name.toLowerCase().includes(search.toLowerCase())) return 1;
          }}
          {...rest}
        >
          <CommandInput placeholder={i18n.t("Wyszukaj...")} />
          <CommandEmpty>{i18n.t("Brak wyników.")}</CommandEmpty>
          <CommandGroup>
            <CommandList>
              {options.map((option) => (
                <CommandItem
                  key={option.name}
                  value={option.value}
                  onSelect={(currentValue) => {
                    setValue(currentValue);
                    setOpen(false);
                  }}
                >
                  <Check className={cn("mr-2 h-4 w-4", value === option.value ? "opacity-100" : "opacity-0")} />
                  {option.name}
                </CommandItem>
              ))}
            </CommandList>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
