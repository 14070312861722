import { Button } from "@/components/Button";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { forwardRef, useState } from "react";

const DatePicker = forwardRef(
  ({ name, date = null, setDate = () => {}, placeholder = "Wybierz datę", description = null }, ref) => {
    const [pickedDate, setPickedDate] = useState(date);

    const handlePick = (value) => {
      setPickedDate(value);
      setDate(value);
    };

    return (
      <div>
        <Popover>
          <PopoverTrigger asChild className="mt-0">
            <Button
              variant="outline"
              ref={ref}
              className={cn(
                "w-full pl-3 text-left font-normal justify-between",
                !pickedDate && "text-muted-foreground",
              )}
              title={pickedDate ? format(pickedDate, "PPP") : i18n.t(placeholder)}
              rightIcon={<CalendarIcon className="h-4 w-4 opacity-50" />}
            />
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar name={name} mode="single" selected={pickedDate} onSelect={handlePick} initialFocus />
          </PopoverContent>
        </Popover>
        {description && (
          <span className="text-sm text-muted-foreground mt-1">
            {typeof description === "string" ? i18n.t(description) : description}
          </span>
        )}
      </div>
    );
  },
);

DatePicker.displayName = "DatePicker";

export { DatePicker };
