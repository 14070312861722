import { useAuth } from "@/hooks/useAuth";

export const useOrderPolicy = () => {
  const { hasPermission, hasAnyPermission } = useAuth();

  const viewAny = () => {
    return hasAnyPermission(["access_all_orders", "access_own_orders"]);
  };

  const viewAll = () => {
    return hasPermission("access_all_orders");
  };

  const view = () => {
    return hasAnyPermission(["show_any_order", "show_own_order"]);
  };

  const update = () => {
    return hasPermission("update_order");
  };

  const destroy = () => {
    return hasPermission("delete_order");
  };

  return {
    viewAny,
    viewAll,
    view,
    update,
    destroy,
  };
};
