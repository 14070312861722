import { Breadcrumbs } from "@/components/Breadcrumbs";
import { ContractsTable } from "@/components/Contracts/ContractsTable";
import { ContractSettings } from "@/components/Contracts/Settings/ContractSettings";
import { ContractsStats } from "@/components/Contracts/Stats/ContractsStats";
import { DateRangePicker } from "@/components/forms/DateRangePicker";
import { Layout } from "@/components/layout/Layout";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import i18n from "@/i18n";
import { useContractPolicy } from "@/policies/contracts/useContractPolicy";
import { useContractStatusPolicy } from "@/policies/contracts/useContractStatusPolicy";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const ContractsPage = () => {
  useDocumentTitle("Umowy");
  const { state } = useLocation();
  const [activeTab, setActiveTab] = useState("table");
  const contractPolicy = useContractPolicy();
  const contractStatusPolicy = useContractStatusPolicy();
  const [dateRange, setDateRange] = useState({
    from: null,
    to: null,
  });

  useEffect(() => {
    if (state?.tab) {
      setActiveTab(state.tab);
    }
  }, [state]);

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {activeTab === "stats" && (
            <DateRangePicker onSelect={setDateRange} selected={dateRange} placeholder="Wybierz zakres" />
          )}
        </div>
      </div>
      <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value)}>
        {(contractPolicy.viewStats() || contractStatusPolicy.create()) && (
          <TabsList className="mb-6">
            <TabsTrigger value="table">{i18n.t("Tabela")}</TabsTrigger>
            {contractPolicy.viewStats() && <TabsTrigger value="stats">{i18n.t("Statystyki")}</TabsTrigger>}
            {contractStatusPolicy.update() && <TabsTrigger value="settings">{i18n.t("Ustawienia")}</TabsTrigger>}
          </TabsList>
        )}
        <TabsContent value="table">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <ContractsTable />
          </div>
        </TabsContent>
        {contractPolicy.viewStats() && (
          <TabsContent value="stats">
            <div className="flex flex-col lg:flex-row w-full gap-5">
              <ContractsStats dateRange={dateRange} />
            </div>
          </TabsContent>
        )}
        {contractStatusPolicy.update() && (
          <TabsContent value="settings">
            <ContractSettings />
          </TabsContent>
        )}
      </Tabs>
    </Layout>
  );
};
