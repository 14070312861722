import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Layout } from "@/components/layout/Layout";
import { UserCreateForm } from "@/components/Users/UserCreateForm";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";

export const UserCreatePage = () => {
  useDocumentTitle("Dodawanie użytkownika");
  return (
    <Layout>
      <div className="flex flex-col justify-between items-center sm:flex-row">
        <Breadcrumbs />
      </div>
      <UserCreateForm />
    </Layout>
  );
};
