import { useInstallationPolicy } from "@/policies/installation/useInstallationPolicy";
import { routes } from "@/routes";
import { Link } from "react-router-dom";

export const InstallationPopup = ({ installation }) => {
  const installationPolicy = useInstallationPolicy();

  if (!installation) return null;

  const { address } = installation.contract;

  return (
    <p className="flex flex-col items-start justify-center text-xs gap-2">
      <span className="font-semibold text-sm text-muted-foreground flex items-center">
        {installationPolicy.view() ? (
          <Link to={`${routes.installations}/${installation.id}`}>{installation.name}</Link>
        ) : (
          installation.name
        )}
      </span>
      <span className="flex flex-col">
        <span>{`${address.street_number}, ${address.postal_code} ${address.city}`}</span>
        <span className="capitalize">{address.voivodeship}</span>
      </span>
    </p>
  );
};
