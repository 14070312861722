import { Checkbox } from "@/components/forms/Checkbox";
import { ErrorMessage } from "@/components/forms/ErrorMessage";
import { Controller, useFormContext } from "react-hook-form";

export function FormCheckbox({ name, label, description, setCurrentValue = () => {}, ...rest }) {
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  const handleChange = (value) => {
    setValue(name, value);
    setCurrentValue(value);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <div className="w-full">
          <Checkbox
            {...field}
            label={label}
            isError={errors[name]}
            description={description}
            checked={field.value}
            onCheckedChange={handleChange}
            {...rest}
          />
          <ErrorMessage error={errors[name]?.message} />
        </div>
      )}
    />
  );
}
