import { Label } from "@/components/ui/label";
import { Textarea as ShadTextarea } from "@/components/ui/textarea";
import i18n from "@/i18n";
import { forwardRef } from "react";

const Textarea = forwardRef(({ name, label, description, isError, placeholder = null, ...rest }, ref) => {
  return (
    <div>
      <div className="grid gap-2">
        {label && (
          <Label htmlFor={name} className={isError ? "text-destructive" : ""}>
            {i18n.t(label)}
          </Label>
        )}
        <ShadTextarea
          ref={ref}
          name={name}
          id={name}
          placeholder={placeholder ? i18n.t(placeholder) : i18n.t(label)}
          {...rest}
        />
      </div>
      {description && (
        <span className="text-sm text-muted-foreground mt-1">
          {typeof description === "string" ? i18n.t(description) : description}
        </span>
      )}
    </div>
  );
});

Textarea.displayName = "Textarea";

export { Textarea };
