import { config } from "@/config";
import { useEffect, useRef } from "react";

export const useDocumentTitle = (title, prevailOnUnmount = false) => {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    const appName = config("app.name");
    const fullTitle = title ? `${title} - ${appName}` : appName;
    document.title = fullTitle;
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    [],
  );
};
