import { UserAvatar } from "@/components/Users/UserAvatar";
import { useAuth } from "@/hooks/useAuth";
import { cn } from "@/lib/utils";
import { Camera, Pencil } from "lucide-react";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

export const AvatarUploadInput = ({ onFileSelect, file, className }) => {
  const { user } = useAuth();
  const [filePreview, setFilePreview] = useState(file);

  const onDrop = (newFiles) => {
    const updatedFile = newFiles[0];
    setFilePreview(URL.createObjectURL(updatedFile));
    onFileSelect(updatedFile);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
    multiple: false,
  });

  useEffect(() => {
    return () => {
      if (filePreview) {
        URL.revokeObjectURL(filePreview);
      }
    };
  }, [filePreview]);

  useEffect(() => {
    if (file) {
      setFilePreview(URL.createObjectURL(file));
    } else {
      setFilePreview(file);
    }
  }, [file]);

  return (
    <div className={cn("relative group", className)} {...getRootProps()}>
      <div
        className={cn(
          "rounded-full flex items-center justify-center",
          isDragActive && "bg-background border-2 border-dashed border-muted-foreground w-full h-full aspect-square",
        )}
      >
        {isDragActive ? (
          <Camera className="text-foreground opacity-60" size={48} />
        ) : filePreview ? (
          <img src={filePreview} className="w-full h-full rounded-full cursor-pointer aspect-square" />
        ) : (
          user && <UserAvatar user={user} className="w-full h-full rounded-full cursor-pointer" />
        )}
      </div>
      {!isDragActive && (
        <div className="absolute bottom-[5%] right-[5%] bg-primary shadow-md rounded-full p-[5%] group-hover:cursor-pointer">
          <Pencil className="text-background dark:text-foreground mx-auto" size={20} />
        </div>
      )}
      <input {...getInputProps()} className="hidden" />
    </div>
  );
};
