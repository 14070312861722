import { ErrorMessage } from "@/components/forms/ErrorMessage";
import { Input } from "@/components/forms/Input";
import { Controller, useFormContext } from "react-hook-form";

export const FormField = ({ name, label, description, placeholder = null, ...rest }) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className="w-full">
          <Input
            {...field}
            placeholder={placeholder}
            label={label}
            isError={errors[name]}
            description={description}
            {...rest}
          />
          <ErrorMessage error={errors[name]?.message} />
        </div>
      )}
    />
  );
};
