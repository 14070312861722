import { useAuth } from "@/hooks/useAuth";

export const useMetadataPolicy = () => {
  const { hasPermission } = useAuth();

  const viewAny = () => {
    return hasPermission("access_metadata");
  };

  const create = () => {
    return hasPermission("create_metadata");
  };

  return {
    viewAny,
    create,
  };
};
