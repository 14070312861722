import { Button } from "@/components/Button";
import { UserTooltip } from "@/components/Users/UserTooltip";
import { CardRow } from "@/components/ui/CardRow";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import i18n from "@/i18n";
import { useInstallationCrewPolicy } from "@/policies/installation/useInstallationCrewPolicy";
import { useInstallationPolicy } from "@/policies/installation/useInstallationPolicy";
import { useUserPolicy } from "@/policies/user/useUserPolicy";
import { routes } from "@/routes";
import { format, parse } from "date-fns";
import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";

export const ContractInstallationInformationCard = ({ installation }) => {
  const userPolicy = useUserPolicy();
  const installationCrewPolicy = useInstallationCrewPolicy();
  const installationPolicy = useInstallationPolicy();

  return (
    <Card className="shadow-md">
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5">
          <CardTitle>{installation.name}</CardTitle>
          <CardDescription>{i18n.t("Informacje o montażu")}</CardDescription>
        </div>
        {installationPolicy.view() && (
          <Link to={`${routes.installations}/${installation.id}`}>
            <Button variant="outline" title="Przejdź do montażu" rightIcon={<ArrowRight size={16} />} />
          </Link>
        )}
      </CardHeader>
      <CardContent className="divide-y divide-y-border">
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Nazwa")}</p>
          <p>{installation.name}</p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Termin montażu")}</p>
          <p>
            {installation.date
              ? format(parse(installation.date, "yyyy-MM-dd", new Date()), "dd MMM yyyy")
              : i18n.t("Nie ustalono")}
          </p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Ekipa montażowa")}</p>
          <p>
            {installation.installationCrew ? (
              installationCrewPolicy.view() ? (
                <Link to={`${routes.installationCrews}/${installation.installationCrew.id}`}>
                  <Button variant="link" size="xs" title={installation.installationCrew.name} />
                </Link>
              ) : (
                <p>{installation.installationCrew.name}</p>
              )
            ) : (
              i18n.t("Nie przypisano")
            )}
          </p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Data utworzenia montażu")}</p>
          <p title={installation.created_at}>
            {format(parse(installation.created_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMM yyyy")}
          </p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Status")}</p>
          <Badge className="h-8 rounded-md" style={{ backgroundColor: installation.status.color }}>
            {installation.status.name}
          </Badge>
        </CardRow>
        {userPolicy.viewAny() && (
          <CardRow>
            <p className="text-xs sm:text-base font-medium">{i18n.t("Osoba odpowiedzialna")}</p>
            <UserTooltip user={installation.user} />
          </CardRow>
        )}
        {installation.description && (
          <CardRow className="flex lg:flex-col lg:items-start gap-1">
            <p className="text-xs sm:text-base font-medium">{i18n.t("Opis")}</p>
            <p className="text-muted-foreground">{installation.description}</p>
          </CardRow>
        )}
      </CardContent>
    </Card>
  );
};
