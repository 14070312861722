import { addDays, format, isAfter, isBefore, parse, startOfDay, startOfWeek } from "date-fns";

export const daysOfTheWeek = ["Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota", "Niedziela"];

export const groupActions = (actions) => {
  const today = startOfDay(new Date());
  const tomorrow = startOfDay(addDays(new Date(), 1));
  return actions.reduce(
    (result, action) => {
      const actionDate = parse(action.action_at, "yyyy-MM-dd HH:mm:ss", new Date());
      if (isBefore(actionDate, tomorrow) && isAfter(actionDate, today)) {
        result.todaysActions.push(action);
      } else if (isAfter(actionDate, tomorrow)) {
        result.upcomingActions.push(action);
      } else {
        result.archiveActions.push(action);
      }

      return result;
    },
    { todaysActions: [], upcomingActions: [], archiveActions: [] },
  );
};

export const groupActionsTodayUpcoming = (actions) => {
  const today = startOfDay(new Date());
  const tomorrow = startOfDay(addDays(new Date(), 1));
  return actions.reduce(
    (result, action) => {
      const actionDate = parse(action.action_at, "yyyy-MM-dd HH:mm:ss", new Date());
      if (isBefore(actionDate, tomorrow) && isAfter(actionDate, today)) {
        result.todaysActions.push(action);
      } else if (isAfter(actionDate, tomorrow)) {
        result.upcomingActions.push(action);
      }

      return result;
    },
    { todaysActions: [], upcomingActions: [] },
  );
};

export const groupUpcomingActions = (actions) => {
  // const nextWeek = startOfDay(addDays(startOfWeek(addDays(new Date(), 7)), 1));
  const nextWeek = addDays(startOfWeek(addDays(startOfDay(new Date()), 7)), 1);
  const tomorrow = startOfDay(addDays(new Date(), 1));

  return actions.reduce(
    (result, action) => {
      const actionDate = parse(action.action_at, "yyyy-MM-dd HH:mm:ss", new Date());
      if (isBefore(actionDate, nextWeek) && isAfter(actionDate, tomorrow)) {
        result.thisWeek.push(action);
      } else if (isAfter(actionDate, nextWeek)) {
        result.later.push(action);
      }

      return result;
    },
    { thisWeek: [], later: [] },
  );
};

export const groupActionsByDay = (actions) => {
  const actionsByDay = {};

  daysOfTheWeek.forEach((day) => {
    const lowerCaseDay = day.toLowerCase();
    actionsByDay[lowerCaseDay] = actions.filter((action) => {
      const actionDay = format(parse(action.action_at, "yyyy-MM-dd HH:mm:ss", new Date()), "EEEE").toLowerCase();
      return actionDay === lowerCaseDay;
    });
  });

  return actionsByDay;
};

export const groupActionsArchivedUpcoming = (actions) => {
  const today = startOfDay(new Date());
  return actions.reduce(
    (result, action) => {
      const actionDate = parse(action.action_at, "yyyy-MM-dd HH:mm:ss", new Date());
      if (isBefore(actionDate, today)) {
        result.archiveActions.push(action);
      } else {
        result.upcomingActions.push(action);
      }

      return result;
    },
    { upcomingActions: [], archiveActions: [] },
  );
};
