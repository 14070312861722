import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { EditableCell } from "@/components/Table/EditableCell";
import { EditableSelectCell } from "@/components/Table/EditableSelectCell";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { UserAvatarLink } from "@/components/Users/UserAvatarLink";
import { BillingStatus } from "@/enums/BillingStatusEnum";
import { formatMoney } from "@/helpers/formatMoney";
import { useAuth } from "@/hooks/useAuth";
import i18n from "@/i18n";
import { useBillingPolicy } from "@/policies/billing/useBillingPolicy";
import { useContractPolicy } from "@/policies/contracts/useContractPolicy";
import { routes } from "@/routes";
import { format, parse } from "date-fns";
import { MoreHorizontal, Trash } from "lucide-react";
import { Link } from "react-router-dom";

export const useBillingsTableColumns = (handleDelete, handleEdit) => {
  const billingPolicy = useBillingPolicy();
  const contractPolicy = useContractPolicy();
  const { hasPermission, user: authUser } = useAuth();

  const columns = [
    {
      id: "status",
      name: "Status",
      accessorKey: "status",
      header: <span>{i18n.t("Status")}</span>,
      cell: ({ row }) => {
        const billing = row.original;
        const handleChangeStatus = (value) => {
          if (value !== billing.status) handleEdit(billing.id, { status: value });
        };
        return hasPermission("update_any_billing") && !billing.billing_group ? (
          <div className="w-full">
            <EditableSelectCell
              className={BillingStatus[billing.status].color}
              onSave={handleChangeStatus}
              options={BillingStatus.getForEdit(hasPermission)}
              value={billing.status}
            />
          </div>
        ) : (
          <span className="flex flex-row gap-1 items-center font-medium">
            {BillingStatus[billing.status].icon()}
            {BillingStatus[billing.status].name}
          </span>
        );
      },
    },
    {
      id: "contract",
      name: "Umowa",
      accessorKey: "contract",
      header: <span>{i18n.t("Umowa")}</span>,
      cell: ({ row }) => {
        const contract = row.original?.contract;
        if (contract) {
          if (contractPolicy.view()) {
            return (
              <Link
                className="flex flex-row gap-1 hover:underline whitespace-nowrap"
                to={`${routes.contracts}/${contract?.id}`}
              >
                <span>{i18n.t("Umowa")}</span>
                <span className="font-semibold">{contract?.identifier}</span>
              </Link>
            );
          } else {
            return (
              <>
                <span>{i18n.t("Umowa")}</span>
                <span className="font-semibold">{contract?.identifier}</span>
              </>
            );
          }
        } else {
          return i18n.t("Brak");
        }
      },
    },
    {
      id: "amount",
      name: "Kwota",
      accessorKey: "amount",
      header: <span>{i18n.t("Kwota")}</span>,
      cell: ({ row }) => {
        const billing = row.original;
        const handleChangeAmount = (value) => {
          if (value !== billing.status) handleEdit(billing.id, { amount: value });
        };
        return billing.user.id === authUser.id && billing.status === BillingStatus.NEEDS_CORRECTION.value ? (
          <div className="w-full">
            <EditableCell formatter={(val) => formatMoney(val)} onSave={handleChangeAmount} value={billing.amount} />
          </div>
        ) : (
          <span className="flex flex-row gap-1 items-center">{formatMoney(billing.amount)}</span>
        );
      },
    },
    {
      id: "description",
      name: "Opis",
      accessorKey: "description",
      header: <span>{i18n.t("Opis")}</span>,
      cell: ({ row }) => row.getValue("description") ?? i18n.t("Brak"),
    },
    hasPermission("access_all_billings")
      ? {
          id: "user_id",
          name: "Użytkownik",
          accessorKey: "user_id",
          enableSorting: false,
          header: () => <div className="px-4">{i18n.t("Użytkownik")}</div>,
          cell: ({ row }) => {
            const user = row.original.user;
            return <UserAvatarLink user={user} />;
          },
        }
      : { id: "user_id" },
    {
      id: "created_at",
      name: "Data utworzenia",
      accessorKey: "created_at",
      header: <span className="text-right"> {i18n.t("Data utworzenia")} </span>,
      cell: ({ row }) => (
        <span className="text-right">
          {format(parse(row.getValue("created_at"), "yyyy-MM-dd HH:mm:ss", new Date()), "dd LLL yyyy HH:mm")}
        </span>
      ),
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const billing = row.original;
        if (!billingPolicy.destroy(billing)) return null;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {billingPolicy.destroy(billing) && (
                <DeleteAlertDialog
                  onConfirm={() => handleDelete(billing.id)}
                  message="Czy na pewno chcesz usunąć to rozliczenie? Tej operacji nie można cofnąć"
                  trigger={
                    <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                      <Trash size={16} className="mr-1 text-primary" />
                      <span className="font-semibold">{i18n.t("Usuń")}</span>
                    </DropdownMenuItem>
                  }
                />
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
