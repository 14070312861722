import ArchiveIcon from "@/assets/icons/archive.svg";
import DocumentIcon from "@/assets/icons/document.svg";
import FileIcon from "@/assets/icons/file.svg";
import ImageIcon from "@/assets/icons/image.svg";
import PdfIcon from "@/assets/icons/pdf.svg";
import PresentationIcon from "@/assets/icons/presentation.svg";
import SpreadsheetIcon from "@/assets/icons/spreadsheet.svg";
import TextIcon from "@/assets/icons/text.svg";

export const getFileExtensionIcon = (extension) => {
  switch (extension) {
    case "pdf":
      return PdfIcon;
    case "xlsx":
    case "ods":
    case "xls":
    case "csv":
      return SpreadsheetIcon;
    case "doc":
    case "odt":
    case "docx":
      return DocumentIcon;
    case "rar":
    case "7z":
    case "zip":
      return ArchiveIcon;
    case "ppt":
    case "pptx":
    case "odp":
      return PresentationIcon;
    case "txt":
      return TextIcon;
    case "png":
    case "jpg":
    case "jpeg":
    case "heic":
    case "webp":
      return ImageIcon;
    default:
      return FileIcon;
  }
};
