import { useAuth } from "@/hooks/useAuth";

export const useAuditPolicy = () => {
  const { hasPermission, hasAnyPermission, user } = useAuth();

  const viewAny = () => {
    return hasPermission("access_own_audits");
  };

  const viewAll = () => {
    return hasPermission("access_all_audits");
  };

  const view = () => {
    return hasAnyPermission(["show_any_audit", "show_own_audit"]);
  };

  const create = () => {
    return hasPermission("create_audit");
  };

  const update = () => {
    return hasAnyPermission(["update_any_audit", "update_own_audit"]);
  };

  const updateStatus = () => {
    return hasPermission("update_audits_status");
  };

  const destroy = (audit) => {
    if (hasPermission("delete_any_audit")) {
      return true;
    }
    if (hasPermission("delete_own_audit")) {
      return user?.id === audit?.user?.id;
    }
  };

  const viewProcesses = () => {
    return hasPermission("access_audit_processes");
  };

  const updateProcess = () => {
    return hasPermission("update_audit_process");
  };

  const bulkEditStatus = () => {
    return hasPermission("bulk_edit_audit_status");
  };

  const assignUserAudits = () => {
    return hasPermission("assign_user_audits");
  };

  return {
    viewAny,
    viewAll,
    view,
    create,
    update,
    updateStatus,
    destroy,
    viewProcesses,
    updateProcess,
    bulkEditStatus,
    assignUserAudits,
  };
};
