import { Progress as ShadProgress } from "@/components/ui/progress";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { formatMoney } from "@/helpers/formatMoney";
import { useEffect, useState } from "react";

export function Progress({ total, current, delayAnimation = 500 }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const percentage = (current / total) * 100;
    const timer = setTimeout(() => setProgress(percentage), delayAnimation);
    return () => clearTimeout(timer);
  }, [total, current]);

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <ShadProgress value={progress} className="w-full" />
        </TooltipTrigger>
        {current > 0 && <TooltipContent>{formatMoney(current)}</TooltipContent>}
      </Tooltip>
    </TooltipProvider>
  );
}
