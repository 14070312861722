import apiClient from "@/api/apiClient";
import { AuthContext } from "@/context/AuthContext";
import { PermissionContext } from "@/context/PermissionContext";
import authStorage from "@/helpers/authStorage";
import { usePermissions } from "@/hooks/usePermissions";
import { routes } from "@/routes";
import * as Sentry from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

export const useAuth = () => {
  const { user, setUser } = useContext(AuthContext);
  const { permissions, setPermissions } = useContext(PermissionContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { fetchPermissions } = usePermissions();

  const logOut = async () => {
    const response = await apiClient.logout();
    if (response.ok) {
      setUser(null);
      setPermissions([]);
      queryClient.clear();
      authStorage.removeToken();
      localStorage.removeItem("new_leads_count");
      localStorage.removeItem("expired_leads_count");
      localStorage.removeItem("filters");
      return navigate(routes.login);
    }
  };

  const logIn = async (auth) => {
    setUser(auth.user);
    Sentry.setUser({
      id: auth.user.id,
      email: auth.user.email,
      username: auth.user.name,
    });
    authStorage.storeToken(auth.token);
    await fetchPermissions();
    queryClient.clear();
  };

  const updateUser = (user) => {
    setUser(user);
    Sentry.setUser({
      id: user.id,
      email: user.email,
      username: user.name,
    });
  };

  const hasPermission = (permissionName) => {
    return permissions.includes(permissionName);
  };

  const hasAnyPermission = (permissionNames) => {
    return permissionNames.some((permissionName) => permissions.includes(permissionName));
  };

  const hasAllPermissions = (permissionNames) => {
    return permissionNames.every((permissionName) => permissions.includes(permissionName));
  };

  const hasRole = (roles) => {
    if (Array.isArray(roles)) {
      return roles.some((role) => user.roles.map(({ name }) => name).includes(role));
    } else if (typeof roles === "string") return user.roles.map(({ name }) => name).includes(roles);
    else if (typeof roles === "object") return user.roles.map(({ name }) => name).includes(roles.value);
  };

  const userHasRole = (userToCheck, roles) => {
    if (Array.isArray(roles)) {
      return roles.some((role) => userToCheck.roles.map(({ name }) => name).includes(role));
    } else if (typeof roles === "string") return userToCheck.roles.map(({ name }) => name).includes(roles);
    else if (typeof roles === "object") return userToCheck.roles.map(({ name }) => name).includes(roles.value);
  };

  return { user, logOut, logIn, updateUser, hasPermission, hasRole, userHasRole, hasAllPermissions, hasAnyPermission };
};
