import { ProductCreatePage } from "@/pages/Product/ProductCreatePage";
import { ProductDetailsPage } from "@/pages/Product/ProductDetailsPage";
import { ProductEditPage } from "@/pages/Product/ProductEditPage";
import { ProductsPage } from "@/pages/Product/ProductsPage";
import { routes } from "@/routes";
import { Route } from "react-router-dom";

export const ProductRoutes = () => (
  <>
    <Route exact path={routes.products} element={<ProductsPage />} />
    <Route exact path={routes.productCreate} element={<ProductCreatePage />} />
    <Route exact path={routes.productEdit} element={<ProductEditPage />} />
    <Route exact path={routes.product} element={<ProductDetailsPage />} />
  </>
);
