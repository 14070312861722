import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { Form } from "@/components/forms/Form";
import { FormField } from "@/components/forms/FormField";
import { FormSelect } from "@/components/forms/FormSelect";
import { ProcessStep } from "@/components/Process/ProcessStep";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { toast } from "@/components/ui/use-toast";
import { ApplicationStatusContext } from "@/context/ApplicationStatusContext";
import { ApplicationTypeContext } from "@/context/ApplicationTypeContext";
import { AuditStatusContext } from "@/context/AuditStatusContext";
import { ContractStatusContext } from "@/context/ContractStatusContext";
import { InstallationStatusContext } from "@/context/InstallationStatusContext";
import { LeadStatusContext } from "@/context/LeadStatusContext";
import { ContractPaymentType } from "@/enums/ContractPaymentTypeEnum";
import { appendEmptyOption } from "@/helpers/appendEmptyOption";
import i18n from "@/i18n";
import { useProcessPolicy } from "@/policies/useProcessPolicy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Check, Pencil, Plus, Trash, X } from "lucide-react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string().required(i18n.t("Pole jest wymagane")),
  status_change: Yup.string().optional().nullable(),
  parent_id: Yup.string().optional().nullable(),
});

export const ProcessListItem = ({ process }) => {
  const queryClient = useQueryClient();
  const processPolicy = useProcessPolicy();
  const { types } = useContext(ApplicationTypeContext);
  const { statusOptions: leadStatusOptions, isLoading: isLoadingLeadStatuses } = useContext(LeadStatusContext);
  const { statusOptions: contractStatusOptions, isLoading: isLoadingContractStatuses } =
    useContext(ContractStatusContext);
  const { statusOptions: installationStatusOptions, isLoading: isLoadingInstallationStatuses } =
    useContext(InstallationStatusContext);
  const { statusOptions: auditStatusOptions, isLoading: isLoadingAuditStatuses } = useContext(AuditStatusContext);
  const { statusOptions: applicationStatusOptions, isLoading: isLoadingApplicationStatuses } =
    useContext(ApplicationStatusContext);

  const [createStep, setCreateStep] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState(process.name);

  const defaultValues = {
    name: undefined,
    status_change: undefined,
  };

  const form = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });
  const watch = form.watch();

  const createProcessStepMutation = useMutation({
    mutationFn: apiClient.createProcessStep,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["processes"] });
        form.reset();
        setCreateStep(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const updateProcessMutation = useMutation({
    mutationFn: apiClient.updateProcess,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["processes"] });
        setEditMode(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const deleteProcessMutation = useMutation({
    mutationFn: apiClient.deleteProcess,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["processes"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Proces został usunięty." /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const handleDeleteProcess = () => {
    deleteProcessMutation.mutate(process.id);
  };

  const onSubmit = (data) => {
    createProcessStepMutation.mutate({ processId: process.id, data });
  };

  const handleEditProcess = () => {
    if (name !== process.name) updateProcessMutation.mutate({ processId: process.id, data: { name } });
    else setEditMode(false);
  };

  return (
    <Card className="w-full h-fit shadow-md" key={process.id}>
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5">
          {process.applies_to === "Contract" && (
            <CardDescription>
              <Badge>{ContractPaymentType[process.contract_type]?.name}</Badge>
            </CardDescription>
          )}
          {process.applies_to === "Application" && (
            <CardDescription>
              <Badge>{types.find((type) => type.id === process.application_type)?.name}</Badge>
            </CardDescription>
          )}
          <div className="flex flex-row gap-1 items-center">
            <CardTitle>
              {editMode ? <Input value={name} onChange={(e) => setName(e.target.value)} /> : process.name}
            </CardTitle>
            {!editMode && (
              <Button
                variant="link"
                title="Edytuj"
                leftIcon={<Pencil size={16} />}
                onClick={() => setEditMode(!editMode)}
              />
            )}
            {editMode && (
              <div className="flex flex-row items-center">
                <Button
                  variant="link"
                  title="Zapisz"
                  isLoading={updateProcessMutation.isPending}
                  leftIcon={<Check size={16} />}
                  onClick={handleEditProcess}
                />
                <Button
                  variant="link"
                  className="text-destructive"
                  title="Anuluj"
                  leftIcon={<Trash size={16} />}
                  onClick={() => setEditMode(!editMode)}
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-row gap-3 items-center">
          {!createStep && processPolicy.update() && (
            <Button
              variant="outline"
              title="Dodaj krok"
              leftIcon={<Plus size={16} />}
              className="w-fit"
              onClick={() => setCreateStep(!createStep)}
            />
          )}
          {processPolicy.destroy() && (
            <DeleteAlertDialog
              message="Czy na pewno chcesz usunąć ten proces? Tej operacji nie można cofnąć"
              onConfirm={handleDeleteProcess}
              trigger={
                <Button
                  variant="destructive"
                  title="Usuń proces"
                  leftIcon={<Trash size={16} />}
                  isLoading={deleteProcessMutation.isPending}
                />
              }
            />
          )}
        </div>
      </CardHeader>
      <CardContent className="flex flex-col gap-2">
        {process?.steps.map((step, stepIndex) => (
          <ProcessStep key={step.id} process={process} step={step} index={stepIndex} />
        ))}
        {createStep && (
          <Form form={form} onSubmit={onSubmit} className="flex flex-col gap-3">
            {process.steps.length + 1}. {watch.name}
            <FormField name="name" autoComplete="off" label="Nazwa" placeholder="Nazwa kroku" />
            {process.applies_to === "Lead" && (
              <FormSelect
                options={appendEmptyOption(leadStatusOptions)}
                label="Zmiana statusu"
                name="status_change"
                isLoading={isLoadingLeadStatuses}
              />
            )}
            {process.applies_to === "Application" && (
              <FormSelect
                options={appendEmptyOption(applicationStatusOptions)}
                label="Zmiana statusu"
                name="status_change"
                isLoading={isLoadingApplicationStatuses}
              />
            )}
            {process.applies_to === "Audit" && (
              <FormSelect
                options={appendEmptyOption(auditStatusOptions)}
                label="Zmiana statusu"
                name="status_change"
                isLoading={isLoadingAuditStatuses}
              />
            )}
            {process.applies_to === "Installation" && (
              <FormSelect
                options={appendEmptyOption(installationStatusOptions)}
                label="Zmiana statusu"
                name="status_change"
                isLoading={isLoadingInstallationStatuses}
              />
            )}
            {process.applies_to === "Contract" && (
              <FormSelect
                options={appendEmptyOption(contractStatusOptions)}
                label="Zmiana statusu"
                name="status_change"
                isLoading={isLoadingContractStatuses}
              />
            )}
            <div className="flex flex-row gap-3 items-center">
              <Button leftIcon={<Plus size={16} />} title="Dodaj" type="submit" />
              <Button
                type="button"
                variant="destructive"
                leftIcon={<X size={16} />}
                title="Anuluj"
                onClick={() => setCreateStep(false)}
              />
            </div>
          </Form>
        )}
      </CardContent>
    </Card>
  );
};
