import { AuditActionsCard } from "@/components/Audits/AuditDetails/AuditActionsCard";
import { AuditInformationCard } from "@/components/Audits/AuditDetails/AuditInformationCard";
import { AuditNotesCard } from "@/components/Audits/AuditDetails/AuditNotesCard";
import { AuditProcessesCard } from "@/components/Audits/AuditDetails/AuditProcessesCard";
import { AuditFilesTable } from "@/components/Audits/AuditDetails/Files/AuditFilesTable";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import i18n from "@/i18n";
import { useAuditActionPolicy } from "@/policies/audit/useAuditActionPolicy";
import { useAuditFilePolicy } from "@/policies/audit/useAuditFilePolicy";
import { useAuditNotePolicy } from "@/policies/audit/useAuditNotePolicy";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const AuditDetails = ({ audit }) => {
  const { state } = useLocation();
  const [activeTab, setActiveTab] = useState("information");
  const auditNotePolicy = useAuditNotePolicy();
  const auditFilePolicy = useAuditFilePolicy();
  const auditActionPolicy = useAuditActionPolicy();

  useEffect(() => {
    if (state?.tab) {
      setActiveTab(state.tab);
    }
  }, [state]);

  return (
    <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value)}>
      {auditFilePolicy.viewAny() && (
        <TabsList className="mb-6">
          <TabsTrigger value="information">{i18n.t("Informacje")}</TabsTrigger>
          {auditFilePolicy.viewAny() && <TabsTrigger value="files">{i18n.t("Pliki")}</TabsTrigger>}
        </TabsList>
      )}
      <TabsContent value="information">
        <div className="flex flex-col lg:flex-row w-full gap-5">
          <AuditInformationCard audit={audit} />
          <div className="flex flex-1 flex-col gap-5 h-fit">
            {auditNotePolicy.viewAny() && <AuditNotesCard />}
            {auditActionPolicy.viewAny() && <AuditActionsCard />}
          </div>
          <div className="flex flex-1 flex-col gap-5 h-fit">
            <AuditProcessesCard />
          </div>
        </div>
      </TabsContent>
      {auditFilePolicy.viewAny() && (
        <TabsContent value="files">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <AuditFilesTable key="files_list" />
          </div>
        </TabsContent>
      )}
    </Tabs>
  );
};
