import apiClient from "@/api/apiClient";
import { Progress } from "@/components/Progress";
import { Table } from "@/components/Table/Table";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { formatMoney } from "@/helpers/formatMoney";
import { useContractBillingsTableColumns } from "@/hooks/table/useContractBillingsTableColumns";
import i18n from "@/i18n";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

export const ContractBillingsTable = ({ contract }) => {
  const queryClient = useQueryClient();
  const { id: contractId } = useParams();

  const deleteBillingMutation = useMutation({
    mutationFn: apiClient.deleteBilling,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["contract", contractId, "billings"] });
      if (!res.ok) {
        toast({ title: <ErrorToast title="Błąd podczas usuwania." /> });
      }
    },
  });

  const updateBillingMutation = useMutation({
    mutationFn: apiClient.updateBilling,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["contract", contractId, "billings"] });
      if (!res.ok) {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleDelete = async (billingId) => {
    deleteBillingMutation.mutate({ billingId });
  };

  const handleEdit = async (billingId, data) => {
    updateBillingMutation.mutate({ billingId, data });
  };

  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery({
    queryKey: ["contract", contractId, "billings"],
    queryFn: () => apiClient.getContractBillings(contractId),
  });

  const { columns } = useContractBillingsTableColumns(handleDelete, handleEdit);
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : response.data,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    rowCount: response?.meta?.total ?? response?.data?.length,
  });

  const billingsSum = response?.data
    ?.filter((billing) => billing.from_comission)
    .reduce((acc, curr) => acc + curr.amount, 0);

  return (
    <div className="w-full flex flex-col gap-5">
      <Card className="bg-background">
        <CardHeader>
          <CardTitle>{i18n.t("Postęp rozliczenia prowizji")}</CardTitle>
          <CardDescription>
            {billingsSum && formatMoney(billingsSum)} / {contract && formatMoney(contract.comission)}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Progress current={parseFloat(billingsSum)} total={contract.comission} />
        </CardContent>
      </Card>
      <div>
        <h2 className="font-semibold text-muted-foreground mb-3">{i18n.t("Wszystkie rozliczenia umowy")}</h2>
        <Table table={table} meta={response?.meta} isFetching={isFetching} isLoading={isLoading} />
      </div>
    </div>
  );
};
