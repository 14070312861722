export const evaluateCondition = (fieldValue, condition) => {
  const { value, operator } = condition;
  switch (operator) {
    case "=":
      return fieldValue == value;
    case "!=":
      return fieldValue != value;
    case "<":
      return fieldValue < value;
    case "<=":
      return fieldValue <= value;
    case ">":
      return fieldValue > value;
    case ">=":
      return fieldValue >= value;
    default:
      return false;
  }
};
