import apiClient from "@/api/apiClient";
import { ClearFiltersButton } from "@/components/Filters/ClearFiltersButton";
import { ColumnFilter } from "@/components/Filters/ColumnFilter";
import { MultipleSelectFilter } from "@/components/Filters/MultipleSelectFilter";
import { SearchFilter } from "@/components/Filters/SearchFilter";
import { UserRole } from "@/enums/UserRoleEnum";
import { appendEmptyOption } from "@/helpers/appendEmptyOption";
import { useFilters } from "@/hooks/table/useFilters";
import { useAuth } from "@/hooks/useAuth";
import { useDepartmentPolicy } from "@/policies/useDepartmentPolicy";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export const UserFilters = ({ table }) => {
  const { hasPermission } = useAuth();
  const departmentPolicy = useDepartmentPolicy();
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const { filter, setFilter } = useFilters(table);

  const {
    isLoading,
    data: departments,
    error,
  } = useQuery({
    queryKey: ["departments"],
    queryFn: apiClient.getDepartments,
    staleTime: 1000 * 60 * 5,
    enabled: departmentPolicy.viewAny(),
  });

  useEffect(() => {
    if (!isLoading && !error && departments) {
      setDepartmentOptions(departments.data.map((department) => ({ name: department.name, value: department.id })));
    }
  }, [isLoading, error, departments]);

  return (
    <div className="flex flex-row flex-wrap gap-3 mb-3">
      <div className="flex flex-row gap-3 w-full lg:max-w-sm">
        <ClearFiltersButton table={table} />
        <SearchFilter value={filter("search")} onChange={(value) => setFilter("search", value)} />
      </div>
      <MultipleSelectFilter
        options={UserRole.getByPermission(hasPermission)}
        setValue={(value) => setFilter("role", value)}
        title="Rola"
        value={filter("role")}
      />
      {departmentPolicy.viewAny() && (
        <MultipleSelectFilter
          options={appendEmptyOption(departmentOptions)}
          isLoading={isLoading}
          setValue={(value) => setFilter("department", value)}
          title="Oddział"
          value={filter("department")}
        />
      )}
      <ColumnFilter table={table} />
    </div>
  );
};
