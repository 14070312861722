import { ApplicationStatusCreateDialog } from "@/components/Applications/Settings/Statuses/ApplicationStatusCreateDialog";
import { ApplicationStatuses } from "@/components/Applications/Settings/Statuses/ApplicationStatuses";
import { ApplicationTypeCreateDialog } from "@/components/Applications/Settings/Types/ApplicationTypeCreateDialog";
import { ApplicationTypes } from "@/components/Applications/Settings/Types/ApplicationTypes";
import { ProcessCreateDialog } from "@/components/Process/ProcessCreateDialog";
import { ProcessSettings } from "@/components/Process/ProcessSettings";
import i18n from "@/i18n";
import { useApplicationStatusPolicy } from "@/policies/application/useApplicationStatusPolicy";
import { useApplicationTypePolicy } from "@/policies/application/useApplicationTypePolicy";
import { useProcessPolicy } from "@/policies/useProcessPolicy";

export const ApplicationSettings = () => {
  const applicationStatusPolicy = useApplicationStatusPolicy();
  const applicationTypePolicy = useApplicationTypePolicy();
  const processPolicy = useProcessPolicy();

  return (
    <div className="flex flex-col gap-5 w-full">
      {applicationStatusPolicy.update() && (
        <div>
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Statusy")}</h2>
            <ApplicationStatusCreateDialog />
          </div>
          <ApplicationStatuses />
        </div>
      )}
      {applicationTypePolicy.update() && (
        <div>
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Typy wniosków")}</h2>
            <ApplicationTypeCreateDialog />
          </div>
          <ApplicationTypes />
        </div>
      )}
      {processPolicy.create() && (
        <div>
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Procesy")}</h2>
            <ProcessCreateDialog appliesTo="Application" />
          </div>
          <ProcessSettings model="Application" />
        </div>
      )}
    </div>
  );
};
