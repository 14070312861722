import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { EditableCell } from "@/components/Table/EditableCell";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import i18n from "@/i18n";
import { useFileCategoryPolicy } from "@/policies/file/useFileCategoryPolicy";
import { ArrowDown, ArrowUp, ArrowUpDown, MoreHorizontal, Trash } from "lucide-react";

export const useFileCategoriesTableColumns = (handleEdit, handleDelete) => {
  const fileCategoryPolicy = useFileCategoryPolicy();
  const columns = [
    {
      id: "name",
      name: "Nazwa kategorii",
      size: 10,
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Nazwa kategorii")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        const fileCategory = row.original;
        const onSave = (name) => {
          handleEdit(fileCategory.id, { name });
        };

        if (fileCategoryPolicy.update(fileCategory)) {
          return <EditableCell value={fileCategory.name} onSave={onSave} />;
        } else {
          return (
            <div className="flex-1 ms-3 whitespace-nowrap overflow-hidden text-ellipsis" title={row.original.name}>
              {row.getValue("name")}
            </div>
          );
        }
      },
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const fileCategory = row.original;
        if (!fileCategoryPolicy.destroy(fileCategory)) return null;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {fileCategoryPolicy.destroy(fileCategory) && (
                <DeleteAlertDialog
                  onConfirm={() => handleDelete(fileCategory.id)}
                  message="Czy na pewno chcesz usunąć tą kategorie? Tej operacji nie można cofnąć"
                  trigger={
                    <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                      <Trash size={16} className="mr-1 text-primary" />
                      <span className="font-semibold">{i18n.t("Usuń")}</span>
                    </DropdownMenuItem>
                  }
                />
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
