import { useEffect, useRef } from "react";

export const ClickAwayListener = ({ children, onClickAway, ...rest }) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClickAway(event);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClickAway]);

  return (
    <div {...rest} ref={wrapperRef}>
      {children}
    </div>
  );
};
