import apiClient from "@/api/apiClient";
import { useAuth } from "@/hooks/useAuth";
import { useQuery } from "@tanstack/react-query";
import { createContext, useEffect, useState } from "react";

export const ProductsContext = createContext();

export function ProductsProvider({ children }) {
  const { user } = useAuth();
  const [products, setProducts] = useState([]);
  const [productOptions, setProductOptions] = useState([]);

  const {
    isLoading,
    data: response,
    error,
  } = useQuery({
    queryKey: ["products"],
    queryFn: apiClient.getProducts,
    enabled: user !== null,
  });

  useEffect(() => {
    if (!isLoading && !error && response) {
      setProducts(response.data);
      setProductOptions(response.data.map((product) => ({ name: product.name, value: product.id })));
    }
  }, [response, isLoading, error]);

  return (
    <ProductsContext.Provider value={{ products, isLoading, productOptions }}>{children}</ProductsContext.Provider>
  );
}
