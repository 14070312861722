import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Layout } from "@/components/layout/Layout";
import { DelegatedTasksTable } from "@/components/Tasks/DelegatedTasksTable";
import { TaskCreateDialog } from "@/components/Tasks/TaskCreateDialog";
import { TasksTable } from "@/components/Tasks/TasksTable";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import i18n from "@/i18n";
import { useTaskPolicy } from "@/policies/useTaskPolicy";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const TasksPage = () => {
  useDocumentTitle("Zadania");
  const { state } = useLocation();
  const [activeTab, setActiveTab] = useState("tasks");
  const taskPolicy = useTaskPolicy();

  useEffect(() => {
    if (state?.tab) {
      setActiveTab(state.tab);
    }
  }, [state]);

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <TaskCreateDialog buttonVariant="outline" withUser={true} />
      </div>
      <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value)}>
        {taskPolicy.assignTask() && (
          <TabsList>
            <TabsTrigger value="tasks">{i18n.t("Moje zadania")}</TabsTrigger>
            <TabsTrigger value="delegated_tasks">{i18n.t("Zlecone zadania")}</TabsTrigger>
          </TabsList>
        )}
        <TabsContent value="tasks" className="mt-5">
          <TasksTable />
        </TabsContent>
        {taskPolicy.assignTask() && (
          <TabsContent value="delegated_tasks" className="mt-5">
            <DelegatedTasksTable />
          </TabsContent>
        )}
      </Tabs>
    </Layout>
  );
};
