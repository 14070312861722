import i18n from "@/i18n";
import { useAuditPolicy } from "@/policies/audit/useAuditPolicy";
import { useContractPolicy } from "@/policies/contracts/useContractPolicy";
import { useInstallationPolicy } from "@/policies/installation/useInstallationPolicy";
import { useClientPolicy } from "@/policies/lead/useClientPolicy";
import { useLeadPolicy } from "@/policies/lead/useLeadPolicy";
import { routes } from "@/routes";
import { Link } from "react-router-dom";

export const useTaskableTableColumn = (task) => {
  const contractPolicy = useContractPolicy();
  const leadPolicy = useLeadPolicy();
  const installationPolicy = useInstallationPolicy();
  const auditPolicy = useAuditPolicy();
  const clientPolicy = useClientPolicy();

  const taskable = task.taskable;
  const taskableType = task.taskable_type;
  if (taskableType === "Lead")
    if (leadPolicy.view()) {
      return (
        <Link className="flex flex-row gap-1 hover:underline whitespace-nowrap" to={`${routes.leads}/${taskable.id}`}>
          <span>{i18n.t("Kontakt")}</span>
          <span className="font-semibold">{taskable.name}</span>
        </Link>
      );
    } else {
      return (
        <>
          <span>{i18n.t("Kontakt")}</span>
          <span className="font-semibold">{taskable.name}</span>
        </>
      );
    }
  else if (taskableType === "Contract")
    if (contractPolicy.view()) {
      return (
        <Link
          className="flex flex-row gap-1 hover:underline whitespace-nowrap"
          to={`${routes.contracts}/${taskable.id}`}
        >
          <span>{i18n.t("Umowa")}</span>
          <span className="font-semibold">{taskable.name}</span>
        </Link>
      );
    } else {
      return (
        <>
          <span>{i18n.t("Umowa")}</span>
          <span className="font-semibold">{taskable.name}</span>
        </>
      );
    }
  else if (taskableType === "Audit")
    if (auditPolicy.view()) {
      return (
        <Link className="flex flex-row gap-1 hover:underline whitespace-nowrap" to={`${routes.audits}/${taskable.id}`}>
          <span className="font-semibold">{i18n.t("Audyt")}</span>
        </Link>
      );
    } else {
      return (
        <>
          <span className="font-semibold">{i18n.t("Audyt")}</span>
        </>
      );
    }
  else if (taskableType === "Installation")
    if (installationPolicy.view()) {
      return (
        <Link
          className="flex flex-row gap-1 hover:underline whitespace-nowrap"
          to={`${routes.installations}/${taskable.id}`}
        >
          <span>{i18n.t("Montaż")}</span>
          <span className="font-semibold">{taskable.name}</span>
        </Link>
      );
    } else {
      return (
        <>
          <span>{i18n.t("Montaż")}</span>
          <span className="font-semibold">{taskable.name}</span>
        </>
      );
    }
  else if (taskableType === "Application")
    if (installationPolicy.view()) {
      return (
        <Link
          className="flex flex-row gap-1 hover:underline whitespace-nowrap"
          to={`${routes.applications}/${taskable.id}`}
        >
          <span>{i18n.t("Wniosek")}</span>
          <span className="font-semibold">{taskable.name}</span>
        </Link>
      );
    } else {
      return (
        <>
          <span>{i18n.t("Wniosek")}</span>
          <span className="font-semibold">{taskable.name}</span>
        </>
      );
    }
  else if (taskableType === "Client")
    if (clientPolicy.view()) {
      return (
        <Link className="flex flex-row gap-1 hover:underline whitespace-nowrap" to={`${routes.clients}/${taskable.id}`}>
          <span>{i18n.t("Klient")}</span>
          <span className="font-semibold">{taskable.name}</span>
        </Link>
      );
    } else {
      return (
        <>
          <span>{i18n.t("Klient")}</span>
          <span className="font-semibold">{taskable.name}</span>
        </>
      );
    }
  else {
    return i18n.t("-");
  }
};
