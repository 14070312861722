import { ClientDetailsPage } from "@/pages/Client/ClientDetailsPage";
import { ClientsPage } from "@/pages/Client/ClientsPage";
import { routes } from "@/routes";
import { Route } from "react-router-dom";

export const ClientRoutes = () => (
  <>
    <Route exact path={routes.clients} element={<ClientsPage />} />
    <Route exact path={routes.client} element={<ClientDetailsPage />} />
  </>
);
