import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { EmptyState } from "@/components/EmptyState";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { Loader } from "@/components/Loader";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { toast } from "@/components/ui/use-toast";
import { UserAvatar } from "@/components/Users/UserAvatar";
import { UsersContext } from "@/context/UsersContext";
import { UserRole } from "@/enums/UserRoleEnum";
import { useAuth } from "@/hooks/useAuth";
import i18n from "@/i18n";
import { useContractUserPolicy } from "@/policies/contracts/useContractUserPolicy";
import { useUserPolicy } from "@/policies/user/useUserPolicy";
import { routes } from "@/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Plus, UserMinus, UserPlus, X } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  user_id: Yup.string().required(i18n.t("Pole jest wymagane")),
});

export const ContractUsersCard = ({ contract }) => {
  const userPolicy = useUserPolicy();
  const contractUserPolicy = useContractUserPolicy();
  const queryClient = useQueryClient();
  const { id: contractId } = useParams();
  const { user: authUser } = useAuth();

  const [isOpen, setIsOpen] = useState(false);
  const [userOptions, setUserOptions] = useState([]);
  const { users, isLoading: isLoadingUsers } = useContext(UsersContext);

  const { isLoading: isLoadingContractUsers, data: contractUsers } = useQuery({
    queryKey: ["contract", contractId, "users"],
    queryFn: () => apiClient.getContractUsers(contractId),
    enabled: contractUserPolicy.viewAny(),
  });

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      user_id: undefined,
    },
    mode: "onBlur",
  });

  const onSubmit = (data) => {
    createContractUserMutation.mutate({ contractId, data });
  };

  const handleDelete = (userId) => {
    deleteContractUserMutation.mutate({ contractId, userId });
  };

  const createContractUserMutation = useMutation({
    mutationFn: apiClient.createContractUser,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["contract", contractId, "users"] });
      queryClient.invalidateQueries({ queryKey: ["users"] });
      if (res.ok) {
        setIsOpen(false);
        form.reset({ user_id: undefined });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const deleteContractUserMutation = useMutation({
    mutationFn: apiClient.deleteContractUser,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["contract", contractId, "users"] });
      queryClient.invalidateQueries({ queryKey: ["users"] });
      if (!res.ok) {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  useEffect(() => {
    if (!isLoadingUsers && users && contractUsers) {
      setUserOptions(
        users
          .filter((user) => !contractUsers.data.some((contractUser) => contractUser.id === user.id))
          .map((user) => ({ name: user.name, value: user.id })),
      );
    }
  }, [isLoadingUsers, users, contractUsers]);

  return (
    <Card className="flex-1 h-fit shadow-md pr-2">
      <CardHeader>
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-col gap-1">
            <CardTitle>{i18n.t("Osoby zaangażowane")}</CardTitle>
            <CardDescription>{i18n.t("Osoby dodane do pracy nad tą umową")}</CardDescription>
          </div>
          {contractUserPolicy.create(contract) && (
            <Dialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
              <DialogTrigger asChild>
                <Button
                  variant="outline"
                  className="flex flex-row gap-1 items-center"
                  leftIcon={<UserPlus size={16} />}
                  title="Dodaj"
                />
              </DialogTrigger>
              <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                  <DialogTitle>{i18n.t("Dodaj użytkownika")}</DialogTitle>
                  <DialogDescription>{i18n.t("Zaproś użytkownika do pracy nad tą umową.")}</DialogDescription>
                </DialogHeader>
                <Form form={form} onSubmit={onSubmit}>
                  <FormCombobox
                    name="user_id"
                    options={userOptions}
                    isLoading={isLoadingUsers}
                    label="Użytkownik do zaproszenia"
                  />
                  <DialogFooter className="mt-5 items-center">
                    <Button
                      type="submit"
                      isLoading={createContractUserMutation.isPending}
                      leftIcon={<Plus size={16} />}
                      title="Dodaj"
                    />
                    <Button
                      type="button"
                      variant="destructive"
                      onClick={() => setIsOpen(false)}
                      className="flex flex-row gap-1"
                      leftIcon={<X size={16} />}
                      title="Anuluj"
                    />
                  </DialogFooter>
                </Form>
              </DialogContent>
            </Dialog>
          )}
        </div>
      </CardHeader>
      <ScrollArea className="w-full flex max-h-48 gap-6 flex-col overflow-y-auto">
        <CardContent className="grid gap-5">
          {isLoadingContractUsers && !contractUsers ? (
            <Loader />
          ) : contractUsers?.data?.length === 0 ? (
            <EmptyState title="Brak użytkowników" />
          ) : (
            contractUsers?.data?.map((user, index) => (
              <div key={index} className="flex items-center justify-between space-x-4">
                {user.id !== authUser.id && userPolicy.view(user) ? (
                  <Link to={`${routes.users}/${user.id}`} className="flex items-center space-x-4 hover:underline">
                    <UserAvatar user={user} />
                    <div>
                      <p className="text-sm font-medium leading-none">{user.name}</p>
                      <p className="text-sm text-muted-foreground">{user.email}</p>
                      <p className="text-sm text-muted-foreground">{UserRole[user.roles[0].name].name}</p>
                    </div>
                  </Link>
                ) : (
                  <div className="flex items-center space-x-4">
                    <UserAvatar user={user} />
                    <div>
                      <p className="text-sm font-medium leading-none">{user.name}</p>
                      <p className="text-sm text-muted-foreground">{user.email}</p>
                      <p className="text-sm text-muted-foreground">{UserRole[user.roles[0].name].name}</p>
                    </div>
                  </div>
                )}
                {contractUserPolicy.destroy() && (
                  <DeleteAlertDialog
                    trigger={
                      <Button
                        title={user.id !== authUser.id ? "Usuń" : "Opuść"}
                        className="flex-1 lg:flex-initial"
                        variant="outline"
                        leftIcon={<UserMinus size={16} />}
                        isLoading={deleteContractUserMutation.isPending}
                      />
                    }
                    message={i18n.t("Czy na pewno chcesz usunąć tego użytkownika?")}
                    onConfirm={() => handleDelete(user.id)}
                  />
                )}
              </div>
            ))
          )}
        </CardContent>
      </ScrollArea>
    </Card>
  );
};
