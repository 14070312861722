import { useAuth } from "@/hooks/useAuth";

export const useDepartmentPolicy = () => {
  const { user, hasPermission } = useAuth();

  const viewAny = () => {
    return hasPermission("access_departments");
  };

  const view = (department) => {
    if (hasPermission("show_any_department")) {
      return true;
    }
    if (hasPermission("show_department")) {
      return department.id === user?.department?.id;
    }
  };

  const create = () => {
    return hasPermission("create_department");
  };

  const update = () => {
    return hasPermission("update_department");
  };

  const destroy = (department) => {
    return (
      hasPermission("delete_department") &&
      (department?.users?.length === 0 || department?.users_count === 0) &&
      user?.department?.id !== department?.id
    );
  };

  const viewUsers = () => {
    return hasPermission("access_department_users");
  };

  const viewStats = () => {
    return hasPermission("access_department_stats");
  };

  return {
    viewAny,
    view,
    create,
    update,
    destroy,
    viewUsers,
    viewStats,
  };
};
