export const TaskRecurrenceUnit = Object.freeze({
  day: {
    value: "day",
    name: "Dzień",
  },
  week: {
    value: "week",
    name: "Tydzień",
  },
  month: {
    value: "month",
    name: "Miesiąc",
  },
  year: {
    value: "year",
    name: "Rok",
  },

  getValues() {
    return Object.values(this).filter((value) => typeof value === "object" && value !== null);
  },

  getNames() {
    return this.getValues().map((status) => status.name);
  },
});
