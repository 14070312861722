import { InstallationCrewDetailsPage } from "@/pages/Installation/InstallationCrewDetailsPage";
import { InstallationDetailsPage } from "@/pages/Installation/InstallationDetailsPage";
import { InstallationsPage } from "@/pages/Installation/InstallationsPage";
import { routes } from "@/routes";
import { Route } from "react-router-dom";

export const InstallationRoutes = () => (
  <>
    <Route exact path={routes.installations} element={<InstallationsPage />} />
    <Route exact path={routes.installation} element={<InstallationDetailsPage />} />
    <Route exact path={routes.installationCrew} element={<InstallationCrewDetailsPage />} />
  </>
);
