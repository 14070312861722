const storeToken = (token) => {
  localStorage.setItem("token", JSON.stringify(token));
};

const getToken = () => {
  const token = localStorage.getItem("token");
  if (token === null || token === "" || token === " ") return null;
  return JSON.parse(token);
};

const removeToken = () => {
  localStorage.removeItem("token");
};

export default { storeToken, getToken, removeToken };
