export const ContractPaymentType = Object.freeze({
  CASH: { value: "CASH", name: "Gotówka" },
  LOAN: { value: "LOAN", name: "Kredyt" },
  PREFINANCED: { value: "PREFINANCED", name: "Prefinansowana" },

  getValues() {
    return Object.values(this).filter((value) => typeof value === "object" && value !== null);
  },

  getNames() {
    return this.getValues().map((status) => status.name);
  },
});
