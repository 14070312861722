import apiClient from "@/api/apiClient";
import { Progress } from "@/components/Progress";
import { Table } from "@/components/Table/Table";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { ButtonContext } from "@/context/ButtonContext";
import { ContractPaymentType } from "@/enums/ContractPaymentTypeEnum";
import { formatMoney } from "@/helpers/formatMoney";
import { useContractPaymentsTableColumns } from "@/hooks/table/useContractPaymentsTableColumns";
import i18n from "@/i18n";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

export const ContractPaymentsTable = ({ contract }) => {
  const { id: contractId } = useParams();
  const queryClient = useQueryClient();
  const { showButton, hideButton } = useContext(ButtonContext);

  const deleteContractPaymentMutation = useMutation({
    mutationFn: apiClient.deleteContractPayment,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["contract", contractId, "payments"] });
      queryClient.invalidateQueries({ queryKey: ["contract", contractId], exact: true });
      if (!res.ok) {
        if (res.status === 400) {
          toast({ title: <ErrorToast title="Nie można usunąć płatności na tym statusie umowy." /> });
        } else {
          toast({ title: <ErrorToast title="Błąd podczas usuwania." /> });
        }
      }
    },
  });

  const handleDelete = async (contractPaymentId) => {
    deleteContractPaymentMutation.mutate({ contractId, contractPaymentId });
  };

  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery({
    queryKey: ["contract", contractId, "payments"],
    queryFn: () => apiClient.getContractPayments(contractId),
  });

  const { columns } = useContractPaymentsTableColumns(handleDelete, contract);
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : response.data,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    rowCount: response?.meta?.total ?? response?.data?.length,
  });

  useEffect(() => {
    showButton("addContractPayment");

    return () => {
      hideButton("addContractPayment");
    };
  }, []);

  return (
    <div className="w-full flex flex-col gap-5">
      <Card className="bg-background">
        <CardHeader>
          {contract.payment_type && (
            <Badge className="w-fit mb-1">{ContractPaymentType[contract.payment_type].name}</Badge>
          )}
          <CardTitle>{i18n.t("Postęp płatności")}</CardTitle>
          <CardDescription>
            {formatMoney(contract.payment_sum)} / {formatMoney(contract.gross_price)}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Progress current={parseFloat(contract.payment_sum)} total={contract.gross_price} />
        </CardContent>
      </Card>
      <Table table={table} meta={response?.meta} isFetching={isFetching} isLoading={isLoading} />
    </div>
  );
};
