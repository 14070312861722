import { InstallationCrewVoivodeships } from "@/components/Installations/Crews/Details/VoivodeshipMap";
import { CardRow } from "@/components/ui/CardRow";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { capitalize } from "@/helpers/capitalize";
import { formatPhoneNumber } from "@/helpers/formatPhoneNumber";
import i18n from "@/i18n";
import { format, parse } from "date-fns";

export const InstallationCrewInformationCard = ({ installationCrew }) => {
  return (
    <Card className="flex-1 h-fit shadow-md">
      <CardHeader>
        <CardTitle>{i18n.t("Informacje")}</CardTitle>
        <CardDescription>{i18n.t("Informacje o montażu")}</CardDescription>
      </CardHeader>
      <CardContent className="divide-y divide-y-border">
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Nazwa ekipy")}</p>
          <p>{installationCrew.name}</p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Data utworzenia")}</p>
          <p title={installationCrew.created_at}>
            {format(parse(installationCrew.created_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMM yyyy")}
          </p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Numer telefonu")}</p>
          {installationCrew.phone_number ? (
            <a className="font-semibold" href={`tel:${installationCrew.phone_number}`}>
              {formatPhoneNumber(installationCrew.phone_number)}
            </a>
          ) : (
            <p>{i18n.t("Brak")}</p>
          )}
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Adres e-mail")}</p>
          {installationCrew.email ? (
            <a className="font-semibold" href={`mailto:${installationCrew.email}`}>
              {installationCrew.email}
            </a>
          ) : (
            <p>{i18n.t("Brak")}</p>
          )}
        </CardRow>
        <CardRow className="hover:bg-background">
          <p className="text-xs sm:text-base font-medium">
            {i18n.t("Województwa")}
            <p className="text-left font-normal mt-5">
              {installationCrew.voivodeships.map((voivodeship, index) => (
                <div key={voivodeship + "_" + index}>{capitalize(voivodeship)}</div>
              ))}
            </p>
          </p>
          <div>
            <InstallationCrewVoivodeships highlightedRegions={installationCrew.voivodeships} />
          </div>
        </CardRow>
        {installationCrew.description && (
          <CardRow className="flex lg:flex-col lg:items-start gap-1">
            <p className="text-xs sm:text-base font-medium">{i18n.t("Opis")}</p>
            <p className="text-muted-foreground">{installationCrew.description}</p>
          </CardRow>
        )}
      </CardContent>
    </Card>
  );
};
