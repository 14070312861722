import i18n from "@/i18n";
import { CheckCircle } from "lucide-react";

export const SuccessToast = ({ title }) => {
  return (
    <div className="col-span-full flex flex-row gap-3 items-center w-full">
      <CheckCircle size={30} className="text-green-500 w-1/4" />
      <p className="w-full">{i18n.t(title)}</p>
    </div>
  );
};
