import { Card, CardContent } from "@/components/ui/card";
import { formatMoney } from "@/helpers/formatMoney";
import i18n from "@/i18n";

export const CalculationPriceSummaryCardContent = ({ calculator }) => {
  return (
    <CardContent className="grid grid-cols-1 gap-3 sm:grid-cols-2">
      <Card className="flex flex-col p-4">
        <p className="capitalize">{i18n.t("Netto")}</p>
        <p className="text-3xl font-bold">{formatMoney(calculator.netPrice)}</p>
      </Card>
      <Card className="flex flex-col p-4">
        <p className="capitalize">{i18n.t("Brutto")}</p>
        <p className="text-3xl font-bold">{formatMoney(calculator.grossPrice)}</p>
      </Card>
      <Card className="flex flex-col p-4">
        <p className="capitalize">{i18n.t("Stawka podatku VAT")}</p>
        <p className="text-3xl font-bold">{calculator.vat ? calculator.vat + " %" : i18n.t("Brak")}</p>
      </Card>
      <Card className="flex flex-col p-4">
        <p className="capitalize">{i18n.t("Wartość podatku VAT")}</p>
        <p className="text-3xl font-bold">{formatMoney(calculator.grossPrice - calculator.netPrice)}</p>
      </Card>
      {Object.values(calculator.customFields).map((field, index) =>
        field.value
          ? field.value > 0 && (
              <Card key={index} className="flex flex-col p-4 col-span-2">
                <p className="capitalize">{field.name}</p>
                <p className="text-3xl font-bold">
                  {field.value} {field.unit}
                </p>
              </Card>
            )
          : null,
      )}
    </CardContent>
  );
};
