import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import i18n from "@/i18n";
import { CheckCircle, FileCheck2, FileSearch, TrendingDown, TrendingUp, Wrench } from "lucide-react";

export const OfficeDashboardOverviewTiles = ({ data }) => {
  const { tasks, open_contracts_count, installations_count, completed_contracts_count } = data;

  return (
    <div className="grid gap-4 md:grid-cols-2 md:gap-5 lg:grid-cols-4">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">{i18n.t("Liczba zadań do wykonania")}</CardTitle>
          <CheckCircle className="h-6 w-6 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{tasks.current_month}</div>
          {tasks.growth > 0 ? (
            <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
              <TrendingUp size={16} />
              {i18n.t("Wzrost o ")} {tasks.growth + "%"} {i18n.t(" względem poprzedniego miesiąca")}
            </p>
          ) : (
            tasks.growth < 0 && (
              <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
                <TrendingDown size={16} />
                {i18n.t("Spadek o")} {tasks.growth + "%"} {i18n.t(" względem poprzedniego miesiąca")}
              </p>
            )
          )}
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">{i18n.t("Liczba umów w procesie realizacji")}</CardTitle>
          <FileSearch className="h-6 w-6 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{open_contracts_count.current_month}</div>
          {open_contracts_count.growth > 0 ? (
            <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
              <TrendingUp size={16} />
              {i18n.t("Wzrost o ")} {open_contracts_count.growth + "%"} {i18n.t(" względem poprzedniego miesiąca")}
            </p>
          ) : (
            open_contracts_count.growth < 0 && (
              <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
                <TrendingDown size={16} />
                {i18n.t("Spadek o")} {open_contracts_count.growth + "%"} {i18n.t(" względem poprzedniego miesiąca")}
              </p>
            )
          )}
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">{i18n.t("Liczba montaży")}</CardTitle>
          <Wrench className="h-6 w-6 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{installations_count.current_month}</div>
          {installations_count.growth > 0 ? (
            <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
              <TrendingUp size={16} />
              {i18n.t("Wzrost o ")} {installations_count.growth + "%"} {i18n.t(" względem poprzedniego miesiąca")}
            </p>
          ) : (
            installations_count.growth < 0 && (
              <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
                <TrendingDown size={16} />
                {i18n.t("Spadek o")} {installations_count.growth + "%"} {i18n.t(" względem poprzedniego miesiąca")}
              </p>
            )
          )}
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">{i18n.t("Liczba zrealizowanych umów")}</CardTitle>
          <FileCheck2 className="h-6 w-6 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{completed_contracts_count.current_month}</div>
          {completed_contracts_count.growth > 0 ? (
            <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
              <TrendingUp size={16} />
              {i18n.t("Wzrost o ")} {completed_contracts_count.growth + "%"} {i18n.t(" względem poprzedniego miesiąca")}
            </p>
          ) : (
            completed_contracts_count.growth < 0 && (
              <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
                <TrendingDown size={16} />
                {i18n.t("Spadek o")} {completed_contracts_count.growth + "%"}{" "}
                {i18n.t(" względem poprzedniego miesiąca")}
              </p>
            )
          )}
        </CardContent>
      </Card>
    </div>
  );
};
