import apiClient from "@/api/apiClient";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { Calculator } from "@/components/Calculators/Calculator";
import { Layout } from "@/components/layout/Layout";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useCalculatorPolicy } from "@/policies/useCalculatorPolicy";
import { routes } from "@/routes";
import { useQuery } from "@tanstack/react-query";
import { Cog } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

export const CalculatorPage = () => {
  const { id: calculatorId } = useParams();
  const calculatorPolicy = useCalculatorPolicy();
  const navigate = useNavigate();

  const { isLoading, data: calculator } = useQuery({
    queryKey: ["calculator", calculatorId],
    queryFn: () => apiClient.getCalculator(calculatorId),
    staleTime: 1000 * 60 * 10,
  });
  useDocumentTitle(calculator?.data ? `Kalkulator ${calculator.data.name}` : null);

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs idAlias={calculator?.data?.name} />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {calculatorPolicy.update() && (
            <Button
              leftIcon={<Cog size={20} />}
              variant="outline"
              title="Konfiguracja"
              onClick={() => navigate(`${routes.calculators}/${calculator?.data?.id}/konfiguracja`)}
            />
          )}
        </div>
      </div>
      <Calculator calculator={calculator?.data} />
    </Layout>
  );
};
