import { ContractProductCreateDialog } from "@/components/Contracts/Settings/Products/ContractProductCreateDialog";
import { ContractProducts } from "@/components/Contracts/Settings/Products/ContractProducts";
import { ContractStatusCreateDialog } from "@/components/Contracts/Settings/Statuses/ContractStatusCreateDialog";
import { ContractStatuses } from "@/components/Contracts/Settings/Statuses/ContractStatuses";
import { ProcessCreateDialog } from "@/components/Process/ProcessCreateDialog";
import { ProcessSettings } from "@/components/Process/ProcessSettings";
import i18n from "@/i18n";
import { useContractProductPolicy } from "@/policies/contracts/useContractProductPolicy";
import { useContractStatusPolicy } from "@/policies/contracts/useContractStatusPolicy";
import { useProcessPolicy } from "@/policies/useProcessPolicy";

export const ContractSettings = () => {
  const contractStatusPolicy = useContractStatusPolicy();
  const contractProductPolicy = useContractProductPolicy();
  const processPolicy = useProcessPolicy();

  return (
    <div className="flex flex-col gap-5 w-full">
      {contractStatusPolicy.update() && (
        <div>
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Statusy")}</h2>
            <ContractStatusCreateDialog />
          </div>
          <ContractStatuses />
        </div>
      )}
      {contractProductPolicy.update() && (
        <div>
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Produkty")}</h2>
            <ContractProductCreateDialog />
          </div>
          <ContractProducts />
        </div>
      )}
      {processPolicy.create() && (
        <div>
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Procesy")}</h2>
            <ProcessCreateDialog appliesTo="Contract" />
          </div>
          <ProcessSettings model="Contract" />
        </div>
      )}
    </div>
  );
};
