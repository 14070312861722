import { useAuth } from "@/hooks/useAuth";

export const useAuditActionPolicy = () => {
  const { user, hasPermission } = useAuth();

  const viewAny = () => {
    return hasPermission("access_audit_actions");
  };

  const create = () => {
    return hasPermission("create_audit_action");
  };

  const update = (auditAction) => {
    return auditAction?.user?.id === user?.id && hasPermission("update_audit_action");
  };

  const destroy = (auditAction) => {
    if (hasPermission("delete_any_audit_action")) return true;
    return auditAction?.user?.id === user?.id && hasPermission("delete_audit_action");
  };

  return {
    viewAny,
    create,
    update,
    destroy,
  };
};
