export const WarehouseOperationType = Object.freeze({
  ADD_NEW_PRODUCT: { value: "ADD_NEW_PRODUCT", name: "Produkt dodany" },
  INCREASE_PRODUCT_QUANTITY: { value: "INCREASE_PRODUCT_QUANTITY", name: "Dodany stan magazynowy" },
  REMOVE_PRODUCT: { value: "REMOVE_PRODUCT", name: "Produkt usunięty" },
  DECREASE_PRODUCT_QUANTITY: { value: "DECREASE_PRODUCT_QUANTITY", name: "Odjęty stan magazynowy" },

  getValues() {
    return Object.values(this).filter((value) => typeof value === "object" && value !== null);
  },

  getNames() {
    return this.getValues().map((type) => type.name);
  },
});
