import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { toast } from "@/components/ui/use-toast";
import { UserTooltip } from "@/components/Users/UserTooltip";
import { useAuth } from "@/hooks/useAuth";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { useContractMessagePolicy } from "@/policies/contracts/useContractMessagePolicy";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format, parse } from "date-fns";
import { Trash } from "lucide-react";
import { useParams } from "react-router-dom";

export const ContractMessage = ({ message }) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const { id: contractId } = useParams();
  const contractMessagePolicy = useContractMessagePolicy();

  const deleteContractMessageMutation = useMutation({
    mutationFn: apiClient.deleteContractMessage,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["contract", contractId, "messages"], exact: true });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const handleDelete = (contractMessageId) => {
    deleteContractMessageMutation.mutate({ contractId, contractMessageId });
  };

  return (
    <div className="flex flex-col">
      <p
        className={cn("text-xs text-muted-foreground", message.user.id === user.id ? "ml-auto mr-1" : " mr-auto ml-1")}
      >
        {message.user.id === user.id ? (
          <span className="flex flex-row gap-3 items-center mb-1">{i18n.t("Ja")}</span>
        ) : (
          <UserTooltip user={message.user} linkClassName="hover:no-underline" />
        )}
      </p>
      <div className="flex flex-row gap-3 items-center">
        {user.id === message.user.id && (
          <>
            {contractMessagePolicy.destroy(message) && (
              <DeleteAlertDialog
                message="Czy na pewno chcesz usunąć tę wiadomość? Tej operacji nie można cofnąć"
                onConfirm={() => handleDelete(message.id)}
                trigger={
                  <Button
                    variant="ghost"
                    size="icon"
                    className="text-destructive ml-auto"
                    leftIcon={<Trash size={16} />}
                  />
                }
              />
            )}
          </>
        )}
        <div
          className={cn(
            "flex flex-row w-max max-w-[75%] gap-2 rounded-lg px-3 py-2 text-sm",
            message.user.id === user.id ? "bg-primary text-primary-foreground dark:text-foreground" : "bg-muted",
          )}
        >
          {message.content}
        </div>
      </div>
      <p
        className={cn(
          "text-xs text-muted-foreground opacity-50",
          message.user.id === user.id ? "ml-auto mr-1" : " mr-auto ml-1",
        )}
      >
        {format(parse(message.created_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMM yyyy HH:mm")}
      </p>
    </div>
  );
};
