import { useAuth } from "@/hooks/useAuth";

export const useInstallationFilePolicy = () => {
  const { user, hasPermission } = useAuth();

  const viewAny = () => {
    return hasPermission("access_installation_files");
  };

  const create = () => {
    return hasPermission("create_installation_file");
  };

  const destroy = (installationFile) => {
    if (hasPermission("delete_any_installation_file")) return true;
    return installationFile?.user?.id === user?.id && hasPermission("delete_installation_file");
  };

  return {
    viewAny,
    create,
    destroy,
  };
};
