import { createContext, useState } from "react";

// Tworzymy kontekst
export const ButtonContext = createContext();

// Tworzymy dostawcę kontekstu
export const ButtonProvider = ({ children }) => {
  const [buttons, setButtons] = useState({});

  const showButton = (buttonId) => {
    setButtons((prevState) => ({
      ...prevState,
      [buttonId]: true,
    }));
  };

  const hideButton = (buttonId) => {
    setButtons((prevState) => ({
      ...prevState,
      [buttonId]: false,
    }));
  };

  return <ButtonContext.Provider value={{ buttons, showButton, hideButton }}>{children}</ButtonContext.Provider>;
};
