import { Button } from "@/components/Button";
import { EmptyState } from "@/components/EmptyState";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { CardRow } from "@/components/ui/CardRow";
import { UserTooltip } from "@/components/Users/UserTooltip";
import { OrderStatus } from "@/enums/OrderStatusEnum";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { useOrderPolicy } from "@/policies/order/useOrderPolicy";
import { useUserPolicy } from "@/policies/user/useUserPolicy";
import { routes } from "@/routes";
import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";

export const ContractOrderTab = ({ contract }) => {
  const orderPolicy = useOrderPolicy();
  const userPolicy = useUserPolicy();
  const order = contract.order;

  return (
    <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-5">
      {!order ? (
        <EmptyState title="Ta umowa nie ma jeszcze zamówienia" />
      ) : (
        <Card className="shadow-md">
          <CardHeader className="flex flex-row justify-between items-center">
            <div className="flex flex-col gap-1.5">
              <CardTitle>{`Zamówienie dla ${contract.name}`}</CardTitle>
              <CardDescription>{i18n.t("Informacje o zamówieniu")}</CardDescription>
            </div>
            {orderPolicy.view() && (
              <Link to={`${routes.orders}/${order.id}`}>
                <Button variant="outline" title="Przejdź do zamówienia" rightIcon={<ArrowRight size={16} />} />
              </Link>
            )}
          </CardHeader>
          <CardContent className="divide-y divide-y-border">
            <CardRow>
              <p className="text-xs sm:text-base font-medium">{i18n.t("Status")}</p>
              <Badge className={cn("h-8 rounded-md", OrderStatus[order.status].color)}>
                {OrderStatus[order.status].name}
              </Badge>
            </CardRow>
            {userPolicy.viewAny() && (
              <CardRow>
                <p className="text-xs sm:text-base font-medium">{i18n.t("Osoba odpowiedzialna")}</p>
                <UserTooltip user={order.user} />
              </CardRow>
            )}
            {order.description && (
              <CardRow className="flex lg:flex-col lg:items-start gap-1">
                <p className="text-xs sm:text-base font-medium">{i18n.t("Opis")}</p>
                <p className="text-muted-foreground">{order.description}</p>
              </CardRow>
            )}
          </CardContent>
        </Card>
      )}
    </div>
  );
};
