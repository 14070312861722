import { BillingCreatePage } from "@/pages/Billing/BillingCreatePage";
import { BillingGroupCreatePage } from "@/pages/Billing/BillingGroupCreatePage";
import { BillingSettingDetailsPage } from "@/pages/Billing/BillingSettingDetailsPage";
import { BillingsPage } from "@/pages/Billing/BillingsPage";
import { routes } from "@/routes";
import { Route } from "react-router-dom";

export const BillingRoutes = () => (
  <>
    <Route exact path={routes.billings} element={<BillingsPage />} />
    <Route exact path={routes.billingCreate} element={<BillingCreatePage />} />
    <Route exact path={routes.billingGroupCreate} element={<BillingGroupCreatePage />} />
    <Route exact path={routes.billingSetting} element={<BillingSettingDetailsPage />} />
  </>
);
