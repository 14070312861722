import { cn } from "@/lib/utils";

export const PulsingDot = ({ className }) => {
  return (
    <div className={cn("absolute -top-1 -right-1", className)}>
      <div className="w-2 h-2 bg-red-600 rounded-full"></div>
      <div className="w-2 h-2 bg-red-600 rounded-full absolute top-0 right-0 animate-ping"></div>
      <div className="w-2 h-2 bg-red-600 rounded-full absolute top-0 right-0 animate-pulse"></div>
    </div>
  );
};
