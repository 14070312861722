import { CardRow } from "@/components/ui/CardRow";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import i18n from "@/i18n";
import { useWarehousePolicy } from "@/policies/warehouse/useWarehousePolicy";

export const WarehouseInformationCard = ({ warehouse }) => {
  const warehousePolicy = useWarehousePolicy();

  if (!warehousePolicy.view()) return null;

  return (
    <Card className="w-1/3">
      <CardHeader>
        <CardTitle>{i18n.t("Informacje")}</CardTitle>
        <CardDescription>{i18n.t("Informacje na temat magazynu")}</CardDescription>
      </CardHeader>
      <CardContent className="divide-y divide-y-border">
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Nazwa magazynu")}</p>
          <p>{warehouse.name}</p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Magazyn zewnętrzny")}</p>
          <p>{warehouse.external ? i18n.t("Tak") : i18n.t("Nie")}</p>
        </CardRow>
        <CardRow>
          <p className="text-xs sm:text-base font-medium">{i18n.t("Liczba produktów")}</p>
          <p>{warehouse.products_count}</p>
        </CardRow>
      </CardContent>
    </Card>
  );
};
