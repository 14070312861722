import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormField } from "@/components/forms/FormField";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { InstallationStatusContext } from "@/context/InstallationStatusContext";
import { UsersContext } from "@/context/UsersContext";
import i18n from "@/i18n";
import { useContractPolicy } from "@/policies/contracts/useContractPolicy";
import { useInstallationPolicy } from "@/policies/installation/useInstallationPolicy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

export const InstallationCreateDialog = ({ contract, buttonVariant = "outline" }) => {
  const { statusOptions } = useContext(InstallationStatusContext);
  const queryClient = useQueryClient();
  const contractPolicy = useContractPolicy();
  const installationPolicy = useInstallationPolicy();

  const [isOpen, setIsOpen] = useState(false);
  const [contractOptions, setContractOptions] = useState([]);
  const { userOptions, isLoading: isLoadingUsers } = useContext(UsersContext);
  const filters = {
    columnFilters: [{ id: "withoutInstallation", value: true }],
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(i18n.t("Pole jest wymagane")),
    user_id: Yup.string().optional().nullable(),
    contract_id: Yup.string().required(i18n.t("Pole jest wymagane")),
    installation_status_id: Yup.string().required(i18n.t("Pole jest wymagane")),
    description: Yup.string().nullable(),
  });

  const {
    isLoading: isLoadingContracts,
    data: contracts,
    error: errorContracts,
  } = useQuery({
    queryKey: ["contracts", filters],
    queryFn: () => apiClient.getContracts(filters),
    staleTime: 1000 * 60 * 5,
    enabled: contractPolicy.viewAny() && isOpen,
  });

  const onSubmit = (data) => {
    createInstallationMutation.mutate({ data });
  };

  const defaultValues = {
    contract_id: contract ? contract.id : undefined,
    user_id: undefined,
    installation_status_id: statusOptions[0]?.value,
    description: "",
    name: "",
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onSubmit",
  });

  const createInstallationMutation = useMutation({
    mutationFn: apiClient.createInstallation,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["installations"] });
      queryClient.invalidateQueries({ queryKey: ["contracts", filters] });
      if (contract) {
        queryClient.invalidateQueries({ queryKey: ["contract", contract.id], exact: true });
      }
      if (res.ok) {
        toast({ title: <SuccessToast title="Montaż dodany." /> });
        form.reset();
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  useEffect(() => {
    if (!isLoadingContracts && !errorContracts && contracts) {
      setContractOptions(
        contracts.data.map((contract) => ({ name: `${contract.identifier} - ${contract.name}`, value: contract.id })),
      );
    }
  }, [isLoadingContracts, contracts, errorContracts]);

  if (!installationPolicy.create()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <DialogTrigger asChild>
        <Button variant={buttonVariant} title="Dodaj montaż" leftIcon={<Plus size={20} />} />
      </DialogTrigger>
      <DialogContent className="sm:max-w-1/2">
        <DialogHeader>
          <DialogTitle>{i18n.t("Dodaj montaż")}</DialogTitle>
          <DialogDescription>{i18n.t("Dodawanie nowego montażu.")}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FormField name="name" label="Nazwa" />
            {!contract && <FormCombobox label="Umowa" name="contract_id" options={contractOptions} />}
            {installationPolicy.viewAll() && (
              <FormCombobox
                label="Użytkownik odpowiedzialny"
                name="user_id"
                options={userOptions}
                isLoading={isLoadingUsers}
              />
            )}
            <FormTextarea name="description" label="Opis" />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              title="Dodaj"
              leftIcon={<Plus size={20} />}
              isLoading={createInstallationMutation.isPending}
            />
            <Button
              type="button"
              title="Anuluj"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
