import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { Form } from "@/components/forms/Form";
import { FormField } from "@/components/forms/FormField";
import { FormSelect } from "@/components/forms/FormSelect";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { AuditStatusContext } from "@/context/AuditStatusContext";
import { ContractStatusContext } from "@/context/ContractStatusContext";
import { InstallationStatusContext } from "@/context/InstallationStatusContext";
import { LeadStatusContext } from "@/context/LeadStatusContext";
import { appendEmptyOption } from "@/helpers/appendEmptyOption";
import i18n from "@/i18n";
import { useProcessPolicy } from "@/policies/useProcessPolicy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Plus, Trash, X } from "lucide-react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string().required(i18n.t("Pole jest wymagane")),
  parent_id: Yup.string().required(i18n.t("Pole jest wymagane")),
});

export const ProcessStep = ({ process, step, index }) => {
  const queryClient = useQueryClient();
  const processPolicy = useProcessPolicy();
  const { statusOptions: leadStatusOptions, isLoading: isLoadingLeadStatuses } = useContext(LeadStatusContext);
  const { statusOptions: contractStatusOptions, isLoading: isLoadingContractStatuses } =
    useContext(ContractStatusContext);
  const { statusOptions: installationStatusOptions, isLoading: isLoadingInstallationStatuses } =
    useContext(InstallationStatusContext);
  const { statusOptions: auditStatusOptions, isLoading: isLoadingAuditStatuses } = useContext(AuditStatusContext);

  const [createSubStep, setCreateSubStep] = useState(false);

  const defaultValues = {
    name: undefined,
    parent_id: step.id,
  };

  const form = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });
  const watch = form.watch();

  const createProcessStepMutation = useMutation({
    mutationFn: apiClient.createProcessStep,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["processes"] });
        form.reset();
        setCreateSubStep(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const deleteProcessStepMutation = useMutation({
    mutationFn: apiClient.deleteProcessStep,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["processes"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Krok został usunięty." /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const onSubmit = (data) => {
    createProcessStepMutation.mutate({ processId: process.id, data });
  };

  const handleDeleteStep = (processStepId) => {
    deleteProcessStepMutation.mutate({ processId: process.id, processStepId });
  };

  return (
    <div key={step.id} className="flex flex-col justify-center gap-1">
      <h3 className="flex flex-row gap-2 items-center mt-2 justify-between border-b pb-1">
        {index + 1}. {step.name}
        {processPolicy.update() && (
          <DeleteAlertDialog
            message="Czy na pewno chcesz usunąć ten krok? Tej operacji nie można cofnąć"
            onConfirm={() => handleDeleteStep(step.id)}
            trigger={
              <Button
                variant="link"
                className="text-destructive"
                size="xs"
                leftIcon={<Trash size={16} />}
                isLoading={deleteProcessStepMutation.isPending}
              />
            }
          />
        )}
      </h3>
      <ul className="flex flex-col gap-2 ml-5">
        {step.children?.map((subStep, subStepIndex) => (
          <li key={subStep.id} className="flex flex-row gap-2 items-center">
            {`${subStepIndex + 1}. ${subStep.name}`}
          </li>
        ))}
      </ul>
      {createSubStep && (
        <Form form={form} onSubmit={onSubmit} className="flex flex-col gap-3 ml-5">
          {step.children.length + 1}. {watch.name}
          <FormField name="name" autoComplete="off" label="Nazwa" placeholder="Nazwa kroku" />
          {process.applies_to === "Lead" && (
            <FormSelect
              options={appendEmptyOption(leadStatusOptions)}
              label="Zmiana statusu"
              name="status_change"
              isLoading={isLoadingLeadStatuses}
            />
          )}
          {process.applies_to === "Contract" && (
            <FormSelect
              options={appendEmptyOption(contractStatusOptions)}
              label="Zmiana statusu"
              name="status_change"
              isLoading={isLoadingContractStatuses}
            />
          )}
          {process.applies_to === "Audit" && (
            <FormSelect
              options={appendEmptyOption(auditStatusOptions)}
              label="Zmiana statusu"
              name="status_change"
              isLoading={isLoadingAuditStatuses}
            />
          )}
          {process.applies_to === "Installation" && (
            <FormSelect
              options={appendEmptyOption(installationStatusOptions)}
              label="Zmiana statusu"
              name="status_change"
              isLoading={isLoadingInstallationStatuses}
            />
          )}
          <div className="flex flex-row gap-3 items-center">
            <Button leftIcon={<Plus size={16} />} title="Dodaj" type="submit" />
            <Button
              type="button"
              variant="destructive"
              leftIcon={<X size={16} />}
              title="Anuluj"
              onClick={() => setCreateSubStep(false)}
            />
          </div>
        </Form>
      )}
      {!createSubStep && processPolicy.update() && (
        <Button
          variant="link"
          title="Dodaj krok"
          leftIcon={<Plus size={16} />}
          className="ml-5 w-fit px-0 py-0"
          onClick={() => setCreateSubStep(!createSubStep)}
        />
      )}
    </div>
  );
};
