import App from "@/App";
import { config } from "@/config";
import "@/i18n";
import "@/index.css";
import * as Sentry from "@sentry/react";
import i18next from "i18next";
import React from "react";
import ReactDOM from "react-dom/client";

const lang = localStorage.getItem("lang") || "pl";
i18next.changeLanguage(lang);
document.documentElement.lang = lang;
const currentBranch = config("app.branch");

if (config("app.env") !== "local" && window.location.hostname !== "localhost") {
  Sentry.init({
    dsn: config("sentry.dsn"),
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    initialScope: {
      tags: {
        branch: currentBranch,
      },
    },
  });
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
