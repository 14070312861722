import apiClient from "@/api/apiClient";
import { CalendarView } from "@/components/CalendarView";
import { FullPageLoader } from "@/components/FullPageLoader";
import { ActionType } from "@/enums/ActionTypeEnum";
import { routes } from "@/routes";
import { useQuery } from "@tanstack/react-query";
import { parse } from "date-fns";
import { useEffect, useState } from "react";

export const ClientsCalendar = () => {
  const [events, setEvents] = useState([]);
  const filters = {
    columnFilters: [{ id: "withActions", value: true }],
  };

  const {
    isLoading: isLoadingClients,
    data: clients,
    error: errorClients,
  } = useQuery({
    queryKey: ["clients", filters],
    queryFn: () => apiClient.getClients(filters),
  });

  useEffect(() => {
    setEvents(() => []);
    if (!isLoadingClients && clients && !errorClients) {
      if (clients.data.length) {
        const actions = clients.data.flatMap((client) => client.actions);
        setEvents(
          actions.map((action) => ({
            title: `${action.name} - ${clients.data.find((ins) => ins.id === action.actionable.id)?.name}`,
            link: `${routes.clients}/${action?.actionable?.id}`,
            icon: ActionType[action.type]?.icon,
            date: parse(action.action_at, "yyyy-MM-dd HH:mm:ss", new Date()),
            description: action.description,
            name: ActionType[action.type]?.name ?? "Działanie",
            className: ActionType[action.type]?.color ?? "bg-yellow-600 hover:bg-yellow-600/90",
          })),
        );
      }
    }
  }, [isLoadingClients, clients, errorClients]);

  return isLoadingClients ? <FullPageLoader /> : <CalendarView events={events} />;
};
