import { ChartContainerWrapper } from "@/components/Charts/ChartContainerWrapper";
import { StatCardDescription } from "@/components/StatCardDescription";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ChartLegend, ChartLegendContent, ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";
import i18n from "@/i18n";
import { Label, Pie, PieChart, Sector } from "recharts";

export const ContractDepartmentStat = ({ dateRange, data, isLoading }) => {
  const totalCount = data && Object.values(data).reduce((acc, current) => acc + current, 0);
  const max = { value: 0, index: 0 };
  const chartData =
    data &&
    Object.keys(data).map((department, index) => {
      const value = data[department];
      if (data[department] > max.value) {
        max.index = index;
        max.value = value;
      }
      return {
        department: department.replace(/\s+/g, "-").toLowerCase(),
        value,
        fill: `var(--color-${department.replace(/\s+/g, "-").toLowerCase()})`,
      };
    });

  const chartConfig =
    data &&
    Object.keys(data).reduce(
      (config, key, index) => {
        config[key.replace(/\s+/g, "-").toLowerCase()] = {
          label: key ? key : i18n.t("Brak oddziału"),
          color: `hsl(var(--chart-${index + 1}))`,
        };
        return config;
      },
      { value: { label: i18n.t("Ilość") } },
    );

  return (
    <Card className="flex flex-col">
      <CardHeader className="items-center pb-0">
        <CardTitle>{i18n.t("Ilość umów per oddział")}</CardTitle>
        <StatCardDescription dateRange={dateRange} />
      </CardHeader>
      <CardContent className="flex-1">
        <ChartContainerWrapper
          isLoading={isLoading}
          data={chartData}
          config={chartConfig}
          className="mx-auto aspect-square max-h-[300px]"
        >
          <PieChart>
            <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
            <Pie
              data={chartData}
              dataKey="value"
              nameKey="department"
              innerRadius={60}
              strokeWidth={5}
              activeIndex={max.index}
              activeShape={({ outerRadius = 0, ...props }) => <Sector {...props} outerRadius={outerRadius + 10} />}
            >
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text x={viewBox.cx} y={viewBox.cy} textAnchor="middle" dominantBaseline="middle">
                        <tspan x={viewBox.cx} y={viewBox.cy} className="fill-foreground text-3xl font-bold">
                          {totalCount}
                        </tspan>
                        <tspan x={viewBox.cx} y={(viewBox.cy || 0) + 24} className="fill-muted-foreground">
                          {i18n.t("Umów")}
                        </tspan>
                      </text>
                    );
                  }
                }}
              />
            </Pie>
            <ChartLegend
              content={<ChartLegendContent nameKey="department" />}
              className="flex-wrap gap-2 [&>*]:justify-center"
            />
          </PieChart>
        </ChartContainerWrapper>
      </CardContent>
    </Card>
  );
};
