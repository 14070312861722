import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { getFileExtensionIcon } from "@/helpers/files/getFileExtensionIcon";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { CloudUpload, X } from "lucide-react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";

export const FileUploadInput = ({
  id = "dropzone-file",
  accept = {},
  multiple = true,
  onFilesSelect = () => {},
  selectedFiles = [],
  ...rest
}) => {
  const [files, setFiles] = useState(selectedFiles);

  const onDrop = (newFiles) => {
    const updatedFiles = multiple ? [...files, ...newFiles] : newFiles;
    setFiles(updatedFiles);
    onFilesSelect(updatedFiles);
  };

  const removeFile = (fileToRemove) => {
    const updatedFiles = files.filter((file) => file !== fileToRemove);
    setFiles(updatedFiles);
    onFilesSelect(updatedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple,
  });

  return (
    <Card className="flex flex-col items-center justify-center w-full p-5">
      <div className="w-full">
        <label
          {...getRootProps()}
          className={cn(
            "relative flex px-5 flex-col items-center justify-center w-full py-6 border-2 border-border border-dashed rounded-lg cursor-pointer bg-background hover:bg-secondary",
            isDragActive ? "border-primary" : "border-border",
          )}
        >
          <div className="text-center">
            <div className="max-w-min mx-auto">
              <CloudUpload size={30} />
            </div>

            <p className="mt-2 text-sm text-foreground">
              <span className="font-semibold">{i18n.t("Przeciągnij i upuść")}</span>
            </p>
            <p className="text-xs text-muted-foreground">{i18n.t("lub")}</p>
            <p className="text-xs text-muted-foreground">{i18n.t("kliknij i wybierz pliki do przesłania")}</p>
          </div>
        </label>

        <Input {...getInputProps()} {...rest} id={id} className="hidden" />
      </div>

      {files.length > 0 && (
        <div className="w-full mt-4">
          <p className="font-medium text-muted-foreground text-sm">{i18n.t("Przesłane pliki")}</p>
          <ScrollArea className="w-full flex max-h-48 flex-col overflow-y-auto">
            <div className="space-y-2">
              {files.map((file, index) => (
                <div
                  key={file.name + index}
                  className="flex justify-between gap-2 rounded-lg overflow-hidden border border-border group hover:pr-0 pr-2"
                >
                  <div className="flex items-center flex-1 p-2">
                    <div className="text-gray-600">
                      <img className="w-6 h-6" src={getFileExtensionIcon(file.type.split("/")[1])} alt="" />
                    </div>

                    <div className="w-full ml-2 space-y-1">
                      <p className="text-sm text-foreground truncate">{file.name}</p>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => removeFile(file)}
                    className="bg-destructive text-white transition-all items-center justify-center cursor-pointer px-2 hidden group-hover:flex"
                  >
                    <X size={20} />
                  </button>
                </div>
              ))}
            </div>
          </ScrollArea>
        </div>
      )}
    </Card>
  );
};
