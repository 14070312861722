import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { formatMoney } from "@/helpers/formatMoney";
import i18n from "@/i18n";
import { Banknote, DollarSign, FileSearch, TrendingDown, TrendingUp, Users } from "lucide-react";

export const SalesManagerDashboardOverviewTiles = ({ data }) => {
  const { leads, open_contracts, unpaid_comissions, paid_comissions } = data;

  return (
    <div className="grid gap-4 md:grid-cols-2 md:gap-5 lg:grid-cols-4">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">{i18n.t("Liczba przypisanych kontaktów")}</CardTitle>
          <Users className="h-6 w-6 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{leads.current_month}</div>
          {leads.growth > 0 ? (
            <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
              <TrendingUp size={16} />
              {i18n.t("Wzrost o ")} {leads.growth + "%"} {i18n.t(" względem poprzedniego miesiąca")}
            </p>
          ) : (
            leads.growth < 0 && (
              <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
                <TrendingDown size={16} />
                {i18n.t("Spadek o")} {leads.growth + "%"} {i18n.t(" względem poprzedniego miesiąca")}
              </p>
            )
          )}
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">{i18n.t("Liczba umów w procesie realizacji")}</CardTitle>
          <FileSearch className="h-6 w-6 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{open_contracts.current_month}</div>
          {open_contracts.growth > 0 ? (
            <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
              <TrendingUp size={16} />
              {i18n.t("Wzrost o ")} {open_contracts.growth + "%"} {i18n.t(" względem poprzedniego miesiąca")}
            </p>
          ) : (
            open_contracts.growth < 0 && (
              <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
                <TrendingDown size={16} />
                {i18n.t("Spadek o")} {open_contracts.growth + "%"} {i18n.t(" względem poprzedniego miesiąca")}
              </p>
            )
          )}
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">{i18n.t("Wartość otwartych szans sprzedaży")}</CardTitle>
          <DollarSign className="h-6 w-6 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{formatMoney(unpaid_comissions.current_month)}</div>
          {unpaid_comissions.growth > 0 ? (
            <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
              <TrendingUp size={16} />
              {i18n.t("Wzrost o ")} {unpaid_comissions.growth + "%"} {i18n.t(" względem poprzedniego miesiąca")}
            </p>
          ) : (
            unpaid_comissions.growth < 0 && (
              <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
                <TrendingDown size={16} />
                {i18n.t("Spadek o")} {unpaid_comissions.growth + "%"} {i18n.t(" względem poprzedniego miesiąca")}
              </p>
            )
          )}
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">{i18n.t("Wypłacone prowizje")}</CardTitle>
          <Banknote className="h-6 w-6 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{formatMoney(paid_comissions.current_month)}</div>
          {paid_comissions.growth > 0 ? (
            <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
              <TrendingUp size={16} />
              {i18n.t("Wzrost o ")} {paid_comissions.growth + "%"} {i18n.t(" względem poprzedniego miesiąca")}
            </p>
          ) : (
            paid_comissions.growth < 0 && (
              <p className="flex flex-row gap-1 items-center text-xs text-muted-foreground">
                <TrendingDown size={16} />
                {i18n.t("Spadek o")} {paid_comissions.growth + "%"} {i18n.t(" względem poprzedniego miesiąca")}
              </p>
            )
          )}
        </CardContent>
      </Card>
    </div>
  );
};
