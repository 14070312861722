import { ProductFilesCard } from "@/components/Product/ProductDetails/Files/ProductFilesCard";
import { ProductInformationCard } from "@/components/Product/ProductDetails/ProductInformationCard";
import { ProductMetadataCard } from "@/components/Product/ProductDetails/ProductMetadataCard";

export const ProductDetails = ({ product }) => {
  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col lg:flex-row gap-5">
        <ProductInformationCard product={product} />
        <ProductMetadataCard product={product} />
      </div>
      <div className="flex flex-col lg:flex-row gap-5">
        <ProductFilesCard product={product} />
      </div>
    </div>
  );
};
