import { ChartContainerWrapper } from "@/components/Charts/ChartContainerWrapper";
import { StatCardDescription } from "@/components/StatCardDescription";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";
import i18n from "@/i18n";
import { format, parse } from "date-fns";
import { Bar, BarChart, CartesianGrid, Label, LabelList, Line, LineChart, ReferenceLine, XAxis } from "recharts";

export const DepartmentLeadCountStat = ({ data, dateRange, isLoading }) => {
  let avg = 0;
  const chartData =
    data &&
    Object.entries(data)
      .map(([month, counts]) => {
        avg += counts.conversion;
        return {
          month: format(parse(month, "yyyy-MM", new Date()), "MMM yyyy"),
          leads: counts.leads > 0 ? counts.leads - counts.contracts : counts.leads,
          contracts: counts.contracts,
          conversion: counts.conversion,
        };
      })
      .sort(
        (a, b) => parse(a.month, "MMM yyyy", new Date()).getTime() - parse(b.month, "MMM yyyy", new Date()).getTime(),
      );

  const chartConfig = {
    leads: {
      label: "Niepodpisane",
      color: "hsl(var(--chart-1))",
    },
    contracts: {
      label: "Podpisane",
      color: "hsl(var(--chart-2))",
    },
    conversion: {
      label: "Konwersja",
      color: "hsl(var(--chart-3))",
    },
  };

  return (
    <div className="flex flex-col gap-3 col-span-2">
      <Card className="col-span-2 h-fit">
        <CardHeader>
          <CardTitle>{i18n.t("Ilość kontaktów i umów")}</CardTitle>
          <StatCardDescription dateRange={dateRange} />
        </CardHeader>
        <CardContent>
          <ChartContainerWrapper
            isLoading={isLoading}
            data={chartData}
            config={chartConfig}
            className="w-full max-h-[300px]"
          >
            <BarChart
              accessibilityLayer
              data={chartData}
              margin={{
                top: 20,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="month"
                tickLine={false}
                tickMargin={10}
                axisLine={false}
                tickFormatter={(value) => value.slice(0, 3)}
              />
              <ChartTooltip
                content={
                  <ChartTooltipContent
                    hideLabel
                    className="w-[180px]"
                    formatter={(value, name, item, index) => (
                      <>
                        <div
                          className="h-2.5 w-2.5 shrink-0 rounded-[2px] bg-[--color-bg]"
                          style={{
                            "--color-bg": `var(--color-${name})`,
                          }}
                        />
                        {chartConfig[name]?.label || name}
                        <div className="ml-auto flex items-baseline gap-0.5 font-mono font-medium tabular-nums text-foreground">
                          {value}
                        </div>
                        {index === 1 && (
                          <div className="mt-1.5 flex basis-full items-center border-t pt-1.5 text-xs font-medium text-foreground">
                            {i18n.t("Razem")}
                            <div className="ml-auto flex items-baseline gap-0.5 font-mono font-medium tabular-nums text-foreground">
                              {item.payload.contracts + item.payload.leads}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  />
                }
                cursor={false}
              />
              <Bar dataKey="leads" stackId="a" fill="var(--color-leads)" radius={[0, 0, 4, 4]}></Bar>
              <Bar dataKey="contracts" stackId="a" fill="var(--color-contracts)" radius={[4, 4, 0, 0]}>
                <LabelList
                  offset={12}
                  className="fill-foreground"
                  fontSize={12}
                  dataKey="conversion"
                  formatter={(val) => val + " %"}
                />
              </Bar>
            </BarChart>
          </ChartContainerWrapper>
        </CardContent>
      </Card>
      <Card className="col-span-2 h-fit">
        <CardHeader>
          <CardTitle>{i18n.t("Konwersja")}</CardTitle>
          <StatCardDescription dateRange={dateRange} />
        </CardHeader>
        <CardContent>
          <ChartContainerWrapper
            isLoading={isLoading}
            data={chartData}
            config={chartConfig}
            className="w-full max-h-[300px]"
          >
            <LineChart
              className="absolute"
              accessibilityLayer
              data={chartData}
              margin={{
                left: 20,
                right: 20,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="month"
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                tickFormatter={(value) => value.slice(0, 3)}
              />
              <ChartTooltip cursor={false} content={<ChartTooltipContent formatter={(val) => val + "%"} />} />
              <Line dataKey="conversion" type="monotone" stroke="hsl(var(--chart-1))" strokeWidth={2} dot={false} />
              {chartData?.length && (
                <ReferenceLine
                  y={avg / chartData.length}
                  stroke="hsl(var(--muted-foreground))"
                  strokeDasharray="3 3"
                  strokeWidth={1}
                >
                  <Label position="insideBottomLeft" value="Średnia" offset={10} fill="hsl(var(--foreground))" />
                  <Label
                    position="insideTopLeft"
                    value={(avg / chartData.length).toFixed(0) + "%"}
                    className="text-lg"
                    fill="hsl(var(--foreground))"
                    offset={10}
                    startOffset={100}
                  />
                </ReferenceLine>
              )}
            </LineChart>
          </ChartContainerWrapper>
        </CardContent>
      </Card>
    </div>
  );
};
