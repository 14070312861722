import { PulsingDot } from "@/components/PulsingDot";
import { Bell } from "lucide-react";

export const NotificationBell = ({ isActive = false }) => {
  if (isActive)
    return (
      <div className="inline-flex relative">
        <Bell className="h-6 w-6 flex-shrink-0" />
        <PulsingDot />
      </div>
    );
  else return <Bell className="h-6 w-6 flex-shrink-0" />;
};
