import { useAuth } from "@/hooks/useAuth";

export const useInstallationStatusPolicy = () => {
  const { hasPermission } = useAuth();

  const create = () => {
    return hasPermission("create_installation_status");
  };

  const update = () => {
    return hasPermission("update_installation_status");
  };

  const updateName = (status) => {
    return hasPermission("update_installation_status") && !status.required;
  };

  const destroy = (status) => {
    return hasPermission("delete_installation_status") && !status.required;
  };

  return {
    create,
    update,
    updateName,
    destroy,
  };
};
